import {
  CHAT_INTERAKSI_BELAJAR_ROOM,
  CREATE_INTERAKSI_BELAJAR_ROOM,
  DELETE_INTERAKSI_BELAJAR_ROOM,
  GET_INTERAKSI_BELAJAR_ROOM,
  GET_PARTISIPAN_INTERAKSI_BELAJAR_ROOM,
  LIST_INTERAKSI_BELAJAR_ROOM,
  SELECTED_PARTISIPAN_INTERKASI_BELAJAR,
  SET_CHATID_INTERAKSI_BELAJAR_ROOM,
  UPDATE_INTERAKSI_BELAJAR_ROOM,
} from '@type';
import { message } from 'antd';

const initialState = {
  list_partisipan: [],
  createRoom: false,
  roomReady: false,
  list_interaksi: [],
  selected_partisipan: false,
  interaksiBody: false,
  retrievedChat: false,
  chatId: false,
  chatName: false,
};

const InteraksiBelajar = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTISIPAN_INTERAKSI_BELAJAR_ROOM:
      return { ...state, list_partisipan: action.value };
    case LIST_INTERAKSI_BELAJAR_ROOM:
      return { ...state, list_interaksi: action.value };
    case GET_INTERAKSI_BELAJAR_ROOM:
      return { ...state, interaksiBody: action.value };
    case SELECTED_PARTISIPAN_INTERKASI_BELAJAR:
      return { ...state, selected_partisipan: action.value };
    case CREATE_INTERAKSI_BELAJAR_ROOM:
      if (action.value) {
        message.success(action.message);
      } else if (action.message !== 'Anda Telah Di Alihkan') {
        message.error(action.message);
      }
      return { ...state, createRoom: action.value };
    case UPDATE_INTERAKSI_BELAJAR_ROOM:
      return state;
    case DELETE_INTERAKSI_BELAJAR_ROOM:
      return state;
    case CHAT_INTERAKSI_BELAJAR_ROOM:
      return { ...state, retrievedChat: action.value };
    case SET_CHATID_INTERAKSI_BELAJAR_ROOM:
      return { ...state, chatId: action.value };
    default:
      return state;
  }
};

export default InteraksiBelajar;
