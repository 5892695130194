import { Loading } from '@components';
import { persistor, store } from '@store/store';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
// ** Redux Imports
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import '@assets/scss/style.scss';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/id';
import id_ID from 'antd/lib/locale/id_ID';
import './i18n-init';
import { Worker } from '@react-pdf-viewer/core';
import * as serviceWorker from './serviceWorker';

moment().locale('id');
const LazyApp = lazy(() => import('./views/App'));
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  defaultIntegrations: false,
  beforeSend: (err) => err,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  environment: process.env.REACT_APP_URL_ENV,
  debug: false,
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <ConfigProvider locale={id_ID}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Loading />}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
            <LazyApp />
          </Worker>
        </Suspense>
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
