import { GET_DOCUMENTS, GET_DOCUMENTS_SCHOOL, GET_FILES, GET_FILES_SCHOOL } from '@type';

const initState = {
  listDocuments: [],
  listFiles: [],
  listDocumentsSchool: [],
  listFilesSchool: [],
  about: null,
};

const Dokumen = (state = initState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return { ...state, listDocuments: action.value };
    case GET_FILES:
      return { ...state, listFiles: action.value };
    case GET_DOCUMENTS_SCHOOL:
      return { ...state, listDocumentsSchool: action.value };
    case GET_FILES_SCHOOL:
      return { ...state, listFilesSchool: action.value };
    case 'GET_ABOUT_GDRIVE':
      return { ...state, about: action.value };
    default:
      return state;
  }
};

export default Dokumen;
