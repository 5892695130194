import { GET_SCHEDULES } from '@type';

const initialState = {
  jadwal: [],
};

const Jadwal = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULES:
      return { ...state, jadwal: action.value };
    default:
      return state;
  }
};

export default Jadwal;
