import axios from 'axios';
import { requestGet } from '@configs';
import { BASE_API_URL, BASE_VERSION } from '@http';
import { showLoading } from '@store/actions';
import {
  GET_ALL_GRADING_PENILAIAN,
  GET_COURSE_PENGAJUAN,
  GET_DETAIL_NILAI,
  GET_DETAIL_SCORE_BY_SISWA,
  GET_NILAI_BY_STUDENT,
  GET_PENGAJUAN_MAPEL,
  GET_STUDENT_DETAIL_TASK,
  GET_STUDENT_NILAI,
  GET_STUDENT_SCORE,
  GET_STUDENT_SCORE_ALL,
  GET_SUBMITTED_COURSE,
} from '@type';
import { getUserToken } from '@utils';
import { notification } from 'antd';
import { BASE_EXAM, BASE_REKAP_NILAI } from '../../../../constants/initHttp';
import { reportSentry } from '../../../../utils/Utils';
import { GET_ONE_GRADING_PENILAIAN } from '../../../../constants/initType';
import { showError } from '../../../../utils/showError';

const { CancelToken } = axios;

export const getStudentNilai = (classId) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const res = await requestGet(`classes/${classId}/students`, null, getUserToken());

    dispatch({ type: GET_STUDENT_NILAI, value: res.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getRekapByStudent = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const res = await requestGet('rekap-nilai', params, getUserToken());

    dispatch({ type: GET_NILAI_BY_STUDENT, value: res.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getRekapDetail = (courseId, params) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const res = await requestGet(`rekap-nilai/${courseId}`, params, getUserToken());

    dispatch({ type: GET_DETAIL_NILAI, value: res.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getDownloadFile = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await axios({
      url: `${BASE_API_URL}${BASE_VERSION}rekap-nilai/${params.course_id}/download`,
      method: 'GET',
      headers: {
        Authorization: getUserToken(),
      },
      responseType: 'blob',
      params: {
        user_id: params.user_id,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Rekap Nilai.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getDownloadFileV2 = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return fetch(`${BASE_API_URL}${BASE_VERSION}rekap-nilai/${params.course_id}/download?user_id=${params.user_id}`, {
    headers: {
      Authorization: getUserToken(),
    },
  })
    .then(async (res) => {
      if (res.status > 300 || res.status < 200) {
        const resMessage = await res.text();
        throw new Error(resMessage);
      }

      const blob = await res.blob();
      const result = {
        filename: 'Rekap Nilai.xlsx',
        blob,
      };
      return result;
    })
    .then((res) => res)
    .catch((error) => {
      notification.error({
        message: 'Mohon Maaf!',
        description: error.message,
      });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getSubmittedCourse = (page, limit, class_id, school_id, role, status, name, semester = '') => {
  const api = async (dispatch) => {
    dispatch(showLoading(true));
    const api = await axios
      .get(
        `${BASE_REKAP_NILAI}v1/api/scoresubmission/submitlist?page=${page}&limit=${limit}
        &order_by=def.created_at&sort=ASC&class_id=${class_id}&school_id=${school_id}&role=${role}&status=${status}&name=${name}&semester=${semester}`,
        {
          headers: { Authorization: `Bearer ${getUserToken()}` },
        }
      )
      .then((response) => {
        dispatch({
          type: GET_SUBMITTED_COURSE,
          value: response.data.data,
        });
      })
      .catch((error) => {
        reportSentry(error);
        notification.error({
          message: 'Gagal!',
          description: showError(error?.response?.data?.message),
        });
      });
    dispatch(showLoading(false));
    return api;
  };
  return api;
};
export const getListPengajuanMapel = (
  type,
  page,
  limit,
  course_id,
  school_id,
  search = '',
  scoringType = '',
  schoolExamType = ''
) => {
  const dp = async (dispatch) => {
    dispatch(showLoading(true));
    const api = await axios
      .get(
        `${BASE_REKAP_NILAI}v1/api/${
          type === 'exam' ? 'schoolExamCourse' : 'assessment'
        }?page=${page}&limit=${limit}&order_by=def.created_at&sort=ASC&course_id=${course_id}&search=${search}&scoring_type=${scoringType}&exam_type_id=${schoolExamType}`,
        {
          headers: { Authorization: `Bearer ${getUserToken()}` },
        }
      )
      .then((response) => {
        dispatch({
          type: GET_PENGAJUAN_MAPEL,
          value: response.data.data,
        });
      })
      .catch((error) => {
        reportSentry(error);
        notification.error({
          message: 'Gagal!',
          description: showError(error?.response?.data?.message),
        });
      });
    dispatch(showLoading(false));
    return api;
  };
  return dp;
};
export const getListStudentScore = (type, page, limit, class_id, score_id, name = '') => {
  const dp = async (dispatch) => {
    const api = await axios
      .get(
        `${BASE_REKAP_NILAI}v1/api/scoresubmission/score/students?page=${page}&limit=${limit}&order_by=def.created_at&sort=ASC&class_id=${class_id}&score_id=${score_id}&type=${type}&name=${name}`,
        {
          headers: { Authorization: `Bearer ${getUserToken()}` },
        }
      )
      .then((response) => {
        dispatch({
          type: GET_STUDENT_SCORE,
          value: response.data.data,
        });
      })
      .catch((error) => {
        reportSentry(error);
        notification.error({
          message: 'Gagal!',
          description: showError(error?.response?.data?.message),
        });
      });
    return api;
  };
  return dp;
};
export const submitStudentScore = (data) => async () => {
  const api = await axios
    .post(`${BASE_REKAP_NILAI}v1/api/scoresubmission/submitscore`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      if (response.status === 200) {
        return true;
      }
    })
    .catch((error) => {
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
      reportSentry(error);
    });
  return api;
};
export const changeStatus = (data, id) => async () => {
  const api = await axios
    .put(`${BASE_REKAP_NILAI}v1/api/scoresubmission/updatestatus/${id}`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      if (response.status === 200) {
        return true;
      }
    })
    .catch((error) => {
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
      reportSentry(error);
    });
  return api;
};

export const downloadTemplateRekap = (data) => async () => {
  const api = await axios({
    method: 'GET',
    url: `${BASE_REKAP_NILAI}v1/api/report/template`,
    headers: { Authorization: `Bearer ${getUserToken()}` },
    responseType: 'blob',
    params: data,
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers.filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) =>
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      })
    );
  return api;
};

export const getScoreByStudent = (page, limit, course_id, name) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(
      `${BASE_REKAP_NILAI}v1/api/scoresubmission/walikelas/conclusion?page=${page}&limit=${limit}&sort=asc&course_Id=${course_id}&name=${
        name || ''
      }`,
      {
        headers: { Authorization: `Bearer ${getUserToken()}` },
      }
    )
    .then((response) => {
      dispatch({
        type: GET_DETAIL_SCORE_BY_SISWA,
        value: response.data.data,
      });
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  dispatch(showLoading(false));
  return api;
};
export const getStudentTaskDetail = (page, limit, course_id, student_id) => async (dispatch) => {
  const api = await axios
    .get(
      `${BASE_REKAP_NILAI}v1/api/scoresubmission/student/taskdetail?page=${page}&limit=${limit}&sort=ASC&course_Id=${course_id}&user_id=${student_id}`,
      {
        headers: { Authorization: `Bearer ${getUserToken()}` },
      }
    )
    .then((response) => {
      dispatch({
        type: GET_STUDENT_DETAIL_TASK,
        value: response.data.data,
      });
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  return api;
};

export const importPengajuan = (data) => async () => {
  const api = await axios
    .post(`${BASE_REKAP_NILAI}v1/api/report/import`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      if (response.status === 200) {
        return true;
      }
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  return api;
};

export const submitPengajuan = (data, id) => async () => {
  const api = await axios
    .put(`${BASE_REKAP_NILAI}v1/api/scoresubmission/submitstatus/${id}`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      if (response.status === 200) {
        return true;
      }
    })
    .catch((error) => {
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message, 'pengajuan'),
      });
      reportSentry(error);
    });
  return api;
};

export const getCoursePengajuan = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/course`,
    method: 'GET',
    params,
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then((response) => {
      dispatch({
        type: GET_COURSE_PENGAJUAN,
        value: response.data.data,
      });
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  dispatch(showLoading(false));
  return api;
};
export const getAllGrading = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/gradeSetting`,
    method: 'GET',
    params,
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then((response) => {
      dispatch({
        type: GET_ALL_GRADING_PENILAIAN,
        value: response.data.data,
      });
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  dispatch(showLoading(false));
  return api;
};
export const getOneGrading = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/gradeSetting/id/${id}`,
    method: 'GET',
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then((response) => {
      dispatch({
        type: GET_ONE_GRADING_PENILAIAN,
        value: response.data.data,
      });
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  dispatch(showLoading(false));
  return api;
};

export const addGrading = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/gradeSetting`,
    method: 'POST',
    data,
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then((response) => {
      if (response?.data?.code === 200) {
        return true;
      }
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  dispatch(showLoading(false));
  return api;
};

export const updateGrading = (data, id) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/gradeSetting/id/${id}`,
    method: 'PUT',
    data,
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then((response) => {
      if (response?.data?.code === 200) {
        return true;
      }
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  dispatch(showLoading(false));
  return api;
};

export const deleteGrading = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/gradeSetting/id/${id}`,
    method: 'DELETE',
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then((response) => {
      if (response?.data?.code === 200) {
        return true;
      }
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  dispatch(showLoading(false));
  return api;
};

export const getListStudentScoreAll = (type, page, limit, class_id, score_id, name = '') => {
  const dp = async (dispatch) => {
    const api = await axios
      .get(
        `${BASE_REKAP_NILAI}v1/api/scoresubmission/score/students/all?order_by=def.created_at&sort=ASC&class_id=${class_id}&score_id=${score_id}&type=${type}&name=${name}`,
        {
          headers: { Authorization: `Bearer ${getUserToken()}` },
        }
      )
      .then((response) => {
        dispatch({
          type: GET_STUDENT_SCORE_ALL,
          value: response.data.data,
        });
      })
      .catch((error) => {
        reportSentry(error);
      });
    return api;
  };
  return dp;
};

export const exportScoreExam = (scoreID) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await axios({
      url: `${BASE_EXAM}export/score/${scoreID}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Nilai Ujian.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getScoreRecap = (params) => async () => {
  const api = await axios
    .get(`${BASE_REKAP_NILAI}v1/api/scoreRecap`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
      params,
    })
    .then((response) => response.data)
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
      return false;
    });
  return api;
};
export const getScoreRecapDetail = (id) => async () => {
  const api = await axios
    .get(`${BASE_REKAP_NILAI}v1/api/scoreRecap/detail/${id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  return api;
};

export const importScoreRecapTemplate = (params) => async () => {
  const api = await axios
    .get(`${BASE_REKAP_NILAI}v1/api/scoring/import/template`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
      params,
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers.filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Kesalahan pengunduhan template',
        description:
          'File anda belum berhasil diunduh karena kurikulum komponen belum terisi. Silahkan lengkapi terlebih dahulu dan coba kembali. Hubungi admin jika kendala ini masih terjadi.',
      });
      // return false;
    });
  return api;
};
export const postRekapNilai = (data) => async () => {
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/scoring`,
    method: 'POST',
    data,
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then(() => true)
    .catch((error) => {
      reportSentry(error);
      // notification.error({
      //     message: "Gagal!",
      //     description: showError(error?.response?.data?.message),
      // });
      return false;
    });
  return api;
};

export const exportRekapNilai = (id, data, progress = () => {}, cancellation = () => {}) => async () => {
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/scoring/import/${id}`,
    method: 'POST',
    data,
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      progress(progressEvent);
    },
    cancelToken: new CancelToken((c) => {
      cancellation(c);
    }),
  })
    .then(() => ({ isError: false, data: null }))
    .catch((error) => {
      reportSentry(error);
      if (error.response?.data?.code === 500 && !error.response?.data?.data) {
        notification.error({
          message: 'Gagal',
          description: error.response?.data?.message,
        });
      }
      return { isError: true, data: error.response?.data?.data };
    });
  return api;
};

export const getScoreRecapDetailHistory = (params) => async () => {
  const api = await axios
    .get(`${BASE_REKAP_NILAI}v1/api/courseFinalScoreHistory`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
      params,
    })
    .then((response) => response.data)
    .catch((error) => {
      reportSentry(error);
      notification.error({
        message: 'Gagal!',
        description: showError(error?.response?.data?.message),
      });
    });
  return api;
};

export const updateFinalScore = (data) => async () => {
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/courseFinalScore`,
    method: 'POST',
    data,
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then((response) => {
      if (response?.data?.code === 200) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      reportSentry(error);
      return false;
    });
  return api;
};
export const updateStatusScore = (data) => async () => {
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/courseScoringStatus`,
    method: 'POST',
    data,
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then((response) => {
      if (response?.data?.code === 200) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      reportSentry(error);
      return false;
    });
  return api;
};
export const checkScoringStatus = (data) => async () => {
  const api = await axios({
    url: `${BASE_REKAP_NILAI}v1/api/courseScoringStatus/check`,
    method: 'POST',
    data,
    headers: { Authorization: `Bearer ${getUserToken()}` },
  })
    .then((response) => {
      // console.log(response);
      if (response?.data?.code === 200) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      reportSentry(error);
      return false;
    });
  return api;
};
