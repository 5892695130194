import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import reducer from '@store/reducers';

const persistConfig = {
  // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
  whitelist: ['Role', 'Auth', 'General', 'School'],
};

const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

export { store, persistor };
