import { requestPost, requestPut } from '@configs/evaluasi';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { KARAKTER_UTAMA_CREATE, KARAKTER_UTAMA_DELETE, KARAKTER_UTAMA_UPDATE } from '@type';

export const createKarakterUtama = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('theme', data, getUserToken());
    dispatch({ type: KARAKTER_UTAMA_CREATE, value: rest.data.id });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const updateKarakterUtama = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestPut(`theme/${id}`, null, data, getUserToken());
    dispatch({ type: KARAKTER_UTAMA_UPDATE, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const deleteKarakterUtama = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestPut(`theme/${id}`, null, data, getUserToken());
    dispatch({ type: KARAKTER_UTAMA_DELETE, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const clearCreateKarakterUtama = () => ({
  type: KARAKTER_UTAMA_CREATE,
  value: false,
});

export const clearDeleteKarakterUtama = () => ({
  type: KARAKTER_UTAMA_DELETE,
  value: false,
});

export const clearUpdateKarakterUtama = () => ({
  type: KARAKTER_UTAMA_UPDATE,
  value: false,
});
