export const listAgamaOptions = [
  {
    label: 'Islam',
    value: 'Islam',
  },
  {
    label: 'Kristen',
    value: 'Kristen',
  },
  {
    label: 'Katolik',
    value: 'Katolik',
  },
  {
    label: 'Hindu',
    value: 'Hindu',
  },
  {
    label: 'Buddha',
    value: 'Buddha',
  },
  {
    label: 'Konghucu',
    value: 'Konghucu',
  },
];

export const defaultProfile = {
  accepted_in_instution_on: '',
  address: '',
  birth_date: '',
  birth_place: '',
  blood_type: '',
  body_abnormality: '',
  child_family_status: '',
  child_sequence: '',
  child_status: '',
  city_id: '',
  city_name: '',
  desa_id: '',
  desa_name: '',
  detail_address: '',
  district_id: '',
  district_name: '',
  email: '',
  father_address: '',
  father_city_id: '',
  father_city_name: '',
  father_district_id: '',
  father_district_name: '',
  father_name: '',
  father_occupation: '',
  father_phone_number: '',
  father_province_id: '',
  father_province_name: '',
  father_religion: '',
  father_status: '',
  full_name: '',
  gender: '',
  guardian_address: '',
  guardian_city_id: '',
  guardian_city_name: '',
  guardian_district_id: '',
  guardian_district_name: '',
  guardian_name: '',
  guardian_occupation: '',
  guardian_phone_number: '',
  guardian_province_id: '',
  guardian_province_name: '',
  guardian_religion: '',
  guardian_status: '',
  id: '',
  image: '',
  is_no_classes: '',
  is_no_courses: '',
  is_no_schools: '',
  kabupaten_id: '',
  kabupaten_name: '',
  kecamatan_id: '',
  kecamatan_name: '',
  latest_education: '',
  mother_address: '',
  mother_city_id: '',
  mother_city_name: '',
  mother_district_id: '',
  mother_district_name: '',
  mother_name: '',
  mother_occupation: '',
  mother_phone_number: '',
  mother_province_id: '',
  mother_province_name: '',
  mother_religion: '',
  mother_status: '',
  nick_name: '',
  nisn: '',
  no_telp: '',
  origin_of_the_institution: '',
  pantau_active: '',
  profile_image_id: '',
  province_id: '',
  province_name: '',
  provinsi_id: '',
  provinsi_name: '',
  religion: '',
  same_father_address: '',
  same_guardian_address: '',
  same_mother_address: '',
  school_distance: '',
  token_pantau: '',
  total_sibling: '',
  user_id: '',
  extracurricular: [],
};

export const ortuProfile = [
  {
    title: 'Ayah',
    label: 'ayah',
    value: 'father',
  },
  { title: 'Ibu', label: 'ibu', value: 'mother' },
  {
    title: 'Wali',
    label: 'wali',
    value: 'guardian',
  },
];
