import { combineReducers } from 'redux';
import Auth from './Auth';
import Role from './Role';
import General from './General';
import Class from './Class';
import { MediaKelas } from './MediaKelas';
import School from './School';
import Wilayah from './Wilayah';
import { DraftSKL, EvaluasiKarakter, KarakterDasar, KarakterUtama, KategoriPenilaian, TemaPenilaian } from './Evaluasi';
import Kursus from './Kursus';
import Pengaturan from './Pengaturan';

export default combineReducers({
  Auth,
  Role,
  General,
  Class,
  ...MediaKelas,
  School,
  Wilayah,
  TemaPenilaian,
  KarakterUtama,
  KarakterDasar,
  DraftSKL,
  KategoriPenilaian,
  EvaluasiKarakter,
  Kursus,
  Pengaturan,
});
