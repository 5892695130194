import { showLoading } from '@store/actions';
import { notification } from 'antd';
import { BASE_REKAP_NILAI } from '../../../../constants/initHttp';
import { reportSentry } from '../../../../utils/Utils';
import httpService from '../../../../configs/service';

const RECOVER = 'https://dev-api.siswamedia.com/v1-report-card/';

const URL_REKAP_NILAI = `${BASE_REKAP_NILAI || RECOVER}v1/api/`;

export const getListSettingRaport = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('reportScoreSetting', { params, baseURL: URL_REKAP_NILAI })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getDetailSettingRaport = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('reportScoreSetting/id', { baseURL: URL_REKAP_NILAI, slashId: id })
    .then((response) => response.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const createSettingRaport = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .post('reportScoreSetting', data, { baseURL: URL_REKAP_NILAI })
    .then((response) => response.data)
    .catch((error) => {
      let msgError = 'Silahkan Hubungi Customer Service Kami';
      const errorData = error.response.data;

      if (errorData.status === 'record_already_exist') {
        msgError = 'Nama Kurikulum sudah ada !';
      }

      notification.error({
        message: 'Mohon Maaf!',
        description: msgError,
      });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const editSettingRaport = (data, id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .put('reportScoreSetting/id', data, { baseURL: URL_REKAP_NILAI, slashId: id })
    .then((response) => response.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const deleteSettingRaport = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .delete('reportScoreSetting/id', { baseURL: URL_REKAP_NILAI, slashId: id })
    .then((response) => response.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};
