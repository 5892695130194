import {
  GET_COURSE_PENGAJUAN,
  GET_DETAIL_NILAI,
  GET_DETAIL_SCORE_BY_SISWA,
  GET_NILAI_BY_STUDENT,
  GET_PENGAJUAN_MAPEL,
  GET_STUDENT_DETAIL_TASK,
  GET_STUDENT_NILAI,
  GET_STUDENT_SCORE,
  GET_STUDENT_SCORE_ALL,
  GET_STUDENT_SCORE_SUBMIT,
  GET_SUBMITTED_COURSE,
} from '@type';
import { GET_ALL_GRADING_PENILAIAN, GET_ONE_GRADING_PENILAIAN } from '../../../../constants/initType';

const initState = {
  listStudent: false,
  listNilai: false,
  detailNilai: false,
  listSubmittedCourse: false,
  listPengajuanMapel: false,
  listStudentScore: false,
  listStudentScoreSubmit: false,
  listScoreBySiswa: false,
  listStudentTaskDetail: false,
  typePengajuan: 'assessment',
  listCoursePengajuan: [],
  gradingList: false,
  gradingDetail: false,
  listStudentScoreAll: [],
};

const Nilai = (state = initState, action) => {
  switch (action.type) {
    case GET_STUDENT_NILAI:
      return {
        ...state,
        listStudent: action.value,
      };

    case GET_NILAI_BY_STUDENT:
      return {
        ...state,
        listNilai: action.value,
      };

    case GET_DETAIL_NILAI:
      return {
        ...state,
        detailNilai: action.value,
      };
    case GET_SUBMITTED_COURSE:
      return {
        ...state,
        listSubmittedCourse: {
          data: action.value?.list,
          total: action.value?.meta?.total,
        },
      };
    case GET_PENGAJUAN_MAPEL:
      return {
        ...state,
        listPengajuanMapel: {
          data: action.value?.list,
          total: action.value?.meta?.total,
        },
      };
    case GET_STUDENT_SCORE:
      return {
        ...state,
        listStudentScore: {
          data: action.value?.list,
          total: action.value?.meta?.total,
        },
      };
    case GET_STUDENT_SCORE_SUBMIT:
      return {
        ...state,
        listStudentScoreSubmit: action.value,
      };
    case GET_DETAIL_SCORE_BY_SISWA:
      return {
        ...state,
        listScoreBySiswa: action.value,
      };
    case GET_STUDENT_DETAIL_TASK:
      return {
        ...state,
        listStudentTaskDetail: action.value,
      };
    case GET_COURSE_PENGAJUAN:
      return {
        ...state,
        listCoursePengajuan: action.value,
      };
    case GET_ALL_GRADING_PENILAIAN:
      return {
        ...state,
        gradingList: action.value,
      };
    case GET_ONE_GRADING_PENILAIAN:
      return {
        ...state,
        gradingDetail: action.value,
      };
    case 'CHANGE_TYPE_PENGAJUAN': {
      return {
        ...state,
        typePengajuan: action.value,
      };
    }
    case GET_STUDENT_SCORE_ALL: {
      return {
        ...state,
        listStudentScoreAll: action.value,
      };
    }
    default:
      return state;
  }
};

export default Nilai;
