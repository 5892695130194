import {
  CREATE_KATEGORI_PENILAIAN,
  DELETE_KATEGORI_PENILAIAN,
  LIST_KATEGORI_PENILAIAN,
  UPDATE_KATEGORI_PENILAIAN,
} from '@type';

const initState = {
  list: false,
  create: false,
  update: false,
  delete: false,
};

const KategoriPenilaian = (state = initState, action) => {
  switch (action.type) {
    case LIST_KATEGORI_PENILAIAN:
      return {
        ...state,
        list: action.value,
      };

    case CREATE_KATEGORI_PENILAIAN:
      return {
        ...state,
        create: action.value,
      };

    case UPDATE_KATEGORI_PENILAIAN:
      return {
        ...state,
        update: action.value,
      };

    case DELETE_KATEGORI_PENILAIAN:
      return {
        ...state,
        delete: action.value,
      };

    default:
      return state;
  }
};

export default KategoriPenilaian;
