import axios from 'axios';
import { objectString } from '@utils';
import { BASE_EVALUASI } from '@http';

const headers = (token) => {
  let data = {
    'Content-Type': 'application/json',
  };

  if (token) {
    data = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      // "X-Content-Type-Options": "nosniff",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      // "Access-Control-Allow-Headers":
      //     "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    };
  }

  return data;
};

export const requestPost = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE_EVALUASI}${url}`, params, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestGet = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_EVALUASI}${url}${params ? objectString(params) : ''}`, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .then((error) => {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestPut = (url, params, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .put(`${BASE_EVALUASI}${url}${params ? objectString(params) : ''}`, body, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestDelete = (url, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .delete(`${BASE_EVALUASI}${url}`, {
        headers: headers(token),
        data: body,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestAnyGet = (url, params, headers) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(
        `${url}${objectString(params)}`,
        headers && {
          headers,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
