import axios from 'axios';
import { objectString } from '@utils';
import { BASE_QUIZ } from '@http';

const headers = (token) => {
  let data = {
    'Content-Type': 'application/json',
  };

  if (token) {
    data = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Content-Type-Options': 'nosniff',
      'Access-Control-Allow-Origin': '*',
      'X-Show-Correct-Answer': true,
    };
  }

  return data;
};

export const requestPost = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE_QUIZ}${url}`, params, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestGet = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_QUIZ}${url}${params ? objectString(params) : ''}`, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .then((error) => {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestPut = (url, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .put(`${BASE_QUIZ}${url}`, body, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestPatch = (url, body, token) =>
  // console.log("body---", body);
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .patch(`${BASE_QUIZ}${url}`, body, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestDelete = (url, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .delete(`${BASE_QUIZ}${url}`, {
        headers: headers(token),
        data: body,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestAnyGet = (url, params, headers) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_QUIZ}${objectString(params)}`,
        headers && {
          headers,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
