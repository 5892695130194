import { GET_UJIAN_GURU, GET_UJIAN_PARTICIPANT_GURU } from '@type';
import { showLoading } from '@store/actions';
import { notification } from 'antd';
import { getUserToken } from '@utils';
import httpService from '../../../../configs/service';
import { BASE_API_URL, BASE_EXAM, BASE_EXAM_QUIZ, BASE_QUIZ, BASE_VERSION } from '../../../../constants/initHttp';
import { reportSentry } from '../../../../utils/Utils';
import { runError } from '../../../../utils/errorHandler';

export const getUjianByGuru = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('exam', { params, baseURL: BASE_EXAM })
    .then((response) => {
      dispatch({
        type: GET_UJIAN_GURU,
        value: response.data.payload,
        pagination: response.data.payload,
      });
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getHeader = (params) =>
  httpService
    .get('examHeader', { params, baseURL: BASE_EXAM })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
export const createHeader = (body) =>
  httpService
    .post('examHeader', body, { baseURL: BASE_EXAM })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
export const updateHeader = (id, body) =>
  httpService
    .put(`examHeader/${id}`, body, { baseURL: BASE_EXAM })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

export const getUjianByGuruWithFilter = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('userdetail/courses', { params, baseURL: BASE_EXAM })
    .then((response) => response.data.payload)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const deleteUjianByGuru = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .delete(`exam/${id}`, { baseURL: BASE_EXAM })
    .then((response) => response.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getDetailUjianByGuru = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('exam', { baseURL: BASE_EXAM, slashId: id })
    .then((response) => response.data.payload)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getQuestionUjianByGuru = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('question', { baseURL: BASE_EXAM_QUIZ, params })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getPriviewResultParticipantUjianByGuru = (examId, userId) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get(`qa/preview/${examId}/${userId}`, { baseURL: BASE_EXAM_QUIZ })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });

      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getPriviewResultParticipantUjianByGuruV2 = (examId, userId) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get(`qa/preview/v2/${examId}/${userId}`, {
      baseURL: BASE_EXAM_QUIZ,
    })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getParticipantUjianByGuru = (id, params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get(`exam/${id}/participant`, { baseURL: BASE_EXAM_QUIZ, params })
    .then((response) => {
      dispatch({
        type: GET_UJIAN_PARTICIPANT_GURU,
        value: response.data.data,
        pagination: response.data.meta,
      });
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const deleteParticipant = (id, user_id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .delete(`exam/${id}/participant/${user_id}`, {
      baseURL: BASE_EXAM_QUIZ,
    })
    .then(() => true)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
      return false;
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const addParticipant = (id, params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .post(`${BASE_EXAM}score/create/init`, params)
    .then((response) => response.data)
    .catch((error) => {
      const { message } = error.response.data;
      runError(message, error);
      return false;
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getParticipantInUjianByGuru = (examId, userId) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get(`exam/${examId}/participant`, {
      baseURL: BASE_EXAM_QUIZ,
      slashId: userId,
    })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const updateAnswerParticipantByGuru = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .patch('/answer', data, { baseURL: BASE_EXAM_QUIZ })
    .then((response) => response.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const evaluateAnswerParticipantByGuru = (examId, userId) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .post(`/exam/${examId}/participant/${userId}/evaluate`, null, {
      baseURL: BASE_EXAM_QUIZ,
    })
    .then((response) => response.data)
    .catch((error) => {
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.data,
      });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getFilterTypeQuestion = () => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('/question/type', { baseURL: BASE_QUIZ })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getEnumStatusPoint = () => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('exam/status', { baseURL: BASE_EXAM_QUIZ })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getListScoreType = () => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('scoringtype', { baseURL: BASE_EXAM })
    .then((response) => (response.data.payload ? response.data.payload.list : []))
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getCurrentClassOld = () => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('me/current-class', {
      baseURL: `${BASE_API_URL}${BASE_VERSION}`,
      headers: {
        customAuthorization: true,
        Authorization: getUserToken(),
      },
    })
    .then((response) => (response.data ? response.data : null))
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const updateStatusParticipantInUjianByGuru = (examId, userId, data) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .put(`exam/${examId}/participant`, data, {
      baseURL: BASE_EXAM_QUIZ,
      slashId: userId,
    })
    .then((response) => {
      notification.success({
        message: 'Berhasil',
        description: 'Sukses memperbarui status siswa',
      });
      return response;
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const submitAlertWarning = (data) =>
  httpService.post('notification/warn', data, {
    baseURL: BASE_EXAM_QUIZ,
  });

export const getDetectionSummary = (examId, params, withLoading) => async (dispatch) => {
  if (withLoading) {
    dispatch(showLoading(true));
  }
  return httpService
    .get(`exam/${examId}/participant/detection/summary`, {
      baseURL: BASE_EXAM_QUIZ,
      params,
    })
    .then((response) => {
      dispatch({
        type: GET_UJIAN_PARTICIPANT_GURU,
        value: response.data.data,
        pagination: response.data.meta,
      });
    })
    .catch(() => {
      // notification.error({
      //     message: "Mohon Maaf!",
      //     description: "Silahkan Hubungi Customer Service Kami",
      // });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const updateQuestionBulk = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .put('/questions', data, { baseURL: BASE_EXAM_QUIZ })
    .then((response) => response)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
      throw error;
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};
