const listTableDetailUjian = (data) =>
  data.map((val) => ({
    ...val,
    id: val.id,
    name: val.qbank_detail ? val.qbank_detail.name : '-',
    question: val.qbank_detail ? val.qbank_detail.question : '-',
    name_question: val.qbank_detail ? val.qbank_detail.name : '-',
    point_question: val.qbank_detail ? val.qbank_detail.name : '-',
    explanation_question: val.qbank_detail ? val.qbank_detail.explanation : '-',
    attachment: val.qbank_detail ? val.qbank_detail.attachment : null,
    selected: val.qbank_question_type,
    point: val.qbank_point,
  }));

const listTableDetailParticipant = (data) =>
  data.map((val) => ({
    ...val,
    name: val.user_name || '-',
    weight: '-',
    id: val.user_id,
  }));

const paginatior = (raw, common) => {
  const temp = { ...common };

  if (raw) {
    temp.current_page = raw.page;
    temp.per_page = raw.limit;
    temp.last_page = raw.max_page;
    temp.total = raw.total_item;

    return temp;
  }

  return common;
};

const normalizeDataQuestion = (data) =>
  data.map((val, idx) => ({
    id: `${val.qbank_quiz_id}${idx}`,
    name: val.qbank_detail ? val.qbank_detail.quiz_name : '-',
    qbank_quiz_id: val.qbank_quiz_id,
    qbank_question_id: val.qbank_question_id,
    qbank_group_id: val.qbank_group_id,
    qbank_point: val.qbank_point,
    qbank_question_type: val.qbank_question_type,
  }));

export { listTableDetailUjian, listTableDetailParticipant, paginatior, normalizeDataQuestion };
