import { notification } from 'antd';
import { RESPONSE_ERRORS } from '../../constants/initError';

export const runError = (msg = undefined, err = undefined) => {
  if (err?.response?.status === 401) {
    return;
  }
  const response = err?.response?.data;
  // eslint-disable-next-line no-prototype-builtins
  if (response && response.hasOwnProperty('data')) {
    const respErr = response.data;
    let buildError = '';
    if (respErr) {
      Object.keys(respErr).map((item) => {
        if (Array.isArray(respErr[item])) {
          respErr[item].forEach((err) => {
            buildError += `${RESPONSE_ERRORS[err] ?? err} \n`;
          });
        }
        return item;
      });
    }
    if (buildError === '') {
      buildError = RESPONSE_ERRORS[respErr] ?? respErr;
    }
    notification.error({
      message: 'Permintaan Ditolak',
      description: buildError,
    });
  } else {
    notification.error({
      message: 'Mohon Maaf',
      description: RESPONSE_ERRORS[msg] ?? 'Silahkan Hubungi Customer Service Kami',
    });
  }
};
