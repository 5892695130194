import { IS_SUCCESS, IS_SUSPENDED, IS_UPLOAD_SUCCESS, SCHOOL_YEAR, SET_LOADING, WHITELABEL_CONFIG } from '@type';
import { requestGet } from '@configs/evaluasi';
import { getUserToken } from '@utils';
import { AxiosInject } from '../../../utils/Utils';
import { BASE_LOGIN } from '../../../constants/initHttp';

export const showLoading = (value) => ({
  type: SET_LOADING,
  value,
});

export const isSuccess = (value, message) => ({
  type: IS_SUCCESS,
  value,
  message,
});

export const isUploadSuccess = (value, message) => ({
  type: IS_UPLOAD_SUCCESS,
  value,
  message,
});

export const routeChanged = () => ({
  type: 'ROUTE_MOVE',
  value: Math.random(),
});
export const getSchoolYear = () => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const res = await requestGet(
      '/schoolyear',
      {
        sort: 'id',
        direction: 'asc',
      },
      getUserToken()
    );

    dispatch({ type: SCHOOL_YEAR, value: res.payload.list });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getWhitelabelInfo = (params, isAll = true, key = '') => {
  const http = AxiosInject(
    BASE_LOGIN,
    'Basic',
    window.btoa(`${process.env.REACT_APP_WL_USER}:${process.env.REACT_APP_WL_PASSWORD}`)
  );
  return async (dispatch) => {
    http
      .get(`v1/apiBasic/whitelabelAttribute/${isAll ? 'select' : `key/${key}`}`, {
        params,
      })
      .then((r) => {
        dispatch({
          type: IS_SUSPENDED,
          value: r.data.data,
        });
      })
      .catch(() => {
        dispatch({
          type: IS_SUSPENDED,
          value: false,
        });
      });
  };
};

export const setWhitelabelData = (payload) => (dispatch) => {
  dispatch({
    type: WHITELABEL_CONFIG,
    value: payload,
  });
};

export const setDictionary = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_DICTIONARY',
    value: payload,
  });
};
