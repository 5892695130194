export const MULTIPLE_ANSWERS_SEPARATOR = '|||';
export const coloringAdd = (svg, color, width, height) => {
  svg.classList.add('svg-class-name');
  const el = [...svg.querySelectorAll('path')];
  const gEl = [...svg.querySelectorAll('line')];
  const gCr = [...svg.querySelectorAll('circle')];
  el.forEach((e) => {
    e.setAttribute('fill', color ?? 'white');
  });
  gEl.forEach((e) => {
    e.setAttribute('stroke', color ?? 'white');
  });
  gCr.forEach((e) => {
    e.setAttribute('stroke', color ?? 'white');
    e.setAttribute('fill', color ?? 'white');
  });
  svg.setAttribute('width', width);
  svg.setAttribute('height', height);
};

export const injectedSvg = (svg, color, width, height) => {
  svg.classList.add('svg-class-name');
  const gEl = [...svg.querySelectorAll('path')];
  gEl.forEach((e) => {
    e.setAttribute('stroke', color ?? 'white');
  });
  const gLine = [...svg.querySelectorAll('line')];
  gLine.forEach((e) => {
    e.setAttribute('stroke', color ?? 'white');
  });

  svg.setAttribute('width', width);
  svg.setAttribute('height', height);
};

export const injectSvg = (svg, { height, width, color }) => {
  svg.classList.add('svg-class-name');
  const el = [...svg.querySelectorAll('path')];
  const gEl = [...svg.querySelectorAll('line')];
  gEl.forEach((e) => {
    e.setAttribute('stroke', 'white');
  });
  el.forEach((e) => {
    e.setAttribute('stroke', color || 'var(--primary-color)');
  });
  svg.setAttribute('width', width);
  svg.setAttribute('height', height);
};

export const PILIHAN_GANDA_TYPE = 1;
export const ESSAY_TYPE = 2;
export const PILIHAN_GANDA_MULTI_TYPE = 3;
export const TRUE_FALSE_TYPE = 4;
export const SHORT_TYPE = 5;
