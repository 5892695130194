import { GET_EXAM_HEADER } from '../../../constants/initType';

const initState = {
  examHeaderList: [],
  refBody: 0,
};
const Pengaturan = (state = initState, action) => {
  switch (action.type) {
    case GET_EXAM_HEADER:
      return {
        ...state,
        examHeaderList: action.value,
      };
    case 'REF_BODY':
      return {
        ...state,
        refBody: action.value,
      };
    default:
      return state;
  }
};

export default Pengaturan;
