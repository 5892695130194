export const setStorage = (path, data) => {
  localStorage.setItem(path, JSON.stringify(data));
};

export const getStorage = (path) => JSON.parse(localStorage.getItem(path));

export const removeStorage = (path) => {
  localStorage.removeItem(path);
};

export const clearStorage = () => {
  localStorage.clear();
};
