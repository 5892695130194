import { notification } from 'antd';
import { showLoading } from '../../../General';
import { AxiosInject, getUserToken } from '../../../../../utils/Utils';
import { BASE_REKAP_NILAI } from '../../../../../constants/initHttp';

export const userReportData = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/userReportData', {
        params,
      })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};
export const userReportDataAll = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/userReportData/all', {
        params,
      })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};
export const userReportDataDetail = (user_id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get(`v1/api/userReportData/userID/${user_id}`)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};
export const userReportDataBasic = (user_id, body) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .put(`v1/api/userReportData/basic/userID/${user_id}`, body)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};
export const userReportDataFamily = (user_id, body) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .put(`v1/api/userReportData/family/userID/${user_id}`, body)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};
export const userReportDataSchool = (user_id, body) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .put(`v1/api/userReportData/lastSchool/userID/${user_id}`, body)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};
export const getMySchool = () => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('/v1/api/school/id')
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};
export const getTemplateUserData = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('/v1/api/userReportDataExcel/template', {
        responseType: 'blob',
        params,
      })
      .then((r) => {
        dispatch(showLoading(false));
        const url = window.URL.createObjectURL(new Blob([r.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Tes.xlsx');
        document.body.appendChild(link);
        link.click();
        // return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};

export const importTemplateData = (data) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) =>
    // dispatch(showLoading(true));
    await httpInjector
      .post('/v1/api/userReportDataExcel/import', data)
      .then((r) => {
        if (r.data.code === 200) {
          notification.success({
            message: 'Sukses',
            description: 'Berhasil merubah data!',
          });
        }
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
};

export const occupationGet = (search = null) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/occupation/select', {
        params: {
          search,
        },
      })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};

export const schoolYearGet = (search = null) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/schoolYear/select', {
        params: {
          search,
        },
      })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.data;
      });
  };
};
