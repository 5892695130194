import { FETCH_FORUM, READ_FORUM } from '@type';

const initState = {
  list: [],
  detail: {},
};

const Forum = (state = initState, action) => {
  switch (action.type) {
    case FETCH_FORUM: {
      return {
        ...state,
        list: action.payload || [],
      };
    }
    case READ_FORUM: {
      return {
        ...state,
        detail: action.payload || {},
      };
    }
    default:
      return state;
  }
};

export default Forum;
