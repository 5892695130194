/* eslint-disable no-prototype-builtins */
import Icon, { InfoCircleFilled, SettingOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Grid, Menu, Row, Select, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clearSetCurrentClass, getCurrentClass, getListClass, getUserProfile } from '@store/actions';
import {
  SVGBook,
  SVGForum,
  SVGForumGuru,
  SVGForumKelas,
  SVGForumSekolah,
  SVGJurnal,
  SVGKelasku,
  SVGLpa,
  SVGRekapNilai,
  SVGSettings,
  SVGUjian,
} from '../../../assets';
import SVGPembayaran from '../../../assets/icon/svg/money-stack-o.svg';
import SVGRaporParent from '../../../assets/icon/svg/paper-white.svg';
import SVGRaporKuanti from '../../../assets/icon/svg/paper-pulse-white.svg';
import SVGRaporKuali from '../../../assets/icon/svg/paper-edit-white.svg';
import SVGDashboard from '../../../assets/icon/svg/new-dashboard.svg';
import SVGJadwal from '../../../assets/icon/svg/new-calender-o.svg';
import SVGAgenda from '../../../assets/icon/svg/agenda-o.svg';
import SVGBankSoal from '../../../assets/icon/svg/file-shield-alt.svg';
import SVGKegiatanBelajar from '../../../assets/icon/svg/monitor-o.svg';
import SVGAbsensi from '../../../assets/icon/svg/file-check-white-alt.svg';
import SVGTugas from '../../../assets/icon/svg/file-edit-alt.svg';
import SVGPodium from '../../../assets/icon/svg/podium.svg';
import defaultSchool from '../../../assets/icon/svg/school_default.svg';
import './menus.scss';
import { getSchoolById, switchClass } from '../../../redux/actions';
import IconCreate from '../../atoms/IconCreate';

const { SubMenu, Item } = Menu;
const isElipsis = (str) => str?.length > 16;
const renderTooltipWhenElipsis = (str, disabled = false) => {
  if (isElipsis(str) && !disabled) {
    return (
      <Tooltip trigger="hover" title={str}>
        {str}
      </Tooltip>
    );
  }
  return str;
};

const DefaultIconSchool = () => <img src={defaultSchool} alt="icon" />;
const CDefaultIconSchool = () => <Icon component={DefaultIconSchool} height={50} width={50} />;
const renderMenu = (menu, setSelectedKey, history, siteType, isMobile = false) => {
  const click = (e) => {
    setSelectedKey(`/${e.path}`);
    history.push(`/${e.path}`, {
      state: e?.state ?? null,
    });
  };
  const sortIt = (childs) => {
    if (siteType === 'wakaf') {
      return childs.sort((a, b) => {
        if (a?.sequence || b?.sequence) {
          return (a?.sequence ?? 0) - (b?.sequence ?? 0);
        }
        return 0;
      });
    }
    return childs;
  };

  if (menu?.children) {
    if (menu.children.length > 1) {
      return (
        <SubMenu
          key={menu.id}
          title={renderTooltipWhenElipsis(menu?.name ?? '', isMobile)}
          icon={<IconCreate svg={menu.icon} />}
        >
          {sortIt(menu.children).map((child) => (
            <Item onClick={() => click(child)} key={`/${child.path}`} icon={<IconCreate svg={child.icon} />}>
              {renderTooltipWhenElipsis(child?.name ?? '', isMobile)}
            </Item>
          ))}
        </SubMenu>
      );
    }
    const single = menu.children[0];
    if (single) {
      return (
        <Item
          onClick={() => click(single)}
          key={`/${single?.path}`}
          icon={<IconCreate svg={single?.icon ?? SVGDashboard} />}
        >
          {renderTooltipWhenElipsis(single?.name ?? '', isMobile)}
        </Item>
      );
    }
  }
  if (menu.path === null) {
    return <></>;
  }
  return (
    <Item onClick={() => click(menu)} key={`/${menu.path}`} icon={<IconCreate svg={menu.icon} />}>
      {renderTooltipWhenElipsis(menu?.name ?? '', isMobile)}
    </Item>
  );
};

function Menus(props) {
  const stateGlobal = useSelector((state) => state);
  const whitelabelRoot = useSelector((state) => state.General.whitelabel);
  const findWlData = (key) => {
    if (whitelabelRoot?.length > 0) {
      const findSitename = whitelabelRoot.filter((e) => e.key === key);
      if (findSitename.length > 0) {
        return findSitename[0].value;
      }
    }
    return null;
  };
  const { xs } = Grid.useBreakpoint();
  const siteType = findWlData('site_type');
  const curClass = useSelector((state) => state.Role.value);
  const bodyWeight = useSelector((state) => state.Pengaturan.refBody);
  const whiteLabelData = useSelector((state) => state.General.whitelabel_data);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(`${window.location.pathname}`);
  const [disallow_production, setDisallowProduction] = useState(process.env.REACT_APP_DISABLED_MENUS?.split(',') ?? []);
  const [state, setState] = useState({
    dataClass: [],
    currentClass: false,
    deleteModal: false,
    dataDelete: false,
    isSubmit: false,
  });
  const [myClass, setMyClass] = useState({});
  const [detailSchool, setDetailSchool] = useState({});
  const history = useHistory();
  const dataTop = [
    {
      id: 'dashboard',
      name: t('dashboard.menu'),
      icon: SVGDashboard,
      path: 'dashboard',
      access: ['SISWA', 'GURU', 'WALIKELAS', 'ORTU'],
    },
    {
      id: 'kelasku-revamp-siswa',
      name: t('kelasku.menu'),
      icon: SVGKelasku,
      path: 'revamp/class/view',
      state: myClass,
      access: ['SISWA'],
    },
    {
      id: 'kelasku-revamp-guru',
      name: t('kelasku.menu'),
      icon: SVGKelasku,
      path: 'revamp/class/view',
      access: ['GURU', 'WALIKELAS', 'ORTU'],
    },
  ];
  // const psbSekolah = [
  //   {
  //     id: 'psb',
  //     name: 'PSB',
  //     icon: SVGPsb,
  //     path: null,
  //     access: ['WALIKELAS', 'ADMIN'],
  //     children: [
  //       {
  //         id: 'master-tahapan',
  //         name: 'Data Tahapan',
  //         icon: SVGPsb,
  //         path: 'psb/master-tahapan',
  //         access: ['WALIKELAS', 'ADMIN'],
  //       },
  //       {
  //         id: 'master-gelombang',
  //         name: 'Gelombang Pendaftaran',
  //         icon: SVGPsb,
  //         path: 'psb/gelombang',
  //         access: ['WALIKELAS', 'ADMIN'],
  //       },
  //     ],
  //   },
  // ];
  const kelasDigital = [
    {
      id: 'kelas-digital-main',
      name: t('rcb.menu'),
      icon: SVGRaporParent,
      path: null,
      access: ['GURU', 'WALIKELAS', 'ORTU', 'SISWA'],
      children: [
        {
          id: 'agenda',
          name: 'Pengumuman',
          icon: SVGAgenda,
          path: 'agenda',
          access: ['GURU', 'WALIKELAS', 'ORTU', 'SISWA'],
        },
        {
          id: 'revamp-jadwal',
          name: t('agenda.menu'),
          icon: SVGJadwal,
          path: 'revamp/jadwal',
          access: ['GURU', 'WALIKELAS', 'SISWA', 'ORTU'],
        },
        {
          id: 'lembar-materi',
          name: t('materi.LABEL_NAME'),
          icon: SVGBook,
          sequence: 1,
          path: 'lembar-materi',
          access: ['GURU', 'WALIKELAS', 'SISWA', 'ORTU'],
        },
        {
          id: 'bank-soal',
          name: t('bank_soal.menu'),
          icon: SVGBankSoal,
          path: 'bank-soal',
          access: ['GURU', 'WALIKELAS'],
        },
      ],
    },
  ];
  const laporanSiswa = [
    {
      id: 'laporan-main',
      name: t('kgb.menu'),
      icon: SVGKegiatanBelajar,
      path: null,
      access: ['GURU', 'WALIKELAS', 'ORTU', 'SISWA'],
      children: [
        {
          id: 'revamp-absensi',
          name: t('absensi.menu'),
          icon: SVGAbsensi,
          path: 'revamp/absensi',
          access: ['GURU', 'WALIKELAS', 'SISWA', 'ORTU'],
        },
        {
          id: 'tugas-revamp',
          sequence: 2,
          name: t('tugas.menu'),
          icon: SVGTugas,
          path: 'revamp/tugas',
          access: ['GURU', 'WALIKELAS', 'ORTU', 'SISWA'],
        },
        {
          id: 'ujian-guru',
          name: t('ujian.menu'),
          icon: SVGAbsensi,
          path: 'quiz',
          access: ['GURU', 'WALIKELAS'],
        },
        {
          id: 'ujian-siswa',
          name: t('ujian.menu'),
          icon: SVGUjian,
          path: 'ujian-siswa',
          access: ['SISWA', 'ORTU'],
        },
        {
          id: 'dokumen',
          name: t('dokumen.menu'),
          icon: SVGRaporParent,
          path: 'dokumen',
          access: ['GURU', 'WALIKELAS', 'SISWA', 'ORTU'],
        },
        {
          id: 'eperpus',
          name: t('eperpus.menu'),
          icon: SVGKelasku,
          path: 'eperpus',
          access: ['GURU', 'WALIKELAS', 'ORTU', 'SISWA'],
        },
        {
          id: 'revamp-konferensi',
          name: 'Kelas Online',
          icon: SVGPodium,
          path: 'revamp/conference',
          access: ['GURU', 'WALIKELAS', 'SISWA', 'ORTU'],
        },
        {
          id: 'jurnal-belajar',
          name: t('jurnal.LABEL_NAME'),
          icon: SVGJurnal,
          sequence: 1,
          path: 'jurnal-belajar',
          access: ['GURU', 'WALIKELAS', 'SISWA', 'ORTU'],
        },
        {
          id: 'blended-learning',
          name: 'Penjadwalan',
          icon: SVGKelasku,
          path: 'blended-learning',
          access: ['WALIKELAS', 'ADMIN'],
        },
        {
          id: 'blended-learning',
          name: 'Penjadwalan',
          icon: SVGKelasku,
          path: 'blended-learning/detail',
          access: ['ORTU'],
        },
        {
          id: 'laporan-nilai-siswa',
          name: `Laporan Nilai ${t('role.SISWA')}`,
          icon: SVGUjian,
          path: 'laporan-nilai-siswa',
          access: ['GURU', 'WALIKELAS'],
        },
      ],
    },
  ];
  const penilaianMain = [
    {
      id: 'penialian-main',
      name: 'Penilaian',
      icon: SVGRaporParent,
      path: null,
      access: ['WALIKELAS', 'ADMIN', 'ORTU', 'SISWA', 'GURU'],
      children: [
        {
          id: 'lpa',
          onlyTK: false,
          name: t('lpa.menu'),
          icon: SVGLpa,
          path: 'lpa',
          access: ['WALIKELAS', 'GURU'],
        },
        {
          id: 'lpa-report',
          onlyTK: false,
          name: t('lpa.menu'),
          icon: SVGUjian,
          path: 'lpa-report',
          access: ['ORTU', 'SISWA'],
        },
        {
          id: 'rekap-nilai-refactor',
          name: t('rekap_nilai.menu'),
          icon: SVGRekapNilai,
          access: ['GURU', 'WALIKELAS'],
          path: 'score-refactor',
        },
        {
          id: 'rapor-kuanti',
          name: 'Rapor Kuantitatif',
          icon: SVGRaporKuanti,
          path: 'rapor',
          access: ['WALIKELAS', 'ADMIN', 'ORTU', 'SISWA', 'GURU'],
        },
        {
          id: 'rapor-kuali',
          name: 'Rapor Kualitatif',
          icon: SVGRaporKuali,
          path: 'rapor-qualitative',
          access: ['WALIKELAS', 'ADMIN', 'ORTU', 'SISWA', 'GURU'],
        },
      ],
    },
  ];
  const pembayaranMain = [
    {
      id: 'tagihan-ortu',
      name: 'Pembayaran',
      icon: SVGPembayaran,
      path: 'tagihan-ortu',
      access: ['ORTU'],
    },
    {
      id: 'tagihan',
      name: 'Pembayaran',
      icon: SVGPembayaran,
      path: 'tagihan',
      access: ['ADMIN'],
    },
  ];
  const forumMain = [
    {
      id: 'forum',
      name: 'Forum',
      icon: SVGForum,
      path: null,
      access: ['WALIKELAS', 'ADMIN', 'ORTU', 'SISWA', 'GURU'],
      children: [
        {
          id: 'forum-sekolah',
          name: 'Forum Sekolah',
          icon: SVGForumSekolah,
          path: 'forum-sekolah',
          access: ['WALIKELAS', 'ADMIN', 'ORTU', 'SISWA', 'GURU'],
        },
        {
          id: 'forum-kelas',
          name: 'Forum Kelas',
          icon: SVGForumKelas,
          path: 'forum-kelas',
          access: ['WALIKELAS', 'ADMIN', 'ORTU', 'SISWA', 'GURU'],
        },
        {
          id: 'forum-guru',
          name: 'Forum Guru',
          icon: SVGForumGuru,
          path: 'forum-guru',
          access: ['WALIKELAS', 'ADMIN', 'GURU'],
        },
      ],
    },
  ];
  const settingMain = [
    {
      id: 'pengaturan',
      name: 'Pengaturan',
      icon: SVGSettings,
      path: 'pengaturan',
      access: ['ADMIN'],
    },
  ];
  const menus = [
    ...dataTop
      .concat(kelasDigital)
      .concat(laporanSiswa)
      .concat(penilaianMain)
      .concat(pembayaranMain)
      .concat(forumMain)
      .concat(settingMain),
  ];
  const renderStyles = () => {
    if (!xs) {
      return {
        height: bodyWeight,
      };
    }
    return undefined;
  };
  const checkRole = (e) => {
    if (disallow_production.includes(e.id)) {
      return false;
    }
    if (e.onlyTK && stateGlobal?.School?.schoolDetail?.grade && stateGlobal?.School?.schoolDetail?.grade !== 'TK') {
      return false;
    }
    if (e?.access) {
      if (e?.children) {
        e.children = e.children.filter((child) => checkRole(child));
      }
      if (e.access.includes('ADMIN') && curClass?.is_admin) {
        return true;
      }
      return e.access.includes(curClass.class_role);
    }
    return true;
  };
  useEffect(() => {
    if (stateGlobal.Role.value.class_role !== '') {
      dispatch(getCurrentClass());
      dispatch(getUserProfile());
      dispatch(getListClass(stateGlobal.Role.value));
    }
  }, [stateGlobal.Role.value]);
  useEffect(() => {
    if (stateGlobal.Class.listClass) {
      setMyClass(stateGlobal.Class.listClass.length > 0 ? stateGlobal.Class.listClass[0] : null);
      const data = stateGlobal.Class.listClass.length > 0 ? stateGlobal.Class.listClass : [];
      if (data) {
        setState({ ...state, dataClass: data });
      }
    }
  }, [stateGlobal.Class.listClass]);

  useEffect(() => {
    if (stateGlobal.Class.currentClass) {
      const data = stateGlobal.Class.currentClass ? stateGlobal.Class.currentClass : false;
      if (data) {
        setState({ ...state, currentClass: data });
        dispatch(clearSetCurrentClass());
      }
    }
  }, [stateGlobal.Class.currentClass]);

  useEffect(() => {
    if (stateGlobal.Class.setCurrentClass) {
      dispatch(getCurrentClass());
      dispatch(getListClass(stateGlobal.Role.value));
    }
  }, [stateGlobal.Class.setCurrentClass]);

  useEffect(() => {
    if (stateGlobal.Class.deleteClass) {
      setState({
        ...state,
        dataDelete: false,
        deleteModal: false,
        isSubmit: false,
      });
      dispatch(getCurrentClass());
      dispatch(getListClass(stateGlobal.Role.value));
    }
  }, [stateGlobal.Class.deleteClass]);

  useEffect(() => {
    if (curClass?.school_id) {
      dispatch(getSchoolById(curClass?.school_id)).then((e) => {
        setDetailSchool(e);
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (whiteLabelData.hidden_menus && whiteLabelData.hidden_menus.length > 0) {
      setDisallowProduction(disallow_production.concat(whiteLabelData.hidden_menus));
    }
  }, [whiteLabelData.hidden_menus]);

  return (
    <div className="main-menu">
      <div className="main-menu-info">
        <Row gutter={10}>
          <Col span={6}>
            {detailSchool && detailSchool?.logo_image_url !== '' && (
              <Avatar src={detailSchool?.logo_image_url} size={40} />
            )}
            {detailSchool && detailSchool?.logo_image_url === '' && (
              <Avatar
                icon={<CDefaultIconSchool />}
                size={40}
                style={{
                  color: 'var(--primary-color)',
                  background: 'transparent',
                }}
              />
            )}
            {!detailSchool && (
              <Avatar
                icon={<CDefaultIconSchool />}
                size={40}
                style={{
                  color: 'var(--primary-color)',
                  background: 'transparent',
                }}
              />
            )}
          </Col>
          <Col span={18}>
            {stateGlobal.Role.value.class_role !== '' && (
              <>
                <div
                  className="main-menu-info-role"
                  onClick={() => {
                    history.push('/ganti-sekolah');
                  }}
                >
                  <Typography.Text>{state.currentClass.school_name}</Typography.Text>
                </div>
              </>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={20}>
            {stateGlobal.Role.value.class_role === '' ? (
              <Button
                block
                type="primary"
                icon={<SettingOutlined />}
                onClick={() => {
                  history.push('/gabung-sekolah');
                }}
              >
                Gabung Sekolah
              </Button>
            ) : (
              <>
                <Select
                  className="dropdown-primary"
                  dropdownClassName="dropdown-primary-active"
                  placeholder={
                    state.dataClass.filter((e) => e.class_id === state.currentClass.class_id).length > 0
                      ? 'Pilih Kelas'
                      : 'Kelas Tidak Ada'
                  }
                  style={{
                    width: '100%',
                    borderRadius: '14px !important',
                  }}
                  disabled={state.dataClass.length === 0}
                  value={
                    state.dataClass.filter((e) => e.id === state.currentClass.class_id).length > 0
                      ? state.currentClass.class_id
                      : null
                  }
                  onChange={(r) => {
                    if (r) {
                      dispatch(
                        switchClass({
                          id: r,
                        })
                      );
                    } else {
                      history.push('/pengaturan/kelas', {
                        openModal: true,
                      });
                    }
                  }}
                >
                  {state.dataClass.map((r) => (
                    <Select.Option key={r.id} value={r.id}>
                      {r.name}
                    </Select.Option>
                  ))}
                  {((curClass.class_role === 'WALIKELAS' && curClass.is_admin) || curClass.is_admin) && (
                    <Select.Option
                      key="new"
                      value={0}
                      style={{
                        color: 'var(--primary-color)',
                        cursor: 'pointer',
                        textAlign: 'center',
                        pointerEvents: 'all',
                      }}
                    >
                      Tambah Kelas Baru
                    </Select.Option>
                  )}
                </Select>
              </>
            )}
          </Col>
          <Col span={4}>
            {stateGlobal.Role.value.class_role !== '' && (
              <Tooltip title="Detail Kelas" trigger="hover">
                <Button
                  shape="circle"
                  type="link"
                  style={{ marginLeft: 5 }}
                  icon={<InfoCircleFilled />}
                  onClick={() => {
                    history.push('/revamp/class/view');
                  }}
                />
              </Tooltip>
            )}
          </Col>
        </Row>
      </div>
      <div className="main-menu-box" style={renderStyles()}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div>
            <Menu
              onClick={props.onSelect}
              className="sidebar-menu "
              selectedKeys={[selectedKey]}
              defaultOpenKeys={['main-0']}
              mode="inline"
              style={{ paddingBottom: 0 }}
            >
              {menus.filter((e) => checkRole(e)).map((menu) => renderMenu(menu, setSelectedKey, history, siteType, xs))}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menus;
