import moment from 'moment';
import axios from 'axios';
import { objectString } from '@utils';
import { BASE_API_URL, BASE_VERSION } from '@http';
import { BASE_LOGIN } from '../../constants/initHttp';
import { generateSignature, notificationOverride } from '../../utils/Utils';
import { store } from '../../redux/store';

axios.interceptors.request.use(
  (config) => {
    if (config?.headers?.Authorization) {
      const { method } = config;
      let _body = '';
      let _token = '';
      let _url = config.url;
      if (method === 'post' || method === 'put') {
        _body = null; // signature crash
      }
      const host = _url.split('/')[2];
      _url = _url.replace(`https://${host}`, '');
      _token = config.headers.Authorization;
      config.headers.Timestamp = moment().toISOString();
      let pathName = '';
      config.params &&
        Object.keys(config.params).map((_key, index) => {
          if (config.params[_key] !== null) {
            pathName += `${_key}=${config.params[_key] ?? ''}`;
            if (Object.keys(config.params).length - 1 !== index) {
              pathName += '&';
            }
          }
          return _key;
        });
      if (pathName !== '') {
        _url = `${_url}?${pathName.replace(' ', '+')}`;
      }
      config.headers.Signature = generateSignature(
        _url,
        _token.replace(' ', ''),
        config.method.toUpperCase(),
        config.headers.Timestamp,
        _body
      );

      delete config.headers.Signature;
      delete config.headers.Timestamp;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// global axios interceptors response
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      const _message = error?.response?.data?.message ?? error?.response?.data?.data ?? error?.response?.data?.error;
      if (_message === 'invalid authorization') {
        // notificationOverride({
        //   title: 'Permintaan Ditolak',
        //   description: 'Sesi login Anda telah berakhir. Silakan login kembali untuk melanjutkan.',
        //   type: 'warning',
        // });
      } else if (['unauthorized device', 'UNAUTHORIZED DEVICE'].includes(_message)) {
        notificationOverride({
          title: 'Perangkat Baru Telah Terdaftar',
          description:
            'Secara otomatis akun Anda dikeluarkan dari situs. Silahkan lakukan login kembali untuk mengakses Siswamedia.',
          type: 'warning-check',
        });
      }
      store.dispatch({ type: 'LOGOUT', value: true });
    }
    return Promise.reject(error);
  }
);
const headers = (token, withBearer = false) => {
  let data = {
    'Content-Type': 'application/json',
  };

  if (token) {
    data = {
      'Content-Type': 'application/json',
      Authorization: `${withBearer ? 'Bearer' : ''} ${token}`,
      'X-Content-Type-Options': 'nosniff ',
      'Access-Control-Allow-Origin': '*',
    };
  }

  return data;
};

export const requestPost = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE_API_URL}${BASE_VERSION}${url}`, params, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestPatch = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .patch(`${BASE_API_URL}${BASE_VERSION}${url}`, params, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestGet = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_API_URL}${BASE_VERSION}${url}${params ? objectString(params) : ''}`, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .then((error) => {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestGetIdentity = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_LOGIN}/v1/api/${url}${params ? objectString(params) : ''}`, {
        headers: headers(token, true),
      })
      .then((response) => {
        resolve(response.data);
      })
      .then((error) => {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestPostIdentity = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE_LOGIN}/v1/api/${url}`, params, {
        headers: headers(token, true),
      })
      .then((response) => {
        resolve(response.data);
      })
      .then((error) => {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestPutIdentity = (url, params, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .put(`${BASE_LOGIN}/v1/api/${url}${params ? objectString(params) : ''}`, body, {
        headers: headers(token, true),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestPut = (url, params, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .put(`${BASE_API_URL}${BASE_VERSION}${url}${params ? objectString(params) : ''}`, body, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestDelete = (url, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    const isNull = body === null;
    let config = {};
    if (isNull) {
      config = {
        headers: headers(token),
      };
    } else {
      config = {
        headers: headers(token),
        data: body ?? '',
        params: body ?? '',
      };
    }
    axios
      .delete(`${BASE_API_URL}${BASE_VERSION}${url}`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestAnyGet = (url, params, headers) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(
        `${url}${objectString(params)}`,
        headers && {
          headers,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
