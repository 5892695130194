import { Select as Choose } from 'antd';

const { Option } = Choose;
const Select = ({ data = [], onChange, ...props }) => {
  if (props.defaultValue) {
    return (
      <Choose
        className={props.readOnly ? 'readOnly' : undefined}
        name={props.name}
        placeholder={props.placeholder ? props.placeholder : 'Select this option'}
        disabled={props.disabled ? props.disabled : false}
        onChange={onChange}
        defaultValue={props.defaultValue}
        open={props.readOnly ? false : undefined}
        allowClear={props.allowClear ? props.allowClear : false}
      >
        {data != null &&
          data.length > 0 &&
          data.map((item, index) => (
            <Option key={index} value={item[props.value]}>
              {item[props.text]}
            </Option>
          ))}
      </Choose>
    );
  }
  return (
    <Choose
      className={props.readOnly ? 'readOnly' : undefined}
      name={props.name}
      placeholder={props.placeholder ? props.placeholder : 'Select this option'}
      disabled={props.disabled ? props.disabled : false}
      onChange={onChange}
      open={props.readOnly ? false : undefined}
      allowClear={props.allowClear ? props.allowClear : false}
    >
      {data != null &&
        data.length > 0 &&
        data.map((item, index) => (
          <Option key={index} value={item[props.value]}>
            {item[props.text]}
          </Option>
        ))}
    </Choose>
  );
};

// FYI : params text/value for get object from data

export default Select;
