import {
  CREATE_CLASS,
  CREATE_MAPEL,
  CREATE_SYNC_CLASS,
  DELETE_CLASS,
  DELETE_MAPEL,
  DELETE_STUDENT,
  GET_CLASS_ME,
  GET_COURSE_BY_SCHOOL,
  GET_CURRENT_CLASS,
  GET_MAPEL_BY_CLASS,
  GET_ONE_CLASS,
  GET_ONE_SCHOOL,
  GET_STUDENT_BY_CLASS,
  GET_SYNC_CLASS,
  GET_SYNC_STUDENT,
  GET_SYNC_TEACHER,
  GET_TEACHER_BY_CLASS,
  SAVE_STATE_FOR_CHANGE_ROLE,
  SET_CURRENT_CLASS,
  SET_WALIKELAS,
  UPDATE_CLASS,
  UPDATE_MAPEL,
} from '@type';

const initState = {
  currentClass: false,
  createClass: false,
  updateClass: false,
  deleteClass: false,
  listClass: false,
  setCurrentClass: false,
  listTeacher: false,
  setWaliKelas: false,
  listDataMurid: false,
  listDataMapel: false,
  createMapel: false,
  updateMapel: false,
  deleteMapel: false,
  deleteStudent: false,
  stateForChangeRole: false,
  courseBySchool: false,
  syncClassData: false,
  syncStudentData: false,
  syncTeacherData: false,
  createSyncClass: false,
  oneClass: false,
  oneSchool: false,
};

const Class = (state = initState, action) => {
  switch (action.type) {
    case GET_CURRENT_CLASS:
      return {
        ...state,
        currentClass: action.value,
      };

    case SET_CURRENT_CLASS:
      return {
        ...state,
        setCurrentClass: action.value,
      };

    case GET_CLASS_ME:
      return {
        ...state,
        listClass: action.value,
      };

    case SAVE_STATE_FOR_CHANGE_ROLE:
      return {
        ...state,
        stateForChangeRole: action.value,
      };
    case GET_TEACHER_BY_CLASS:
      return {
        ...state,
        listTeacher: { data: action.value.data, total: action.value.pagination.total },
      };

    case CREATE_CLASS:
      return {
        ...state,
        createClass: action.value,
      };

    case UPDATE_CLASS:
      return {
        ...state,
        updateClass: action.value,
      };

    case DELETE_CLASS:
      return {
        ...state,
        deleteClass: action.value,
      };

    case SET_WALIKELAS:
      return {
        ...state,
        setWaliKelas: action.value,
      };

    case GET_STUDENT_BY_CLASS:
      return {
        ...state,
        listDataMurid: { data: action.value.data, total: action.value.pagination.total },
      };

    case GET_MAPEL_BY_CLASS:
      return {
        ...state,
        listDataMapel: { data: action.value.data, total: action.value.pagination.total },
      };

    case CREATE_MAPEL:
      return {
        ...state,
        createMapel: action.value,
      };

    case UPDATE_MAPEL:
      return {
        ...state,
        updateMapel: action.value,
      };

    case DELETE_MAPEL:
      return {
        ...state,
        deleteMapel: action.value,
      };

    case DELETE_STUDENT:
      return {
        ...state,
        deleteStudent: action.value,
      };

    case GET_COURSE_BY_SCHOOL:
      return {
        ...state,
        courseBySchool: action.value,
      };

    case GET_SYNC_CLASS:
      return {
        ...state,
        syncClassData: action.value,
      };

    case GET_SYNC_STUDENT:
      return {
        ...state,
        syncStudentData: action.value,
      };

    case GET_SYNC_TEACHER:
      return {
        ...state,
        syncTeacherData: action.value,
      };

    case CREATE_SYNC_CLASS:
      return {
        ...state,
        createSyncClass: action.value,
      };
    case GET_ONE_CLASS:
      return {
        ...state,
        oneClass: action.value,
      };
    case GET_ONE_SCHOOL:
      return {
        ...state,
        oneSchool: action.value,
      };

    default:
      return state;
  }
};

export default Class;
