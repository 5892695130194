import { requestDelete, requestGet, requestPost, requestPut } from '@configs/evaluasi';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import {
  CREATE_KATEGORI_PENILAIAN,
  DELETE_KATEGORI_PENILAIAN,
  LIST_KATEGORI_PENILAIAN,
  UPDATE_KATEGORI_PENILAIAN,
} from '@type';

export const getListKategoriPenilaian = () => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('assessmentcategories', null, getUserToken());
    dispatch({
      type: LIST_KATEGORI_PENILAIAN,
      value: rest.payload.list,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createKategoriPenilaian = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('assessmentcategories', data, getUserToken());
    dispatch({
      type: CREATE_KATEGORI_PENILAIAN,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const updateKategoriPenilaian = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPut(`assessmentcategories/${id}`, null, data, getUserToken());
    dispatch({
      type: UPDATE_KATEGORI_PENILAIAN,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const deleteKategoriPenilaian = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestDelete(`assessmentcategories/${id}`, null, getUserToken());

    dispatch({ type: DELETE_KATEGORI_PENILAIAN, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const clearCreateKategoriPenilaian = () => ({
  type: CREATE_KATEGORI_PENILAIAN,
  value: false,
});

export const clearUpdateKategoriPenilaian = () => ({
  type: UPDATE_KATEGORI_PENILAIAN,
  value: false,
});

export const clearDeleteKategoriPenilaian = () => ({
  type: DELETE_KATEGORI_PENILAIAN,
  value: false,
});
