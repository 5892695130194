import { showLoading } from '@store/actions';
import axios from 'axios';
import { notification } from 'antd';
import {
  MASTER_BANK,
  MASTER_COURSE_CERTIFICATE_TYPE,
  MASTER_COURSE_DIFFICULTY_LEVEL,
  MASTER_COURSE_SCHEDULE_TYPE,
  MASTER_COURSE_TYPE,
  MASTER_LANG,
  UPLOAD_COVER,
} from '@type';
import { getUserToken } from '@utils';
import { BASE_COURSE } from '../../../../constants/initHttp';
import { reportSentry } from '../../../../utils/Utils';

export const getCourseScheduleType = (page, limit) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(`${BASE_COURSE}/v1/api/master/course_schedule_type?page=${page}&limit=${limit}`)
    .then((response) => {
      if (response.data.code === 200) {
        dispatch({
          type: MASTER_COURSE_SCHEDULE_TYPE,
          value: response.data.data,
        });
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const getCourseDifficulty = (page, limit) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(`${BASE_COURSE}/v1/api/master/course_difficulty_level?page=${page}&limit=${limit}`)
    .then((response) => {
      if (response.data.code === 200) {
        dispatch({ type: MASTER_COURSE_DIFFICULTY_LEVEL, value: response.data.data });
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const getCertificateType = (page, limit) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(`${BASE_COURSE}/v1/api/master/course_certificate_type?page=${page}&limit=${limit}`)
    .then((response) => {
      if (response.data.code === 200) {
        dispatch({ type: MASTER_COURSE_CERTIFICATE_TYPE, value: response.data.data });
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const getLangType = (page, limit) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(`${BASE_COURSE}/v1/api/master/course_lang?page=${page}&limit=${limit}`)
    .then((response) => {
      if (response.data.code === 200) {
        dispatch({ type: MASTER_LANG, value: response.data.data });
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const getBankList = (page, limit) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(`${BASE_COURSE}/v1/api/master/course_bank?page=${page}&limit=${limit}`)
    .then((response) => {
      if (response.data.code === 200) {
        dispatch({ type: MASTER_BANK, value: response.data.data });
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const getCourseType = (page, limit) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(`${BASE_COURSE}/v1/api/master/course_type?page=${page}&limit=${limit}`)
    .then((response) => {
      if (response.data.code === 200) {
        dispatch({ type: MASTER_COURSE_TYPE, value: response.data.data });
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const uploadCover = (file) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .post(`${BASE_COURSE}/v1/course/file/upload`, file, { headers: { Authorization: `Bearer ${getUserToken()}` } })
    .then((response) => {
      if (response.data.code === 200) {
        dispatch({ type: UPLOAD_COVER, value: response.data.data });
      } else {
        dispatch({ type: UPLOAD_COVER, value: null });
      }
      dispatch(showLoading(false));
      return response.data;
    })
    .catch((error) => {
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};
