import {
  CREATE_DETAIL_KD,
  CREATE_KARAKTER_DASAR,
  CREATE_RINCIAN_KD,
  DELETE_DETAIL_KD,
  DELETE_KARAKTER_DASAR,
  DELETE_RINCIAN_KD,
  FIND_KARAKTER_DASAR,
  LIST_DETAIL_KD,
  LIST_KARAKTER_DASAR,
  LIST_RINCIAN_KD,
  UPDATE_DETAIL_KD,
  UPDATE_KARAKTER_DASAR,
  UPDATE_RINCIAN_KD,
} from '@type';

const initState = {
  list: false,
  create: false,
  update: false,
  delete: false,
  find: false,

  listDetail: false,
  createDetail: false,
  updateDetail: false,
  deleteDetail: false,

  listRincian: false,
  createRincian: false,
  updateRincian: false,
  deleteRincian: false,
};

const KarakterDasar = (state = initState, action) => {
  switch (action.type) {
    case LIST_KARAKTER_DASAR:
      return {
        ...state,
        list: action.value,
      };

    case CREATE_KARAKTER_DASAR:
      return {
        ...state,
        create: action.value,
      };

    case UPDATE_KARAKTER_DASAR:
      return {
        ...state,
        update: action.value,
      };

    case DELETE_KARAKTER_DASAR:
      return {
        ...state,
        delete: action.value,
      };

    case FIND_KARAKTER_DASAR:
      return { ...state, find: action.value };

    case LIST_DETAIL_KD:
      return {
        ...state,
        listDetail: action.value,
      };

    case CREATE_DETAIL_KD:
      return {
        ...state,
        createDetail: action.value,
      };

    case UPDATE_DETAIL_KD:
      return {
        ...state,
        updateDetail: action.value,
      };

    case DELETE_DETAIL_KD:
      return {
        ...state,
        deleteDetail: action.value,
      };

    case LIST_RINCIAN_KD:
      return {
        ...state,
        listRincian: action.value,
      };

    case CREATE_RINCIAN_KD:
      return {
        ...state,
        createRincian: action.value,
      };

    case UPDATE_RINCIAN_KD:
      return {
        ...state,
        updateRincian: action.value,
      };

    case DELETE_RINCIAN_KD:
      return {
        ...state,
        deleteRincian: action.value,
      };

    default:
      return state;
  }
};

export default KarakterDasar;
