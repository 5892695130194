import { FETCH_EXAM_QUESTION, FETCH_STUDENT_EXAM, READ_STUDENT_EXAM, SUBMIT_STUDENT_EXAM } from '@type';
import { COLLECT_ANSWER, READ_QNA_EXAM } from '../../../../constants/initType';
import { handleCollectAnswer } from './functions';

const initialState = {
  list: {
    data: [],
    meta: {
      page: 1,
      limit: 10,
      max_page: 1,
      total_items: 5,
    },
  },
  detail: null,
  questions: null,
  qna: null,
  answerCollection: [],
};

const UjianSiswa = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STUDENT_EXAM:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.value.list,
          meta: action.value.meta,
        },
      };
    case READ_STUDENT_EXAM:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.value,
        },
      };
    case READ_QNA_EXAM:
      return {
        ...state,
        qna: action.value,
      };
    case FETCH_EXAM_QUESTION:
      return {
        ...state,
        questions: action.value,
      };
    case COLLECT_ANSWER:
      return {
        ...state,
        answerCollection: handleCollectAnswer(action.data, state.answerCollection),
      };
    case SUBMIT_STUDENT_EXAM:
      return {
        ...state,
        answerCollection: [],
      };

    case 'FORCE_SET_DATA':
      return {
        ...state,
        [action.path]: action.data,
      };
    case 'CLEAR_STATE_DETAIL':
      return {
        ...state,
        detail: null,
      };

    default:
      return state;
  }
};
export default UjianSiswa;
