/*eslint-disable */
import { notification } from 'antd';
import { requestGet } from '@configs';
import { showLoading } from '@store/actions';
import { getGoogleToken, getUserToken } from '@utils';
import {
  CHANGE_SCHOOL,
  CREATE_CLASS,
  CREATE_MAPEL,
  CREATE_SYNC_CLASS,
  DELETE_CLASS,
  DELETE_MAPEL,
  DELETE_STUDENT,
  DETAIL_SCHOOL,
  GET_CLASS_ME,
  GET_CLASSES,
  GET_COURSE_BY_SCHOOL,
  GET_CURRENT_CLASS,
  GET_MAPEL_BY_CLASS,
  GET_ONE_CLASS,
  GET_ONE_SCHOOL,
  GET_ROLE,
  GET_STUDENT_BY_CLASS,
  GET_SYNC_CLASS,
  GET_SYNC_STUDENT,
  GET_SYNC_TEACHER,
  GET_TEACHER_BY_CLASS,
  SAVE_STATE_FOR_CHANGE_ROLE,
  SET_CURRENT_CLASS,
  SET_WALIKELAS,
  SET_WALIKELAS_DATA,
  STATUS_ROLE,
  UPDATE_CLASS,
  UPDATE_MAPEL,
} from '@type';
import { requestAnyGet, requestDelete, requestPost, requestPut } from '@configs/engine';
import axios from 'axios';
import { BASE_API_URL, BASE_LOGIN } from '../../../constants/initHttp';
import { AxiosInject, reportSentry } from '../../../utils/Utils';
import { runError } from '../../../utils/errorHandler';

export const getClasses = (params = null) => async (dispatch) => {
  const api = await requestGet(`me/classes`, params, getUserToken())
    .then((response) => {
      dispatch({ type: GET_CLASSES, value: response.data });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const getChildData = () => async (dispatch) => {
  dispatch(showLoading(true));
  const http = AxiosInject(BASE_LOGIN, 'Bearer', getUserToken());
  return http
    .get(`v1/api/user/children`)
    .then((response) => {
      dispatch(showLoading(false));
      return response.data;
    })
    .catch((error) => {
      dispatch(showLoading(false));
      reportSentry(error);
    });
};
export const getClassOnlyMe = (school_id, class_level) => {
  const http = AxiosInject(BASE_API_URL, '', getUserToken());
  return http
    .get(`v2/schools/${school_id ?? 0}/classes?onlyme=true&status=active${class_level ? `&grade=${class_level}` : ''}`)
    .then((_response) => {
      return _response.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const autoSubmitSchoolAndClasses = (dispatch) => {
  const http = AxiosInject(BASE_API_URL, '', getUserToken());
  return http
    .get(`v2/me/schools?search=&role=`)
    .then((response) => {
      const { data } = response.data;
      const lastSchool = data[data.length - 1];
      console.log('lastSchool', lastSchool);
      const isOnWizard = window.location.pathname.indexOf('/wizard');
      if (isOnWizard > -1) {
        return 'is_on_wizard';
      }
      if (lastSchool?.id && data.length === 1) {
        return new Promise((resolve, reject) => {
          dispatch(switchSchool({ id: lastSchool.id }, {}, true))
            .then((_success) => {
              return http.get(`v2/schools/${lastSchool.id}/classes?onlyme=true&status=active`).then((_response) => {
                const { data } = _response.data;
                const lastClass = data[data.length - 1];
                return dispatch(switchClass({ id: lastClass.id })).then(() => {
                  window.location.href = '/dashboard';
                });
                resolve('OK');
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      } else if (data.length === 0) {
        return 'no_school';
      } else {
        return false;
      }
    })
    .catch((error) => {
      reportSentry(error);
      throw error;
    });
};

export const getCoursesBySchool = (id) => async (dispatch) => {
  const api = await requestGet(`course-teach/${id}?status=active`, false, getUserToken())
    .then((response) => {
      dispatch({ type: GET_COURSE_BY_SCHOOL, value: response.data });
      return response;
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const getCurrentClass = () => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestGet('me/current-class-json', null, getUserToken());

      const payload = {
        ...rest.data,
        class_id: rest.data.id,
      };
      dispatch(showLoading(false));
      dispatch({ type: GET_CURRENT_CLASS, value: payload });
      dispatch(getOneClass(rest.data.id));
      return payload;
    } catch (error) {
      if (error?.message !== "Cannot read properties of undefined (reading 'id')") {
        // notification.error({
        //   message: 'Mohon Maaf!',
        //   description: 'Silahkan Hubungi Customer Service Kami',
        // });
      }
    }
    dispatch(showLoading(false));
  };
};

export const getTeacherByClass = (classId, page, limit) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestGet(
        `classes/${classId}/teachers?page=${page || 1}&sortBy=name&order=asc&limit=${limit || 100}`,
        null,
        getUserToken()
      );
      dispatch({ type: GET_TEACHER_BY_CLASS, value: rest });
    } catch (error) {
      // notification.error({
      //     message: "Mohon Maaf!",
      //     description: "Silahkan Hubungi Customer Service Kami",
      // });
    }
    dispatch(showLoading(false));
  };
};

export const getStudentByClass = (classId, page, limit, params = {}) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestGet(
        `classes/${classId}/students?page=${page || 1}&sortBy=name&order=asc&limit=${limit || 100}`,
        null,
        getUserToken()
      );
      dispatch({ type: GET_STUDENT_BY_CLASS, value: rest });
      dispatch(showLoading(false));
      return rest;
    } catch (error) {
      // notification.error({
      //     message: "Mohon Maaf!",
      //     description: "Silahkan Hubungi Customer Service Kami",
      // });
      dispatch(showLoading(false));
    }
  };
};

export const getMapelByClass = (classId, page, limit, isteacher, params) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestGet(
        `classes/${classId}/courses`,
        {
          sortby: 'name',
          orderby: 'asc',
          limit: limit || 100,
          page: page || 1,
          isteacher: isteacher ?? false,
          ...params,
        },
        getUserToken()
      );
      dispatch({ type: GET_MAPEL_BY_CLASS, value: rest });
      dispatch(showLoading(false));
      return rest;
    } catch (error) {
      // notification.error({
      //     message: "Mohon Maaf!",
      //     description: "Silahkan Hubungi Customer Service Kami",
      // });
      dispatch(showLoading(false));
      return error;
    }
  };
};

export const getListClass = (currentSchool) => {
  let params = {
    onlyme: !currentSchool.is_admin,
    status: 'active',
  };

  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      if (currentSchool.school_id) {
        let rest = await requestGet(`schools/${currentSchool.school_id ?? 0}/classes`, params, getUserToken());
        dispatch({ type: GET_CLASS_ME, value: rest.data });
        dispatch(showLoading(false));
        return rest.data;
      }
      return null;
    } catch (error) {
      dispatch(showLoading(false));
      if (error.response?.data?.message === 'invalid authorization') {
        localStorage.clear();
        window.location.href = '/';
      }
      if (currentSchool.school_id) {
        // notification.error({
        //     message: "Mohon Maaf!",
        //     description: "Silahkan Hubungi Customer Service Kami",
        // });
      }
      return [];
    }
  };
};

export const createClass = (data) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestPost(`classes`, data, getUserToken());
      dispatch({ type: CREATE_CLASS, value: true });
      if (rest.status_code === 200) {
        dispatch(showLoading(false));
        dispatch(
          switchClass({
            id: rest.data.id,
          })
        );
        return rest.data.id;
      }
    } catch (error) {
      dispatch({ type: CREATE_CLASS, value: 'error' });
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: error.response.data.message
      //       ? error.response.data.message
      //       : 'Silahkan Hubungi Customer Service Kami',
      // });
    }
    dispatch(showLoading(false));
  };
};

export const updateClass = (id, data) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let res = await requestPut(`classes/${id}`, null, data, getUserToken());
      dispatch({ type: UPDATE_CLASS, value: true });
      dispatch(switchClass({ id }));
      if (res.status_code === 200) {
        dispatch(showLoading(false));
        return true;
      }
    } catch (error) {
      dispatch({ type: UPDATE_CLASS, value: 'error' });
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: error.response.data.message
      //       ? error.response.data.message
      //       : 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
    }
    dispatch(showLoading(false));
  };
};

export const deleteClass = (id) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let res = await requestDelete(`classes/${id}`, null, getUserToken());
      dispatch(showLoading(false));
      return true;
    } catch (error) {
      runError(error.response.data.message, error);
    }
    dispatch(showLoading(false));
  };
};

export const createMapel = (data) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestPost(`courses`, data, getUserToken());
      dispatch({ type: CREATE_MAPEL, value: true });
      if (rest.status_code === 200) return true;
    } catch (error) {
      const msg = error.response.data.message;
      runError(msg, error);
    }
    dispatch(showLoading(false));
  };
};

export const getMapelByClassAll = (classId, params) => {
  return async (dispatch) => {
    try {
      let rest = await requestGet(`classes/${classId}/courses`, params, getUserToken());
      dispatch({ type: GET_MAPEL_BY_CLASS, value: rest });
    } catch (error) {
      throw error;
    }
  };
};

export const updateMapel = (mapelId, data) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestPut(`courses/${mapelId}`, null, data, getUserToken());
      dispatch({ type: UPDATE_MAPEL, value: true });
      if (rest.status_code === 200) return true;
    } catch (error) {
      const msg = error.response.data.message;
      runError(msg, error);
    }
    dispatch(showLoading(false));
  };
};

export const setCurrentClass = (form) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestPut(`me/current-class-json`, { data: JSON.stringify(form) }, null, getUserToken());
      dispatch({ type: SET_CURRENT_CLASS, value: true });
    } catch (error) {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    }
    dispatch(showLoading(false));
  };
};

export const deleteMapel = (courseId) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestDelete(`courses/${courseId}`, null, getUserToken());
      dispatch({ type: DELETE_MAPEL, value: true });
      return rest;
    } catch (error) {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    }
    dispatch(showLoading(false));
  };
};

export const deleteStudentV2 = (id, params = {}) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      // params to qs
      const QSParams = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      const append = QSParams ? `?${QSParams}` : '';
      let rest = await requestDelete(`member-v2/id/${id}${append}`, null, getUserToken());
      dispatch({ type: DELETE_STUDENT, value: true });
      return rest;
    } catch (error) {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    }
    dispatch(showLoading(false));
  };
};

export const deleteStudent = (data) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestDelete(`member`, data, getUserToken());
      dispatch({ type: DELETE_STUDENT, value: true });
      return rest;
    } catch (error) {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    }
    dispatch(showLoading(false));
  };
};

export const updateStudent = (id, data) => {
  const httpAdapter = AxiosInject(BASE_LOGIN, 'Bearer', getUserToken());
  return (dispatch) => {
    dispatch(showLoading(true));
    return httpAdapter
      .put(`v1/api/user/email/${id}`, data)
      .then((res) => {
        dispatch(showLoading(false));
        return res.data;
      })
      .catch((err) => {
        dispatch(showLoading(false));
        runError(err?.response?.data.messages[0], err);
      });
  };
};

export const setWaliKelas = (classId, data) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestPut(`classes/${classId}/promote-teacher-to-walikelas`, null, data, getUserToken());

      dispatch({
        type: SET_WALIKELAS,
        value: { type: 'success', data: rest.data },
      });
      dispatch(getCurrentClass());
      if (rest.status_code === 200) {
        return true;
      }
    } catch (error) {
      dispatch({
        type: SET_WALIKELAS,
        value: { type: 'error', data: error.response?.data?.message },
      });
    }
    dispatch(showLoading(false));
  };
};

export const getSyncClass = (url, params) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestAnyGet(url, params, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getGoogleToken()}`,
      });

      dispatch({ type: GET_SYNC_CLASS, value: rest.courses });
    } catch (error) {
      notification.error({
        message: 'Mohon Maaf!',
        description: 'Ada kesalahan saat mengambil data kelas di google classroom.',
      });
    }
    dispatch(showLoading(false));
  };
};

export const getSyncStudent = (url, params) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestAnyGet(url, params, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getGoogleToken()}`,
      });

      dispatch({
        type: GET_SYNC_STUDENT,
        value: rest && Object.keys(rest).length === 0 && rest.data.constructor === Object ? false : rest,
      });
    } catch (error) {
      notification.error({
        message: 'Mohon Maaf!',
        description: 'Ada kesalahan saat mengambil data siswa di google classroom.',
      });
    }
    dispatch(showLoading(false));
  };
};

export const getSyncTeacher = (url, params) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestAnyGet(url, params, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getGoogleToken()}`,
      });

      dispatch({
        type: GET_SYNC_TEACHER,
        value: rest && Object.keys(rest).length === 0 && rest.constructor === Object ? false : rest,
      });
    } catch (error) {
      notification.error({
        message: 'Mohon Maaf!',
        description: 'Ada kesalahan saat mengambil data guru di google classroom.',
      });
    }
    dispatch(showLoading(false));
  };
};

export const createSyncClass = (data) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestPost(`classes/gcr`, data, getUserToken());
      dispatch({ type: CREATE_SYNC_CLASS, value: true });
    } catch (error) {
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message ? error.response.data.message : 'Ada kesalahan pada request data.',
      });
    }
    dispatch(showLoading(false));
  };
};

export const joinClassess = (invitation_code, role, showError = true) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost(
    `join-classes-v2`,
    {
      invitation_code: invitation_code,
      role,
    },
    getUserToken()
  )
    .then((response) => {
      if (response) {
        // dispatch({
        //     type: IS_SUCCESS,
        //     value: true,
        //     message: "Join School Success",
        // });
        dispatch(
          switchClass({
            id: response.data.class_id,
          })
        );
        dispatch(showLoading(false));
        return true;
      } else {
        dispatch(showLoading(false));
        // dispatch({
        //     type: IS_SUCCESS,
        //     value: false,
        //     message: "Join School Failed",
        // });
        return false;
      }
    })
    .catch((error) => {
      if (showError) {
        if (error.response.status === 404) {
          notification.error({
            message: 'Kode tidak ditemukan silahkan hubungi Operator atau walikelas',
          });
        }
        if (error.response.status === 403) {
          notification.error({
            message: 'Kode Orang Tua Sudah Pernah Digunakan',
          });
        }
        if (error.response.status === 409) {
          notification.error({
            message: 'Kode Sudah Digunakan silahkan Hubungi Operator atau cek Kelasku',
          });
        }
        if (error.response.status === 400) {
          if (error.response.data?.message === 'already_joined') {
            notification.error({
              message: 'Anda telah bergabung di kelas ini',
            });
          } else {
            notification.error({
              message: 'Mohon maaf, kode undangan tidak ditemukan',
            });
          }
        }
        reportSentry(error);
        dispatch(showLoading(false));
      } else {
        reportSentry(error);
        dispatch(showLoading(false));
        return error.response.data?.message || false;
      }
    });
  return api;
};

export const exportInvitationCode = (classId) => async (dispatch) => {
  const api = await axios({
    url: `${BASE_LOGIN}/v1/api/export/parentCode?class_id=${classId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    },
    responseType: 'blob',
    params: {
      class_id: classId,
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Invitation Code.xlsx');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw err;
    });
};

export const getOneClass = (class_id) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestGet(`classes/${class_id}`, false, getUserToken());
      dispatch(showLoading(false));
      dispatch({ type: GET_ONE_CLASS, value: rest.data });
      return rest.data;
    } catch (error) {
      // notification.error({
      //     message: "Mohon Maaf!",
      //     description: "Silahkan Hubungi Customer Service Kami",
      // });
    }
    dispatch(showLoading(false));
  };
};
export const getOneClassPlain = (class_id) => {
  return requestGet(`classes/${class_id}`, false, getUserToken())
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const switchClass = (data) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestPost(`classes/switch-classes`, data, getUserToken());
      dispatch({ type: GET_ROLE, value: rest.data });
      dispatch({ type: SET_CURRENT_CLASS, value: true });
      dispatch(showLoading(false));
      return true;
    } catch (error) {
      runError(null, error);
      dispatch(showLoading(false));
      return false;
    }
  };
};
export const switchSchool = (data, school = {}, noModal = false) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestPost(`schools/switch-school`, data, getUserToken());
      if (rest.status_code === 200) {
        // dispatch(getRole());
        dispatch({ type: GET_ROLE, value: rest.data });
        dispatch({ type: DETAIL_SCHOOL, value: school });
        dispatch({ type: STATUS_ROLE, value: true });
        if (!noModal) {
          dispatch({ type: CHANGE_SCHOOL, value: true });
          dispatch({
            type: SAVE_STATE_FOR_CHANGE_ROLE,
            value: rest.data,
          });
        }
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error,
      });
    }
    dispatch(showLoading(false));
  };
};

export const getOneSchool = (school_id) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestGet(`schools/${school_id ?? 0}`, false, getUserToken());
      dispatch({ type: GET_ONE_SCHOOL, value: rest.data });
    } catch (error) {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    }
    dispatch(showLoading(false));
  };
};

export const exportProfileMaster = (params) => {
  return axios({
    url: `${BASE_API_URL}v2/me/profile/master-export`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
    responseType: 'blob',
    params,
  })
    .then((response) => {
      let file_name = 'exported_data.csv';
      if (response.headers['filename']) {
        file_name = response.headers['filename'];
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
      return true;
    })
    .catch((err) => {
      throw err;
    });
};

export const exportProfile = (params, type) => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/me/profile/export`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
    responseType: 'blob',
    params,
  })
    .then((response) => {
      let file_name = 'exported_data.csv';
      if (response.headers.hasOwnProperty('content-disposition')) {
        let file_name = response.headers['content-disposition'].split('=');
        if (file_name.length > 0) {
          file_name = file_name[1];
        } else {
          file_name = 'exported_data.csv';
        }
      }
      let is_fullfname = false;
      if (response.headers.hasOwnProperty('filename')) {
        file_name = response.headers['filename'];
        is_fullfname = true;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      if (file_name) {
        link.setAttribute(
          'download',
          file_name === 'exported_data.csv' || is_fullfname ? file_name : String(file_name).slice(1, -1)
        );
      } else {
        link.setAttribute('download', `Profile ${type === 'teacher' ? 'Guru' : 'Siswa'}.${params.file_type}`);
      }
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw err;
    });
};

export const getProfile = () => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/me/profile`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
  })
    .then((response) => {
      return response.data?.data;
    })
    .catch((err) => {
      return null;
    });

  return api;
};

export const updateProfileTK = (data) => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/me/profile`,
    method: 'PUT',
    headers: {
      Authorization: getUserToken(),
    },
    data,
  })
    .then((response) => {
      return response.data?.data;
    })
    .catch(() => {
      return null;
    });

  return api;
};

export const getProfileByID = (id, isGetClasses) => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/profile/${id}?isGetClasses=${isGetClasses}`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
  })
    .then((response) => {
      return response.data?.data;
    })
    .catch((err) => {
      return null;
    });

  return api;
};
export const getProfileTeacherByID = (id) => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/profile/teacher/${id}`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
  })
    .then((response) => {
      return response.data?.data;
    })
    .catch((err) => {
      return null;
    });

  return api;
};

export const updateProfileTKByID = (id, data) => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/me/profile/${id}`,
    method: 'PUT',
    headers: {
      Authorization: getUserToken(),
    },
    data,
  })
    .then((response) => {
      return response.data?.data;
    })
    .catch(() => {
      return null;
    });

  return api;
};

export const getCheckProfileCompletion = () => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/profile/complete-check`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
  })
    .then((response) => {
      if (response.data?.data.is_show_pop_up) {
        return response.data?.data.is_show_pop_up;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return false;
    });

  return api;
};

export const downloadPDFProfile = (params) => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/profile/download`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
    responseType: 'blob',
    params,
  })
    .then((response) => {
      const file_name = response.headers['content-disposition'].split('=')[1];

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', String(file_name).slice(1, -1));
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw err;
    });
};
export const downloadPDFProfileGuru = (params) => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/profile/teacher/download`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
    responseType: 'blob',
    params,
  })
    .then((response) => {
      const file_name = response.headers['content-disposition'].split('=')[1];

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', String(file_name).slice(1, -1));
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw err;
    });
};

export const getClassGrade = () => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/me/classGrade`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
  })
    .then((response) => {
      return response.data?.data;
    })
    .catch((err) => {
      return null;
    });

  return api;
};

export const getProfileWalikelas = (class_id) => async (dispatch) => {
  const api = await axios({
    url: `${BASE_API_URL}v2/profile/walikelas`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
    params: {
      class_id,
    },
  })
    .then((response) => {
      dispatch({ type: SET_WALIKELAS_DATA, payload: response.data?.data });
    })
    .catch((err) => {
      return null;
    });

  return api;
};

export const downloadBulkAddStudent = (params) => async () => {
  const api = await axios({
    url: `${BASE_API_URL}v2/join-classes-bulk-template`,
    method: 'GET',
    headers: {
      Authorization: getUserToken(),
    },
    responseType: 'blob',
    params,
  })
    .then((response) => {
      const file_name = 'template_tambah_siswa_bersamaan.xlsx';

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', String(file_name));
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw err;
    });
};

export const clearCreateClass = () => ({
  type: CREATE_CLASS,
  value: false,
});

export const clearUpdateClass = () => ({
  type: UPDATE_CLASS,
  value: false,
});

export const clearDeleteClass = () => ({
  type: DELETE_CLASS,
  value: false,
});

export const clearSetCurrentClass = () => ({
  type: SET_CURRENT_CLASS,
  value: false,
});

export const clearCreateMapel = () => ({
  type: CREATE_MAPEL,
  value: false,
});

export const clearUpdateMapel = () => ({
  type: UPDATE_MAPEL,
  value: false,
});

export const clearDeleteMapel = () => ({
  type: DELETE_MAPEL,
  value: false,
});

export const clearDeleteStudent = () => ({
  type: DELETE_STUDENT,
  value: false,
});

export const clearSetWaliKelas = () => ({
  type: SET_WALIKELAS,
  value: false,
});

export const clearCreateSyncClass = () => ({
  type: CREATE_SYNC_CLASS,
  value: false,
});

export const insertStudent = (data) => {
  return async (dispatch) => {
    dispatch(showLoading(true));
    try {
      let rest = await requestPost(`classes/user/insert`, data, getUserToken());
      // dispatch({ type: CREATE_SYNC_CLASS, value: true });
      dispatch(showLoading(false));
      return rest;
    } catch (error) {
      dispatch(showLoading(false));
      return error?.response?.data;
    }
  };
};
