import Tugas from './Tugas';
import Jadwal from './Jadwal';
import Conference from './Conference';
import Absensi from './Absensi';
import BankSoal from './BankSoal';
import Ujian from './Ujian';
import UjianGuru from './Ujian.Guru';
import UjianSiswa from './UjianSiswa';
import Dokumen from './Dokumen';
import Nilai from './Nilai';
import Forum from './Forum';
import JurnalBelajar from './JurnalBelajar';
import InteraksiBelajar from './InteraksiBelajar';

export const MediaKelas = {
  Tugas,
  Jadwal,
  Conference,
  Absensi,
  BankSoal,
  Ujian,
  UjianGuru,
  UjianSiswa,
  Dokumen,
  Nilai,
  Forum,
  JurnalBelajar,
  InteraksiBelajar,
};
