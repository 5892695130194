import { requestGet, requestPost, requestPut } from '@configs/evaluasi';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { CREATE_DRAFT_SKL, DATA_DRAFT_SKL, UPDATE_DRAFT_SKL } from '@type';

export const getListDraftSKL = (schoolId) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(
      'draftskl',
      {
        school_id: schoolId,
      },
      getUserToken()
    );
    dispatch({ type: DATA_DRAFT_SKL, value: rest.payload.list });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createDraftSKL = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('draftskl', data, getUserToken());
    dispatch({
      type: CREATE_DRAFT_SKL,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const updateDraftSKL = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPut(`draftskl/${id}`, null, data, getUserToken());
    dispatch({
      type: UPDATE_DRAFT_SKL,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const clearCreateDraftSKL = () => ({
  type: CREATE_DRAFT_SKL,
  value: false,
});

export const clearUpdateDraftSKL = () => ({
  type: UPDATE_DRAFT_SKL,
  value: false,
});
