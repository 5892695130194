import { notification } from 'antd';
import { showLoading } from '@store/actions';
import { requestGet, requestPut } from '@configs';
import { getUserToken } from '@utils';
import { GET_ROLE, SAVE_STATE_FOR_CHANGE_ROLE, SET_ROLE, STATUS_ROLE } from '@type';
import { autoSubmitSchoolAndClasses, getStudentByClass } from '../Class';

export const getRole = () => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('me/current-class-json', false, getUserToken());
    if (rest.message === 'data not found in cache') {
      dispatch({ type: STATUS_ROLE, value: false });
      autoSubmitSchoolAndClasses(dispatch).then((_res) => {
        if (_res !== false && _res !== 'no_school' && _res !== 'is_on_wizard') {
          // _res.then((r) => {
          //     window.location.href = "/dashboard";
          // });
        } else if (_res === 'no_school') {
          window.location.href = '/wizard';
        } else if (_res === 'is_on_wizard') {
          dispatch({
            type: GET_ROLE,
            value: {
              class_id: null,
              class_name: '',
              class_role: '',
              school_id: null,
              is_admin: false,
              school_name: '',
              school_role: '',
              type: '',
            },
          });
        } else {
          dispatch({
            type: GET_ROLE,
            value: {
              class_id: null,
              class_name: '',
              class_role: '',
              school_id: null,
              is_admin: false,
              school_name: '',
              school_role: '',
              type: '',
            },
          });
        }
      });
    } else {
      const py = {
        ...rest.data,
        class_id: rest.data.id,
        school_role: rest.data.school_role ?? [],
      };
      dispatch({ type: GET_ROLE, value: py });
      dispatch({ type: STATUS_ROLE, value: true });
      dispatch({
        type: SAVE_STATE_FOR_CHANGE_ROLE,
        value: rest.data,
      });
      dispatch(getStudentByClass(rest.data.id)).then((e) => {
        if (e) {
          if (e.data) {
            if (e.data.length > 0) {
              const remap = e.data.map(({ user_id, image }) => ({
                id: user_id,
                image,
              }));
              dispatch({
                type: 'SAVE_PHOTOS',
                value: remap || [],
              });
            }
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(showLoading(false));
};

export const setRole = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = requestPut('me/current-class-json', params, null, getUserToken());
    const payload = {
      ...rest.data,
      class_id: rest.data.id,
    };
    dispatch({ type: SET_ROLE, value: payload });
    dispatch(getRole());
  } catch (error) {
    notification.error({
      message: 'Mohon Maaf!',
      description: 'Ada kesalahan saat pengambila data.',
    });
  }
};
