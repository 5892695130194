import {
  CREATE_CONFERENCE,
  DELETE_CONFERENCE,
  GET_CLASS_BY_SCHOOL,
  GET_CONFERENCE,
  GET_COURSE_BY_CLASS,
  GET_SCHOOL_ME,
  UPDATE_CONFERENCE,
} from '@type';
import { requestDelete, requestGet, requestPost, requestPut } from '@configs';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { notification } from 'antd';

export const getConferenceTeacher = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('confrences', data, getUserToken());
    dispatch({ type: GET_CONFERENCE, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getConferenceSchool = () => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('me/schools', null, getUserToken());
    dispatch({ type: GET_SCHOOL_ME, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getConferenceClass = (schoolId) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(`schools/${schoolId}/classes`, { onlyme: true }, getUserToken());
    dispatch({
      type: GET_CLASS_BY_SCHOOL,
      value: rest.data,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getConferenceCourse = (classid) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(
      `classes/${classid}/courses`,
      {
        sortby: 'id',
        order: 'desc',
      },
      getUserToken()
    );
    dispatch({
      type: GET_COURSE_BY_CLASS,
      value: rest.data,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createConference = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('confrences', data, getUserToken());
    dispatch({ type: CREATE_CONFERENCE, value: rest.data.url });
  } catch (error) {
    dispatch({ type: CREATE_CONFERENCE, value: false });
    notification.error({
      message: 'Jadwal Yang Dibuat Berbenturan Dengan Jadwal Yang Lain',
    });
  }
  dispatch(showLoading(false));
};

export const updateConference = (data, id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestPut(`confrences/${id}`, null, data, getUserToken());
    dispatch({ type: UPDATE_CONFERENCE, value: true });
  } catch (error) {
    dispatch({ type: UPDATE_CONFERENCE, value: false });
  }
  dispatch(showLoading(false));
};
export const deleteConference = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestDelete(`confrences/${id}`, null, getUserToken());
    dispatch({ type: DELETE_CONFERENCE, value: true });
  } catch (error) {
    dispatch({ type: DELETE_CONFERENCE, value: false });
  }
  dispatch(showLoading(false));
};
export const clearCreateConference = () => ({
  type: CREATE_CONFERENCE,
  value: false,
});
export const clearDeleteConference = () => ({
  type: DELETE_CONFERENCE,
  value: null,
});
export const clearUpdateConference = () => ({
  type: UPDATE_CONFERENCE,
  value: null,
});
