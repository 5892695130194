export const MSG_NETWORK_FAILED = 'Jaringan tidak stabil mohon coba lagi';
export const MSG_SUCCESS_TRANSACTION = 'Permintaan telah berhasil';
export const MSG_WARNING_TRANSACTION = 'Sepertinya ada kesalahan dalam operasi sebelumnya';
export const MSG_SUCCESS_ADD = 'Data berhasil ditambahkan';
export const MSG_WARNING_ADD = 'Data gagal ditambahkan';
export const MSG_SUCCESS_EDIT = 'Data berhasil diubah';
export const MSG_WARNING_EDIT = 'Data gagal diubah';
export const RESPONSE_ERRORS = {
  'invalid request body': 'Silahkan cek semua kolom isian anda',
  record_already_exist: 'Data pengguna sudah ada, silakan menggunakan email baru',
  record_already_exist_wa: 'Data tahapan telah ada sebelumnya',
  record_exist: 'Data Partisipan Telah Ada Sebelumnya',
  'Nama sudah terdaftar': 'Nama Mata Pelajaran Telah Ada Sebelumnya',
  invalid_date: 'Tanggal Yang Anda Pilih Melebihi Periode Yang Di Tentukan',
  'Email must be a valid email address': 'Email Tidak Valid',
  date_exist: 'Pada tanggal tersebut data LPA sudah tersedia',
};
