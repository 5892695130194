/* eslint-disable no-prototype-builtins */
import { requestAnyGet, requestDelete, requestGet, requestPost, requestPut } from '@configs';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { notification } from 'antd';
import {
  CREATE_ANSWER_QUIZ_BANK_SOAL,
  CREATE_QUIZ_BANK_SOAL,
  DELETE_QUIZ,
  GET_COURSE_BANK_SOAL,
  GET_EXAM_DETAIL,
  GET_EXAM_DETAIL_AND_QUESTION,
  GET_LIST_BANK_SOAL,
  GET_LIST_EXAM,
  GET_LIST_MASTER_SOAL,
  GET_QUESTION_BY_EXAM_ID,
  GET_QUESTION_BY_EXAM_ID_NEW,
  GET_QUIZ_DETAIL,
  GET_QUIZ_JAWABAN,
  GET_QUIZ_PERSONAL,
  GET_QUIZ_SOAL,
  GET_SOAL_BANK_SOAL,
  GET_TYPE_LIST,
  IS_SUCCESS,
  UPDATE_QUIZ,
} from '@type';
import axios from 'axios';
import { BASE_EXAM, BASE_EXAM_QUIZ } from '../../../../constants/initHttp';
import httpService from '../../../../configs/service';
import { getQuestionUjianByGuru } from '../Ujian.Guru';
import { normalizeDataQuestion } from '../../../selector/MediaKelas/UjianGuru';
import { reportSentry } from '../../../../utils/Utils';
import { RESPONSE_ERRORS } from '../../../../constants/initError';

export const getQuizPersonal = (classId) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('me/quiz', { quiz_type: 'SEKOLAH', class_id: classId }, getUserToken());

    dispatch({ type: GET_QUIZ_PERSONAL, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getListBankSoal = (keywords) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestAnyGet('https://api.siswamedia.com/g2j/', {
      id: '1VTedfoSXmis0lOoubDAA0kGG2pSNtT73fB9CGjQsQaU',
      sheet: 2,
      columns: false,
      q: keywords,
    });
    dispatch({ type: GET_LIST_BANK_SOAL, value: rest.rows });
  } catch (error) {
    notification.error({
      message: 'Mohon Maaf!',
      description: 'Silahkan Hubungi Customer Service Kami google sheet',
    });
  }
  dispatch(showLoading(false));
};

export const getListMasterSoal = (keywords) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestAnyGet('https://api.siswamedia.com/g2j/', {
      id: '1GU89LkfJXwiVDSVcMk7tZ4-VexIQIVISQknxfDS_FNY',
      sheet: 7,
      columns: false,
      q: keywords,
    });

    dispatch({ type: GET_LIST_MASTER_SOAL, value: rest.rows[0] });
  } catch (error) {
    notification.error({
      message: 'Mohon Maaf!',
      description: 'Silahkan Hubungi Customer Service Kami google sheet',
    });
  }
  dispatch(showLoading(false));
};

export const createQuizBankSoal = (body, key, total) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('quiz', body, getUserToken());

    dispatch({
      type: CREATE_QUIZ_BANK_SOAL,
      value: { id: rest.data.id, key: key + 1, total },
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami.',
    // });
  }
  dispatch(showLoading(false));
};

export const createAnswerQuizBankSoal = (objQuiz, body) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestPost(`quiz/${objQuiz.id}/bank-soal`, { kumpulan_soal: body }, getUserToken());

    if (objQuiz.key === objQuiz.total) {
      dispatch({ type: CREATE_ANSWER_QUIZ_BANK_SOAL, value: true });
    }
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami.',
    // });
  }
  dispatch(showLoading(false));
};

export const getSoalBankSoal = (linkSoal) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestAnyGet(linkSoal);

    dispatch({ type: GET_SOAL_BANK_SOAL, value: rest.rows });
  } catch (error) {
    notification.error({
      message: 'Mohon Maaf!',
      description: 'Silahkan Hubungi Customer Service Kami google sheet',
    });
  }
  dispatch(showLoading(false));
};

export const getCourseBankSoal = (classId, is_teacher = true) => {
  const api = async (dispatch) => {
    dispatch(showLoading(true));
    try {
      const rest = await requestGet(
        `classes/${classId}/courses`,
        {
          sortby: 'id',
          order: 'desc',
          limit: 10,
          page: 1,
          isteacher: is_teacher ?? false,
        },
        getUserToken()
      );

      dispatch({ type: GET_COURSE_BANK_SOAL, value: rest.data });
    } catch (error) {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    }
    dispatch(showLoading(false));
  };
  return api;
};

export const updateQuiz = (quizId, body) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestPut(`quiz/${quizId}`, null, body, getUserToken());

    dispatch({ type: UPDATE_QUIZ, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const deleteQuiz = (quizId) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestDelete(`quiz/${quizId}`, null, getUserToken());

    dispatch({ type: DELETE_QUIZ, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

// Ujian Student
export const getQuizDetail = (quizId) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(`quiz/${quizId}`, null, getUserToken());

    dispatch({ type: GET_QUIZ_DETAIL, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getQuizSoal = (quizId) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(
      `quiz/${quizId}/bank-soal`,
      {
        order: 'asc',
      },
      getUserToken()
    );
    dispatch({ type: GET_QUIZ_SOAL, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getQuizJawaban = (quizId, userId) => (dispatch) => {
  dispatch(showLoading(true));
  return requestGet(`quiz/${quizId}/review-jawaban?user_id=${userId}`, null, getUserToken()).then(
    (res) => {
      dispatch({ type: GET_QUIZ_JAWABAN, value: res.data });
      dispatch(showLoading(false));
      return res.data;
    },
    (error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      throw error.response?.data || 'no_message';
    }
  );
};

export const submitQuiz = (data) => (dispatch) => {
  dispatch(showLoading(true));
  return requestPost('bank-soal/myanswer', data, getUserToken()).then(
    () => {
      notification.success({
        message: 'Sukses!',
        description: 'Berhasil mengumpulkan jawaban ujian',
      });
      dispatch(showLoading(false));
      return true;
    },
    () => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
    }
  );
};

export const submitPenilaian = (data) => (dispatch) => {
  dispatch(showLoading(true));
  return requestPost('bank-soal/answer/score', data, getUserToken()).then(
    () => {
      notification.success({
        message: 'Sukses!',
        description: 'Berhasil membuat penilaian ujian',
      });
      dispatch(showLoading(false));
      return true;
    },
    (error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      throw error.response?.data || 'no_message';
    }
  );
};

export const createQuizExcel = (dataQuiz, dataSoal) => async (dispatch) => {
  // dispatch(showLoading(true));
  const api = await requestPost('quiz', dataQuiz, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch(addQuizTemplate(response.data.id, dataSoal));
      }
    })
    .catch((error) => {
      const msgBackend = error.response.data.message;

      notification.error({
        message: 'Mohon Maaf!',
        description: RESPONSE_ERRORS.hasOwnProperty(msgBackend) ? RESPONSE_ERRORS[msgBackend] : 'Ada Kesalahan',
      });
      reportSentry(error);
    });
  return api;
};

export const addQuizTemplate = (id, dataSoal) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost(`quiz/${id}/bank-soal-template`, dataSoal, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Add Quiz Success',
        });
        dispatch(showLoading(false));
      } else {
        dispatch({
          type: IS_SUCCESS,
          value: false,
          message: 'Add Quiz Failed',
        });
        dispatch(showLoading(false));
      }
    })
    .catch((error) => {
      const msgBackend = error.response.data.message;

      notification.error({
        message: 'Mohon Maaf!',
        description: msgBackend || 'Ada Kesalahan',
      });
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Add Quiz Failed',
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

// for ujian refactor
export const getTypeList = (page, limit) => async (dispatch) => {
  const api = await axios
    .get(`${BASE_EXAM}examtype?page=${page}&limit=${limit}&sort=id&direction=ASC`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({ type: GET_TYPE_LIST, value: response.data.payload });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
export const getListExam = (page, limit, class_id, status) => async (dispatch) => {
  const api = await axios
    .get(`${BASE_EXAM}exam?page=${page}&limit=${limit}&sort=id&direction=DESC&class_id=${class_id}&status=${status}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({
        type: GET_LIST_EXAM,
        value: response.data?.payload?.list,
      });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const addUjian = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .post(`${BASE_EXAM}exam`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch(showLoading(false));
      if (response.data.statusCode === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));

      /* ------------------------- minute duration getter ------------------------- */
      const date1 = new Date(data?.start_date);
      const date2 = new Date(data?.end_date);
      const diffInMs = Math.abs(date2 - date1);
      const convertMinutes = diffInMs / (1000 * 60);

      if (convertMinutes < data?.duration) {
        /* ------- message show if duration greater than end_date - start date ------ */
        notification.error({
          message: 'Mohon Maaf!',
          description: 'Durasi yang anda berikan melebihi waktu akhir',
        });
      } else {
        /* ----------------------------- general message ---------------------------- */
        notification.error({
          message: 'Mohon Maaf!',
          description: 'Ada Kesalahan',
        });
      }
      reportSentry(error);
    });

  return api;
};

export const getExamDetail = (id) => async (dispatch) => {
  const api = await axios
    .get(`${BASE_EXAM}exam/${id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({ type: GET_EXAM_DETAIL, value: response.data?.payload });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
export const getQuestionByExamID = (page, id) => async (dispatch) => {
  const api = await axios
    .get(`${BASE_EXAM_QUIZ}question?exam_id=${id}&page=${page}&item_per_page=10`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({
        type: GET_QUESTION_BY_EXAM_ID,
        value: response.data?.data,
      });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const getQuestionUjianByGuruNew = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('question', { baseURL: BASE_EXAM_QUIZ, params })
    .then((response) => {
      dispatch({
        type: GET_QUESTION_BY_EXAM_ID_NEW,
        value: response.data?.data,
      });
    })
    .catch(() => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const editUjian = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .put(`${BASE_EXAM}exam/${id}`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch(showLoading(false));
      if (response.data.statusCode === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));

      notification.error({
        message: 'Mohon Maaf!',
        description: 'Ada Kesalahan',
      });
      reportSentry(error);
      return false;
    });

  return api;
};
export const editCourses = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .put(`${BASE_EXAM}course/update/${id}`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch(showLoading(false));
      if (response.data.statusCode === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));

      notification.error({
        message: 'Mohon Maaf!',
        description: 'Ada Kesalahan',
      });
      throw error;
    });

  return api;
};
export const deleteUjian = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .delete(`${BASE_EXAM}exam/${id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch(showLoading(false));
      if (response.data.statusCode === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));

      notification.error({
        message: 'Mohon Maaf!',
        description: 'Ada Kesalahan',
      });
      reportSentry(error);
    });

  return api;
};

export const getExamDetailandQuestion = (id, exam_id) => async (dispatch) => {
  const api = await axios
    .get(`${BASE_EXAM}exam/${id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then(async (response) => {
      let tempRes = response.data ? response.data.payload : {};

      const tempCourses = [];
      tempRes.courses &&
        tempRes.courses.forEach((val) => {
          if (!tempCourses.includes(val.course_id)) {
            tempCourses.push(val.course_id);
          }
        });
      const tempQuestion = await dispatch(getQuestionUjianByGuru({ exam_id }));
      tempRes = {
        ...tempRes,
        questions: normalizeDataQuestion(tempQuestion || []),
        courses: tempCourses || [],
      };

      dispatch({ type: GET_EXAM_DETAIL_AND_QUESTION, value: tempRes });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
export const getSchoolYearExam = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('schoolyear', { baseURL: BASE_EXAM, params })
    .then((response) => (response.data.payload ? response.data.payload.list : []))
    .catch((err) => {
      console.log('err', err);
      return null;
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const exportPDF = (params, filename = 'file') => {
  const api = async (dispatch) => {
    dispatch(showLoading(true));
    return httpService
      .get('export/exam/pdf', {
        baseURL: `${BASE_EXAM}`,
        params,
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        // notification.error({
        //   message: 'Mohon Maaf!',
        //   description: 'Silahkan Hubungi Customer Service Kami',
        // });
      })
      .finally(() => {
        dispatch(showLoading(false));
      });
  };
  return api;
};

export const clearCourseBankSoal = () => ({
  type: GET_COURSE_BANK_SOAL,
  value: false,
});

export const clearQuizPersonal = () => ({
  type: GET_QUIZ_PERSONAL,
  value: false,
});

export const clearListBankSoal = () => ({
  type: GET_LIST_BANK_SOAL,
  value: false,
});

export const clearUpdateQuiz = () => ({
  type: UPDATE_QUIZ,
  value: false,
});

export const clearDeleteQuiz = () => ({
  type: DELETE_QUIZ,
  value: false,
});

export const clearAnswerCreateQuiz = () => ({
  type: CREATE_ANSWER_QUIZ_BANK_SOAL,
  value: false,
});
