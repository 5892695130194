import { KARAKTER_UTAMA_CREATE, KARAKTER_UTAMA_DELETE, KARAKTER_UTAMA_UPDATE } from '@type';

const initState = {
  list: false,
  create: false,
  update: false,
  delete: false,
};

const KarakterUtama = (state = initState, action) => {
  switch (action.type) {
    case KARAKTER_UTAMA_CREATE:
      return {
        ...state,
        create: action.value,
      };

    case KARAKTER_UTAMA_UPDATE:
      return {
        ...state,
        update: action.value,
      };

    case KARAKTER_UTAMA_DELETE:
      return {
        ...state,
        delete: action.value,
      };

    default:
      return state;
  }
};

export default KarakterUtama;
