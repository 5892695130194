import { showLoading } from '../../..';
import { BASE_REKAP_NILAI } from '../../../../../constants/initHttp';
import { AxiosInject, getUserToken } from '../../../../../utils/Utils';

export const addBulkScoreQualitative = (data) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .post('v1/api/reportQualitativeScore/bulk', data)
      .then((r) => {
        dispatch(showLoading(false));
        if (r.status === 200) {
          return true;
        }
        // return r.data
      })
      .catch(() => {
        dispatch(showLoading(false));
        // return r.data
      });
  };
};

export const getAllReportQualitative = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/reportQualitativeScore', {
        params,
      })
      .then((r) => {
        dispatch(showLoading(false));
        // console.log(r);
        return r.data;
      })
      .catch(() => {
        dispatch(showLoading(false));
        // return r.data
      });
  };
};
export const getSelectAllReportQualitativeScores = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/reportQualitativeScoreDetail/select', {
        params,
      })
      .then((r) => {
        dispatch(showLoading(false));
        // console.log(r);
        return r.data;
      })
      .catch(() => {
        dispatch(showLoading(false));
        // return r.data
      });
  };
};

export const getReportQualitativeScoresByID = (id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get(`v1/api/reportQualitativeScoreDetail/id/${id}`)
      .then((r) => {
        dispatch(showLoading(false));
        // console.log(r);
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};
export const deleteReportQualitativeScoresByID = (id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .delete(`v1/api/reportQualitativeScore/id/${id}`)
      .then((r) => {
        dispatch(showLoading(false));
        // console.log(r);
        if (r.status === 200) {
          return true;
        }
      })
      .catch(() => {
        dispatch(showLoading(false));
      });
  };
};

export const getReportQualitativeById = (id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get(`v1/api/reportQualitativeScore/id/${id}`)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch(() => {
        dispatch(showLoading(false));
        // return r.data
      });
  };
};
export const createReportQualitativeScoreDetail = (data) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .post('v1/api/reportQualitativeScoreDetail', data)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};
export const deleteReportQualitativeScoreDetail = (id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .delete(`v1/api/reportQualitativeScoreDetail/id/${id}`)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};

export const updateReportQualitativeScoreDetail = (data, id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .put(`v1/api/reportQualitativeScoreDetail/id/${id}`, data)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};

export const getReportQualitativeScoreDetailSelectAll = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async () =>
    await httpInjector
      .get('v1/api/reportQualitativeScoreDetail/select', { params })
      .then(
        (r) =>
          // dispatch(showLoading(false));
          r.data
      )
      .catch(
        (r) =>
          // dispatch(showLoading(false));
          r.response?.data
      );
};

export const createReportQualitativeValue = (data) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) =>
    // dispatch(showLoading(true));
    await httpInjector
      .post('v1/api/reportQualitativeSectionValue', data)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
};
export const updateReportQualitativeValue = (data, id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .put(`v1/api/reportQualitativeSectionValue/id/${id}`, data)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};
export const getReportQualitativeValueSelectAll = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/reportQualitativeSectionValue/select', { params })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};

export const createReportQualitativeOtherNote = (data) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .post('v1/api/reportQualitativeScoreNote', data)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};

export const updateReportQualitativeOtherNote = (data, id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .put(`v1/api/reportQualitativeScoreNote/id/${id}`, data)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};

export const getReportQualitativeOtherNoteSelectAll = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/reportQualitativeScoreNote/select', { params })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};

export const createReportQualitativeScoreSummary = (data) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .post('v1/api/reportQualitativeScoreSummary', data)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};

export const getReportQualitativeScoreSummarySelectAll = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/reportQualitativeScoreSummary/select', { params })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};
export const updateReportQualitativeScoreSummary = (data, id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .put(`v1/api/reportQualitativeScoreSummary/id/${id}`, data)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        return r.response?.data;
      });
  };
};
