import { CREATE_DRAFT_SKL, DATA_DRAFT_SKL, UPDATE_DRAFT_SKL } from '@type';

const initState = {
  data: false,
  create: false,
  update: false,
};

const DraftSKL = (state = initState, action) => {
  switch (action.type) {
    case DATA_DRAFT_SKL:
      return {
        ...state,
        data: action.value,
      };

    case CREATE_DRAFT_SKL:
      return {
        ...state,
        create: action.value,
      };

    case UPDATE_DRAFT_SKL:
      return {
        ...state,
        update: action.value,
      };

    default:
      return state;
  }
};

export default DraftSKL;
