import { GET_ROLE, SET_ROLE, STATUS_ROLE, SET_WALIKELAS_DATA } from '@type';

const initState = {
  statusRole: false,
  value: {
    school_id: 0,
    school_name: '',
    type: '',
    id: 0,
    is_admin: false,
    class_id: 0,
    class_name: '',
    class_role: '',
    school_role: [],
  },
  studentPhotos: [],
  walikelas: null,
};

const Role = (state = initState, action) => {
  switch (action.type) {
    case GET_ROLE:
      return {
        ...state,
        value: action.value,
      };

    case SET_ROLE:
      return {
        ...state,
        value: action.value,
      };

    case STATUS_ROLE:
      return {
        ...state,
        statusRole: action.value,
      };

    case 'SAVE_PHOTOS':
      return {
        ...state,
        studentPhotos: action.value,
      };
    case SET_WALIKELAS_DATA:
      return {
        ...state,
        walikelas: action.payload,
      };
    default:
      return state;
  }
};

export default Role;
