/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import Icon from '@ant-design/icons';
import { ReactSVG } from 'react-svg';

const IconCreate = ({
  svg,
  color,
  width = 16,
  height = 16,
  style = { marginBottom: 2, marginRight: 5 },
  injection = false,
}) => {
  const MyIcon = () => (
    <ReactSVG
      style={{
        width,
        height,
        backgroundColor: 'transparent',
        ...style,
      }}
      beforeInjection={(svg) => {
        !injection
          ? () => {
              svg.classList.add('svg-class-name');
              const el = [...svg.querySelectorAll('path')];
              const gEl = [...svg.querySelectorAll('line')];
              el.forEach((e) => {
                e.setAttribute('fill', color ?? 'white');
              });
              gEl.forEach((e) => {
                e.setAttribute('stroke', color ?? 'white');
              });
              svg.setAttribute('width', width);
              svg.setAttribute('height', height);
            }
          : injection(svg);
      }}
      src={svg}
    />
  );
  // const MyIcon = ()=>(
  //     <img
  //         style={
  //             {
  //                 width:32,
  //                 height:32,
  //                 marginBottom:7,
  //                 marginRight:5,
  //             }
  //         }
  //         src={svg}
  //     />
  //
  // )

  return (
    <Icon
      component={MyIcon}
      style={{
        backgroundColor: 'transparent !important',
      }}
    />
  );
};

export default IconCreate;
