import { requestDelete, requestGet, requestPost, requestPut } from '@configs/evaluasi';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { CREATE_JP, CREATE_TEMA, DELETE_TEMA, LIST_TEMA, UPDATE_TEMA } from '@type';

export const getListTema = (sort, direction) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(
      'theme',
      {
        sort,
        direction,
      },
      getUserToken()
    );

    dispatch({ type: LIST_TEMA, value: rest.payload.list });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createTema = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('theme', data, getUserToken());
    dispatch({ type: CREATE_TEMA, value: rest.payload.id });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createJP = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestPost('jp', data, getUserToken());
    dispatch({ type: CREATE_JP, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const deleteTema = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestDelete(`theme/${id}`, null, getUserToken());
    dispatch({ type: DELETE_TEMA, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const updateTema = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestPut(`theme/${id}`, null, data, getUserToken());

    dispatch({ type: UPDATE_TEMA, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const clearCreateTema = () => ({
  type: CREATE_TEMA,
  value: false,
});

export const clearCreateJP = () => ({
  type: CREATE_JP,
  value: false,
});

export const clearUpdateTema = () => ({
  type: UPDATE_TEMA,
  value: false,
});

export const clearDeleteTema = () => ({
  type: DELETE_TEMA,
  value: false,
});
