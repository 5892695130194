import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import school from './assets/translator/school.json';
import bimbel from './assets/translator/bimbel.json';
import course from './assets/translator/course.json';
import collage from './assets/translator/collage.json';
import company from './assets/translator/company.json';
import madrasah from './assets/translator/madrasah.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'school', // default language
    debug: process.env.DEBUG === 'true' ?? true,
    // debug: true,
    resources: {
      school: {
        translation: school,
      },
      collage: {
        translation: collage,
      },
      company: {
        translation: company,
      },
      madrasah: {
        translation: madrasah,
      },
      wakaf: {
        translation: school,
      },
      course: {
        translation: course,
      },
      bimbel: {
        translation: bimbel,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
