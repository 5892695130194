import { requestDelete, requestGet, requestPost } from '@configs/quiz';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { GET_ALL_BANK_SOAL, GET_BANK_SOAL, GET_ONE_BANK_SOAL } from '@type';
import { CLEAR_BANK_SOAL, CREATE_BANK_SOAL, DELETE_BANK_SOAL } from '../../../../constants/initType';
import { requestPatch } from '../../../../configs/quiz';
import { AxiosInject } from '../../../../utils/Utils';
import { BASE_API_URL, BASE_QUIZ } from '../../../../constants/initHttp';

export const getAllQuiz = () => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('quiz', null, getUserToken());
    dispatch({ type: GET_ALL_BANK_SOAL, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getQuizByParam = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('quiz', params, getUserToken());
    dispatch({
      type: GET_BANK_SOAL,
      value: rest.data,
      meta: rest.meta,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createBankSoal = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('quiz', data, getUserToken());

    // dispatch({
    //     type: CREATE_BANK_SOAL,
    //     value: rest.code === 200 ? true : false,
    // });
    dispatch(showLoading(false));
    return rest.data;
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const duplicateBankSoal = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  const httpInjector = AxiosInject(BASE_QUIZ, 'Bearer', getUserToken());
  return await httpInjector
    .get(`quiz/duplicate/${id}`)
    .then((r) => r.data)
    .catch((r) => {
      throw new Error(r);
    })
    .finally(() => dispatch(showLoading(false)));
};

export const createQuestion = (data, type) => async (dispatch) => {
  if (type === 'finish') {
    dispatch(showLoading(true));
  }
  try {
    const rest = await requestPost('question', data, getUserToken());

    if (type === 'finish') {
      dispatch({
        type: CREATE_BANK_SOAL,
        value: rest.code === 200,
      });
    }
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};
export const updateBankSoal = (data, type) => async (dispatch) => {
  if (type === 'finish') {
    dispatch(showLoading(true));
  }
  try {
    const rest = await requestPatch('quiz', data, getUserToken());
    if (type === 'finish') {
      dispatch({
        type: CREATE_BANK_SOAL,
        value: rest.code === 200,
      });
    }
    dispatch({ type: 'PATCH_BANK_SOAL', value: rest?.data?.questions });
    return rest?.data?.questions;
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const deleteBankSoal = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestDelete(`quiz/${id}`, null, getUserToken());
    dispatch({
      type: DELETE_BANK_SOAL,
      value: rest.code === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};
export const deleteQuestion = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestDelete(`question/${id}`, null, getUserToken());
    // dispatch({
    //     type: DELETE_BANK_SOAL,
    //     value: rest.code === 200 ? true : false,
    // });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

// Proses lampiran di bank soal
export const uploadFileBankSoal = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('quiz/file/upload', data, getUserToken());
    dispatch(showLoading(false));
    return rest;
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getQuizByID = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(`quiz/${id}`, null, getUserToken());
    dispatch({ type: GET_ONE_BANK_SOAL, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getAllSchool = () => {
  const httpInjector = AxiosInject(BASE_API_URL, '', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('/v2/schools')
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        throw new Error(r);
      });
  };
};

export const shareQuiz = (payload) => {
  const httpInjector = AxiosInject(BASE_QUIZ, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .post('quiz/share', payload)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        throw new Error(r);
      });
  };
};

export const getQuestionHistory = (params) => {
  const httpInjector = AxiosInject(BASE_QUIZ, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('question/history', { params })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        throw new Error(r);
      });
  };
};

// clear action reducers
export const clearCreateBankSoal = () => ({
  type: CREATE_BANK_SOAL,
  value: false,
});

export const clearBankSoal = () => ({
  type: CLEAR_BANK_SOAL,
});
