export const showError = (key, type) => {
  const listError = {
    INVALID_REQUEST_PAYLOAD: 'Silahkan cek semua kolom isian anda',
    CANNOT_FIND_STUDENT_SCORE_LIST: 'Nilai siswa tidak ditemukan',
    CANNOT_SUBMIT_STUDENT_SCORE: 'Nilai siswa tidak terkirim',
    ROLE_CANNOT_EMPTY: 'Role tidak boleh kosong',
    FAILED_GET_LIST_SCORE_SUBMISSION: 'Tidak bisa mendapatkan pengajuan nilai siswa',
    FAILED_GET_LIST_SUBMITTED_STUDENT_SCORE: 'Tidak bisa mendapatkan nilai siswa yang diajukan',
    FAILED_GET_SUBMITTED_ASSSESSMENT_AND_EXAM_LIST: 'Tidak bisa mendapatkan nilai tugas dan ujian yang diajukan',
    FAILED_FIND_SCORE_CONCLUSION_DETAIL_BY_COURSE_ID: 'Tidak bisa mendapatkan nilai siswa',
    FAILED_FIND_TASK_DETAIL_LIST_BY_USER_ID: 'Tidak bisa mendapatkan detail nilai siswa',
    FAILED_UPDATE_SCORE_SUBMISSION_STATUS:
      type === 'pengajuan'
        ? 'Mohon periksa data Nilai yang diajukan , ada siswa yang belum sama total tugas dan ujian yang diajukan atau pastikan siswa sudah mengumpulkan nilai tugas dan ujian !!'
        : 'Tidak bisa merubah status pengajuan',
    INVALID_UPDATE_SCORE_SUBMISSION_STATUS: 'Perubahan nilai tidak benar, harap cek kembali',
    REJECT_REASON_CANNOT_EMPTY: 'Alasan ditolak tidak boleh kosong',
    INVALID_SCORE: 'Range nilai yang dimasukkan sudah tersedia!',
    RECORD_ALREADY_EXIST: 'Data yang dimasukkan sudah tersedia, mohon ubah dengan data yang lain!',
    FAILED_UPDATE_SCORE_SUBMISSION_STATUS_REPORT_TEMPLATE_NOT_FOUND:
      'Tidak bisa merubah status pengajuan karena template raport belum dibuat',
  };
  return listError[key] || 'Silahkan Hubungi Customer Service Kami';
};
