import { getUserToken } from '@utils';
import { GET_DOCUMENTS, GET_DOCUMENTS_SCHOOL, GET_FILES, GET_FILES_SCHOOL } from '@type';
import axios from 'axios';
import { notification } from 'antd';
import { reportSentry } from '../../../../utils/Utils';
import { BASE_API_URL, BASE_LOGIN } from '../../../../constants/initHttp';
import { showLoading } from '../../General';

export const getDocuments = (parent) => async (dispatch) => {
  if (parent === '') {
    return;
  }
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/schoolGoogleDrive?directory_id=${parent}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({ type: GET_DOCUMENTS, value: response.data.data.files });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
export const getFiles = (params, type) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/schoolGoogleDrive`, {
      params,
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({
        type: type === 'class' ? GET_FILES : GET_FILES_SCHOOL,
        value: response.data.data,
      });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
    });
  return api;
};

export const addFolder = (path, name, userMail = false) => async (dispatch) => {
  dispatch(showLoading(true));
  let permission = [
    {
      type: 'anyone',
      role: 'reader',
      send_notification: false,
    },
  ];
  const owned = [
    {
      type: 'user',
      role: 'reader',
      email: userMail,
      send_notification: false,
    },
    {
      type: 'domain',
      role: 'reader',
      domain: 'siswamedia.com',
      send_notification: false,
    },
    {
      type: 'user',
      role: 'writer',
      email: userMail,
      send_notification: false,
    },
  ];
  if (userMail) {
    permission = permission.concat(owned);
  }
  const fileMetadata = {
    path,
    name,
    permissions: permission,
  };
  const api = await axios
    .post(`${BASE_LOGIN}/v1/api/schoolGoogleDrive/directory`, fileMetadata, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(showLoading(false));
        return true;
      }
    })
    .catch((error) => {
      const msg = () => {
        if (error.response.status === 422) return 'Tidak punya izin! Harap hubungi admin!';
      };
      notification.error({
        message: 'Gagal!',
        description: msg(),
      });
    });
  dispatch(showLoading(false));
  return api;
};
export const addFile = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .post(`${BASE_LOGIN}/v1/api/schoolGoogleDrive/file`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(showLoading(false));
        dispatch(addPermission(response.data.data.id));
        return true;
      }
    })
    .catch(() => {
      notification.error({
        message: 'Maaf Anda Tidak Di Izinkan Untuk Menambahkan File',
      });
    });
  dispatch(showLoading(false));
  return api;
};
export const addPermission = (path, email) => async (dispatch) => {
  dispatch(showLoading(true));
  const data = [];
  if (email) {
    email.forEach((item) => {
      data.push(
        {
          type: 'anyone',
          role: 'reader',
          send_notification: false,
        },
        {
          type: 'user',
          role: 'reader',
          email: item,
          send_notification: false,
        },
        {
          type: 'domain',
          role: 'reader',
          domain: 'siswamedia.com',
          send_notification: false,
        },
        {
          type: 'user',
          role: 'writer',
          email: item,
          send_notification: false,
        }
      );
    });
  } else {
    data.push({
      type: 'anyone',
      role: 'reader',
      send_notification: false,
    });
  }
  const api = await axios
    .put(`${BASE_LOGIN}/v1/api/schoolGoogleDrive/permission/id/${path}`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(showLoading(false));
        return true;
      }
    })
    .catch(() => {
      notification.error({
        message: 'Anda Tidak Berhak Untuk Memberikan Izin',
      });
    });
  dispatch(showLoading(false));
  return api;
};

export const getDocumentsSchool = (parent) => async (dispatch) => {
  if (parent === '') {
    return;
  }
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/schoolGoogleDrive?directory_id=${parent}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({
        type: GET_DOCUMENTS_SCHOOL,
        value: response.data.data.files,
      });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
export const getFilesSchool = (parent) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/schoolGoogleDrive?directory_id=${parent}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({
        type: GET_FILES_SCHOOL,
        value: response.data.data.files,
      });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
    });
  return api;
};
export const deleteFile = (parent) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .delete(`${BASE_LOGIN}/v1/api/schoolGoogleDrive/file/id/${parent}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(showLoading(false));
        return true;
      }
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
      const msg = () => {
        if (error.response.status === 422) {
          return 'Tidak punya izin! Harap hubungi admin!';
        }
      };
      notification.error({
        message: 'Gagal!',
        description: msg(),
      });
    });
  return api;
};

export const addFolderIfEmpty = (class_id, type) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .put(
      `${BASE_API_URL}v2/${type === 'class' ? 'classes' : 'schools'}/drive/${class_id}`,
      {},
      {
        headers: { Authorization: `${getUserToken()}` },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch(showLoading(false));
        return true;
      }
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
    });
  return api;
};

export const getAboutGdrive = () => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/schoolGoogleDrive/about`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({
        type: 'GET_ABOUT_GDRIVE',
        value: response.data.data,
      });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
    });
  return api;
};
