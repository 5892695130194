import {
  ADD_FILE_GDRIVE,
  GOOGLE_URL,
  IS_SUCCESS,
  IS_SUSPENDED,
  IS_UPLOAD_SUCCESS,
  SCHOOL_YEAR,
  SET_LOADING,
  WHITELABEL_CONFIG,
} from '@type';

const initState = {
  loading: false,
  isSuccess: null,
  isUploadSuccess: null,
  message: '',
  school_year: false,
  networkError: null,
  suspended: null,
  whitelabel: null,
  googleUrl: null,
  drive: null,
  bySiswa: false,
  typeFilter: 'assessment',
  tab_quiz_detail: '1',
  whitelabel_data: {
    kelasku_banner: '',
    login_banner: [],
    // Dashboard
    dashboard_absensi_empty_state: '',
    dashboard_schedule_empty_state: '',
    dashboard_class_empty_state: '',
    dashboard_tugas_empty_state: '',
    dashboard_rekap_empty_state: '',
    // Kelasku
    kelasku_siswa_empty_state: '',
    kelasku_matpel_empty_state: '',
    kelasku_guru_empty_state: '',
    // Dokumen
    dokumen_empty_state: '',
    // Tugas
    tugas_empty_state: '',
    konferensi_empty_state: '',
    // Ujian Pro
    ujian_pro_empty_state: '',
    // Bank Soal
    bank_soal_empty_state: '',
    // Absensi
    absensi_empty_state: '',
    // Jadwal
    jadwal_empty_state: '',
    // Penjadwalan
    penjadwalan_empty_state: '',
    // Rekap Nilai
    rekap_nilai_empty_state: '',
    // Jurna Belajar
    jurnal_belajar_empty_state: '',
    // Forum
    forum_empty_state: '',
    site_name: '',
    site_type: '',
    wizard_step_two: undefined,
    wizard_step_one: undefined,
    wizard_step_three: undefined,
    hidden_menus: [],
  },
  routeChanged: false,
  dictionaries: null,
  redirected: false,
};

const General = (state = initState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case 'BY_SISWA':
      return {
        ...state,
        bySiswa: action.value,
      };
    case 'ROUTE_MOVE':
      return {
        ...state,
        routeChanged: !state.routeChanged,
      };
    case 'TYPE_FILTER':
      return {
        ...state,
        typeFilter: action.value,
      };
    case 'ADD_DICTIONARY':
      return {
        ...state,
        dictionaries: action.value,
      };
    case 'SET_TAB_QUIZ_DETAIL':
      return {
        ...state,
        tab_quiz_detail: action.value,
      };
    case IS_SUCCESS:
      return {
        ...state,
        isSuccess: action.value,
        message: action.message,
      };
    case IS_SUSPENDED:
      return {
        ...state,
        suspended: action.value === false,
        whitelabel: action.value !== false || action.value !== null ? action.value : null,
      };
    case ADD_FILE_GDRIVE:
      return {
        ...state,
        drive: action.value,
      };
    case GOOGLE_URL:
      return {
        ...state,
        googleUrl: action.value,
      };
    case IS_UPLOAD_SUCCESS:
      return {
        ...state,
        isUploadSuccess: action.value,
        networkError: action.value,
        message: action.message,
      };

    case SCHOOL_YEAR:
      return {
        ...state,
        school_year: action.value,
      };

    case 'REDIRECT_TO_CLASS':
      return {
        ...state,
        redirected: action.value,
      };

    case WHITELABEL_CONFIG:
      return {
        ...state,
        whitelabel_data: {
          ...state.whitelabel_data,
          ...action.value,
        },
      };

    default:
      return state;
  }
};

export default General;
