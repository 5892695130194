import { CREATE_BANK_SOAL, GET_ALL_BANK_SOAL, GET_BANK_SOAL, GET_ONE_BANK_SOAL } from '@type';
import { CLEAR_BANK_SOAL } from '../../../../constants/initType';

const initialState = {
  list: {
    data: [],
    meta: {
      item_per_page: 10,
      number_of_page: 0,
      page: 1,
    },
  },
  createBankSoal: false,
  bankSoalDetail: {},
};

const BankSoal = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BANK_SOAL:
      return {
        ...state,
        listBankSoal: action.value,
      };

    case GET_BANK_SOAL:
      return {
        ...state,
        list: {
          data: action.value,
          meta: action.meta,
        },
      };

    case CREATE_BANK_SOAL:
      return {
        ...state,
        createBankSoal: action.value,
      };
    case GET_ONE_BANK_SOAL:
      return {
        ...state,
        bankSoalDetail: action.value,
      };
    case CLEAR_BANK_SOAL:
      return {
        ...state,
        bankSoalDetail: {},
        createBankSoal: false,
      };
    default:
      return state;
  }
};
export default BankSoal;
