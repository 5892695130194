import { FileExcelFilled } from '@ant-design/icons';
import { Space } from 'antd';

const MultiAttachment = ({ attachments }) => (
  <>
    {attachments && (
      <Space direction="vertical">
        {attachments.map((row, i) => (
          <Space key={Number(i)}>
            <a
              href={row.url}
              target="_blank"
              download={`Attachment soal nomor ${i + 1}`}
              rel="noreferrer"
              style={{ marginLeft: '8px' }}
            >
              <FileExcelFilled style={{ fontSize: '24px' }} />
            </a>
          </Space>
        ))}
      </Space>
    )}
  </>
);

export default MultiAttachment;
