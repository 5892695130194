import { getUserToken } from '@utils';
import { requestGetIdentity, requestPutIdentity } from '../../../configs';

export const get_user_all = async (role = 'teacher', is_admin) =>
  await requestGetIdentity(
    'user',
    {
      role_code: role,
      is_admin,
    },
    getUserToken()
  );
export const get_user_all_paginate = async (params) =>
  await requestGetIdentity('user/paginate', params, getUserToken());
export const set_useradmin = async (user_id, admin = true) => {
  const r = await requestPutIdentity(
    `schoolReference/setAdmin/id/${user_id}`,
    null,
    {
      is_admin: admin,
    },
    getUserToken()
  );
  return r;
};
