import { AUTH_EDIT, AUTH_FILE, AUTH_PROFILE, AUTH_USER } from '@type';

const initState = {
  user: false,
  profile: false,
  bindedAccount: [],
  updateProfile: false,
  uploadFile: false,
  checkEmail: null,
  checkEmailState: false,
  loggedOut: false,
};

const Auth = (state = initState, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        user: action.value,
      };

    case AUTH_PROFILE:
      return {
        ...state,
        profile: action.value,
      };
    case 'CHECK_EMAIL':
      return { ...state, checkEmail: action.value };
    case 'LOGOUT':
      if (action.value === true) {
        localStorage.removeItem('userData');
      }
      return { ...state, loggedOut: action.value };
    case 'CHECK_EMAIL_STATE':
      return { ...state, checkEmailState: action.value };
    case 'BINDED_ACCOUNT':
      return { ...state, bindedAccount: action.value.data };

    case AUTH_EDIT:
      return {
        ...state,
        updateProfile: action.value,
      };

    case AUTH_FILE:
      return {
        ...state,
        uploadFile: action.value,
      };

    default:
      return state;
  }
};

export default Auth;
