import React from 'react';
import { Button as Btn } from 'antd';
import '@assets/scss/components/atoms/Button.scss';

const Button = ({ text, htmlType }) => (
  <Btn className="button-atom-general" htmlType={htmlType} block>
    <p className="button-atom-general-text">{text}</p>
  </Btn>
);

export default Button;
