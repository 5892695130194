import { showLoading } from '@store/actions';
import { notification } from 'antd';
import httpService from '../../../configs/service';
// import { BASE_STUDY } from "../../../constants/initHttp";

const BASE_STUDY = 'https://dev-api.siswamedia.com/v1-study-material/v1/api/';

export const getMaterial = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('material', { params, baseURL: BASE_STUDY })
    .then((response) => response.data.data)
    .catch(() => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getMaterialById = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('material', { baseURL: BASE_STUDY, slashId: id })
    .then((response) => response.data.data)
    .catch(() => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getFilter = (id, params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('master', { params, baseURL: BASE_STUDY, slashId: id })
    .then((response) => response.data.data)
    .catch(() => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const createFileMateri = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .post('material', data, { baseURL: BASE_STUDY })
    .then((response) => response)
    .catch(() => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const updateFileMateri = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .post('material', data, { baseURL: BASE_STUDY })
    .then((response) => response)
    .catch(() => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const uploadFileMateri = (file) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .post('file/upload', file, { baseURL: BASE_STUDY })
    .then((response) => response.data.data)
    .catch(() => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const deleteMaterialById = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .delete('material', { baseURL: BASE_STUDY, slashId: id })
    .then((response) => response.data.data)
    .catch(() => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getCommentMaterial = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('rate', { baseURL: BASE_STUDY, params })
    .then((response) => response.data)
    .catch(() => {
      notification.error({
        message: 'Opps!',
        description: 'Ada kesalahan melakukan permintaan ke server',
      });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const postCommentMaterial = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .post('rate', data, { baseURL: BASE_STUDY })
    .then((response) => response.data)
    .catch(() => {
      notification.error({
        message: 'Opps!',
        description: 'Ada kesalahan melakukan permintaan ke server',
      });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const updateCommentMaterial = (data, id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .put('rate', data, { baseURL: BASE_STUDY, slashId: id })
    .then((response) => response.data)
    .catch(() => {
      notification.error({
        message: 'Opps!',
        description: 'Ada kesalahan melakukan permintaan ke server',
      });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const deleteCommentMaterial = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .delete('rate', { baseURL: BASE_STUDY, slashId: id })
    .then((response) => response.data)
    .catch(() => {
      notification.error({
        message: 'Opps!',
        description: 'Ada kesalahan melakukan permintaan ke server',
      });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};
