import { requestGet, requestPost, requestPut } from '@configs';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { notification } from 'antd';
import { CREATE_FORUM, CREATE_FORUM_COMMENT, FETCH_FORUM, READ_FORUM } from '@type';
import { reportSentry } from '../../../../utils/Utils';
import { requestDelete } from '../../../../configs';

export const fetchForum = (params, class_id = null, school_id = null) => {
  const api = (dispatch) => {
    dispatch(showLoading(true));
    return requestGet('forum', params, getUserToken())
      .then((res) => {
        if (class_id && res.data && Object.keys(params).length === 1 && school_id) {
          dispatch({
            type: FETCH_FORUM,
            payload: res.data.filter((e) => {
              if (e.class_id === class_id || e.class_id === 0) {
                return e.school_id === school_id;
              }
              return false;
            }),
          });
        } else {
          dispatch({ type: FETCH_FORUM, payload: res.data });
        }
        dispatch(showLoading(false));
      })
      .catch(() => {
        // notification.error({
        //   message: 'Mohon Maaf!',
        //   description: 'Silahkan Hubungi Customer Service Kami',
        // });
        dispatch(showLoading(false));
      });
  };
  return api;
};

export const createForum = (data) => (dispatch) => {
  dispatch(showLoading(true));
  return requestPost('forum', data, getUserToken())
    .then((res) => {
      dispatch({ type: CREATE_FORUM, payload: res.data });
      dispatch(showLoading(false));
      return res.data;
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      reportSentry(error);
    });
};

export const readForum = (id) => (dispatch) => {
  dispatch(showLoading(true));
  return requestGet(`forum/${id}`, null, getUserToken())
    .then((res) => {
      dispatch({ type: READ_FORUM, payload: res.data });
      dispatch(showLoading(false));
    })
    .catch(() => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
    });
};

export const createForumComment = (id, data) => (dispatch) => {
  dispatch(showLoading(true));
  return requestPost(`forum/${id}/reply`, data, getUserToken())
    .then((res) => {
      dispatch({ type: CREATE_FORUM_COMMENT, payload: res.data });
      dispatch(showLoading(false));
      return res.data;
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      reportSentry(error);
    });
};
export const deleteForum = (id) => (dispatch) => {
  dispatch(showLoading(true));
  return requestDelete(`forum/${id}`, null, getUserToken())
    .then((res) => {
      dispatch(showLoading(false));
      notification.success({
        message: 'Berhasil!',
        description: res.message,
      });
      return true;
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      reportSentry(error);
    });
};

export const editForum = (data) => (dispatch) => {
  dispatch(showLoading(true));
  return requestPut('forum', null, data, getUserToken())
    .then((res) => {
      dispatch(showLoading(false));
      if (res.status_code === 200) {
        return true;
      }
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      reportSentry(error);
    });
};

export const hideForumComment = (id, forumId) => (dispatch) => {
  dispatch(showLoading(true));
  return requestPut(`forum/comment/hide/${id}`, null, null, getUserToken())
    .then((res) => {
      if (res.status_code === 200) {
        dispatch(showLoading(false));
        dispatch(readForum(forumId));
        return true;
      }
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      reportSentry(error);
    });
};
