import { KOTA, PROVINSI } from '@type';
import { KECAMATAN } from '../../../constants/initType';

const initialState = {
  provinsi: [],
  kota: [],
  kecamatan: [],
};

const Wilayah = (state = initialState, action) => {
  switch (action.type) {
    case PROVINSI:
      return { ...state, provinsi: action.value };
    case KOTA:
      return { ...state, kota: action.value };
    case KECAMATAN:
      return { ...state, kecamatan: action.value };
    default:
      return state;
  }
};

export default Wilayah;
