import { requestDelete, requestGet, requestPost, requestPut } from '@configs/evaluasi';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import {
  CREATE_DETAIL_KD,
  CREATE_KARAKTER_DASAR,
  CREATE_RINCIAN_KD,
  DELETE_DETAIL_KD,
  DELETE_KARAKTER_DASAR,
  DELETE_RINCIAN_KD,
  FIND_KARAKTER_DASAR,
  LIST_DETAIL_KD,
  LIST_KARAKTER_DASAR,
  UPDATE_DETAIL_KD,
  UPDATE_KARAKTER_DASAR,
  UPDATE_RINCIAN_KD,
} from '@type';

export const getListKarakterDasar = () => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('foundamentalCharacter', { sort: 'id', direction: 'desc' }, getUserToken());
    dispatch({ type: LIST_KARAKTER_DASAR, value: rest.payload.list });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getListDetailKD = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(`kd/detail/${id}`, null, getUserToken());
    dispatch({ type: LIST_DETAIL_KD, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createKarakterDasar = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('foundamentalCharacter', data, getUserToken());

    dispatch({
      type: CREATE_KARAKTER_DASAR,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const updateKarakterDasar = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPut(`foundamentalCharacter/${id}`, null, data, getUserToken());

    dispatch({
      type: UPDATE_KARAKTER_DASAR,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const deleteKarakterDasar = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestDelete(`foundamentalCharacter/${id}`, null, getUserToken());

    dispatch({
      type: DELETE_KARAKTER_DASAR,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const findKarakterDasar = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(`foundamentalCharacter/${id}`, null, getUserToken());

    dispatch({ type: FIND_KARAKTER_DASAR, value: rest.payload });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createDetailKD = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('foundamentalAssessment', data, getUserToken());

    dispatch({
      type: CREATE_DETAIL_KD,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const updateDetailKD = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPut(`foundamentalAssessment/${id}`, null, data, getUserToken());

    dispatch({ type: UPDATE_DETAIL_KD, value: rest.data.id });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const deleteDetailKD = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestDelete(`foundamentalAssessment/${id}`, null, getUserToken());
    dispatch({ type: DELETE_DETAIL_KD, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
};

export const createRincianKD = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('foundamentalAssessment', data, getUserToken());
    dispatch({
      type: CREATE_RINCIAN_KD,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const updateRincianKD = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPut(`foundamentalAssessment/${id}`, null, getUserToken());

    dispatch({
      type: UPDATE_RINCIAN_KD,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const deleteRincianKD = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestDelete(`rfoundamentalAssessment/${id}`, null, getUserToken());
    dispatch({ type: DELETE_RINCIAN_KD, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const clearCreateKarakterDasar = () => ({
  type: CREATE_KARAKTER_DASAR,
  value: false,
});

export const clearUpdateKarakterDasar = () => ({
  type: UPDATE_KARAKTER_DASAR,
  value: false,
});

export const clearDeleteKarakterDasar = () => ({
  type: DELETE_KARAKTER_DASAR,
  value: false,
});

export const clearCreateDetailKD = () => ({
  type: CREATE_DETAIL_KD,
  value: false,
});

export const clearUpdateDetailKD = () => ({
  type: UPDATE_DETAIL_KD,
  value: false,
});

export const clearDeleteDetailKD = () => ({
  type: DELETE_DETAIL_KD,
  value: false,
});

export const clearCreateRincianKD = () => ({
  type: CREATE_RINCIAN_KD,
  value: false,
});

export const clearUpdateRincianKD = () => ({
  type: UPDATE_RINCIAN_KD,
  value: false,
});

export const clearDeleteRincianKD = () => ({
  type: DELETE_RINCIAN_KD,
  value: false,
});
