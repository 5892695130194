import {
  CREATE_CONFERENCE,
  DELETE_CONFERENCE,
  GET_CLASS_BY_SCHOOL,
  GET_CONFERENCE,
  GET_COURSE_BY_CLASS,
  GET_SCHOOL_ME,
  UPDATE_CONFERENCE,
} from '@type';

const initState = {
  list: [],
  createConference: false,
  deleteConference: null,
  updateConference: null,
  conferenceSchool: [],
  conferenceClasses: [],
  conferenceCourse: [],
};

const Conference = (state = initState, action) => {
  switch (action.type) {
    case GET_CONFERENCE:
      return { ...state, list: action.value };
    case CREATE_CONFERENCE:
      return { ...state, createConference: action.value };
    case DELETE_CONFERENCE:
      return { ...state, deleteConference: action.value };
    case UPDATE_CONFERENCE:
      return { ...state, updateConference: action.value };
    case GET_SCHOOL_ME:
      return { ...state, conferenceSchool: action.value };
    case GET_CLASS_BY_SCHOOL:
      return { ...state, conferenceClasses: action.value };
    case GET_COURSE_BY_CLASS:
      return { ...state, conferenceCourse: action.value };
    default:
      return state;
  }
};

export default Conference;
