import {
  ABSENCE_BY_COURSE_BY_DATE,
  ABSENCE_BY_COURSE_BY_MONTH,
  ABSENCE_CLASS_SUMMARY,
  ABSENCE_GURU,
  LIST_PERMIT,
} from '@type';

const initialState = {
  absenceId: 0,
  classSummary: null,
  absenceByCourseByDate: [],
  absenceByCourseByMonth: [],
  listPermit: [],
  idPermission: 0,
  responsePermit: false,
};

const Absensi = (state = initialState, action) => {
  switch (action.type) {
    case ABSENCE_GURU:
      return { ...state, absenceId: action.value };
    case ABSENCE_CLASS_SUMMARY:
      return { ...state, classSummary: action.value };
    case ABSENCE_BY_COURSE_BY_DATE:
      return { ...state, absenceByCourseByDate: action.value };
    case ABSENCE_BY_COURSE_BY_MONTH:
      return { ...state, absenceByCourseByMonth: action.value };
    case LIST_PERMIT:
      return { ...state, listPermit: action.value };
    case 'CREATE_PERMISSION':
      return { ...state, idPermission: action.value.id };
    case 'RESPONSE_PERMIT':
      return { ...state, responsePermit: action.value };
    default:
      return state;
  }
};

export default Absensi;
