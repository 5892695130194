import { notification } from 'antd';
import { AxiosInject, getUserToken, reportSentry } from '../../../../../utils/Utils';
import { BASE_REKAP_NILAI } from '../../../../../constants/initHttp';
import { showLoading } from '../../../General';

export const reportTemplateData = (params) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('v1/api/reportTemplate', {
        params,
      })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch(() => {
        dispatch(showLoading(false));
        notification.error({
          message: 'Mohon Maaf',
          description: 'Silahkan Hubungi Kami',
        });
      });
  };
};
export const reportTemplateDataPost = (body) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .post('v1/api/reportTemplate', body)
      .then((r) => {
        dispatch(showLoading(false));
        if (r.status === 200) {
          return true;
        }
      })
      .catch(() => {
        dispatch(showLoading(false));
        notification.error({
          message: 'Mohon Maaf',
          description: 'Silahkan Hubungi Kami',
        });
      });
  };
};
export const reportTemplateDataPut = (id, body) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .put(`v1/api/reportTemplate/id/${id}`, body)
      .then((r) => {
        dispatch(showLoading(false));
        if (r.status === 200) {
          return true;
        }
      })
      .catch(() => {
        dispatch(showLoading(false));
        notification.error({
          message: 'Mohon Maaf',
          description: 'Silahkan Hubungi Kami',
        });
      });
  };
};
export const reportTemplateDataDelete = (id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .delete(`v1/api/reportTemplate/id/${id}`)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        throw new Error(r);
      });
  };
};

export const reportTemplateDataById = (id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get(`v1/api/reportTemplate/id/${id}`)
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        throw new Error(r);
      });
  };
};

export const reportCoverData = (search = '') => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('/v1/api/reportData/select', {
        params: {
          search,
        },
      })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        throw new Error(r);
      });
  };
};

export const uploadFileRekapNilai = (fd) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .post('/v1/api/file', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'json',
      })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        reportSentry(r);
      });
  };
};

export const getNewSchoolYear = (search = '', status = true, is_future = false) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return async (dispatch) => {
    dispatch(showLoading(true));
    return await httpInjector
      .get('/v1/api/schoolYear/select', {
        params: {
          search,
          status,
          is_future,
        },
      })
      .then((r) => {
        dispatch(showLoading(false));
        return r.data;
      })
      .catch((r) => {
        dispatch(showLoading(false));
        throw new Error(r);
      });
  };
};
