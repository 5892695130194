import { CREATE_JP, CREATE_TEMA, DELETE_TEMA, LIST_TEMA, UPDATE_TEMA } from '@type';

const initState = {
  list: false,
  create: false,
  createJp: false,
  delete: false,
  update: false,
};

const TemaPenilaian = (state = initState, action) => {
  switch (action.type) {
    case LIST_TEMA:
      return {
        ...state,
        list: action.value,
      };

    case CREATE_TEMA:
      return {
        ...state,
        create: action.value,
      };

    case CREATE_JP:
      return {
        ...state,
        createJp: action.value,
      };

    case UPDATE_TEMA:
      return {
        ...state,
        update: action.value,
      };

    case DELETE_TEMA:
      return {
        ...state,
        delete: action.value,
      };

    default:
      return state;
  }
};

export default TemaPenilaian;
