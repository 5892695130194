import {
  GET_ALL_COURSES,
  GET_COURSES_BY_ME,
  MASTER_BANK,
  MASTER_CONTENT_TYPE,
  MASTER_COURSE_CERTIFICATE_TYPE,
  MASTER_COURSE_DIFFICULTY_LEVEL,
  MASTER_COURSE_SCHEDULE_TYPE,
  MASTER_COURSE_TYPE,
  MASTER_LANG,
  UPLOAD_COVER,
} from '@type';

const initialState = {
  courseScheduleType: [],
  difficultyLevel: [],
  certificateType: [],
  langType: [],
  uploadCover: null,
  myCourses: [],
  allCourse: [],
  allBanks: [],
  courseType: [],
  contentType: [],
};

const Kursus = (state = initialState, action) => {
  switch (action.type) {
    case MASTER_COURSE_SCHEDULE_TYPE:
      return {
        ...state,
        courseScheduleType: action.value,
      };
    case MASTER_COURSE_DIFFICULTY_LEVEL:
      return {
        ...state,
        difficultyLevel: action.value,
      };
    case MASTER_COURSE_CERTIFICATE_TYPE:
      return {
        ...state,
        certificateType: action.value,
      };
    case MASTER_LANG:
      return {
        ...state,
        langType: action.value,
      };
    case MASTER_COURSE_TYPE:
      return {
        ...state,
        courseType: action.value,
      };
    case UPLOAD_COVER:
      return {
        ...state,
        uploadCover: action.value,
      };
    case GET_COURSES_BY_ME:
      return {
        ...state,
        myCourses: action.value,
      };
    case GET_ALL_COURSES:
      return {
        ...state,
        allCourses: action.value,
      };
    case MASTER_BANK:
      return {
        ...state,
        allBanks: action.value,
      };
    case MASTER_CONTENT_TYPE:
      return {
        ...state,
        contentType: action.value,
      };

    default:
      return state;
  }
};
export default Kursus;
