import {
  CREATE_TUGAS,
  DETAIL_HISTORY,
  GET_ASSESSMENT_STUDENT_REFERENCE,
  GET_ASSESSMENT_STUDENT_REFERENCE_HISTORY,
  TUGAS_DETAIL,
  TUGAS_GURU,
  TUGAS_GURU_HISTORY,
  TUGAS_REFERENCE,
  TUGAS_SISWA,
  TUGAS_SISWA_HISTORY,
  UPLOAD,
} from '@type';

const initialState = {
  tugas_siswa: [],
  tugas_guru: [],
  tugas_siswa_history: [],
  tugas_guru_history: [],
  tugas_reference: [],
  tugas_detail: null,
  tugas_reference_detail: null,
  fileUploaded: { id: null, webViewLink: null, name: null },
  studentReference: null,
  commentSuccess: null,
  studentReferenceHistory: [],
  tugas_detail_history: [],
};

const Tugas = (state = initialState, action) => {
  switch (action.type) {
    case TUGAS_SISWA:
      return { ...state, tugas_siswa: action.value };
    case TUGAS_GURU:
      return { ...state, tugas_guru: action.value };
    case TUGAS_SISWA_HISTORY:
      return { ...state, tugas_siswa_history: action.value };
    case TUGAS_GURU_HISTORY:
      return { ...state, tugas_guru_history: action.value };
    case TUGAS_REFERENCE:
      return { ...state, tugas_reference: action.value };
    case DETAIL_HISTORY:
      return { ...state, tugas_detail_history: action.value };
    case TUGAS_DETAIL:
      return { ...state, tugas_detail: action.value };
    case CREATE_TUGAS:
      return state;
    case GET_ASSESSMENT_STUDENT_REFERENCE:
      return { ...state, studentReference: action.value };
    case 'BERI_NILAI':
      return state;
    case 'TAMBAH_COMMENT':
      return { ...state, commentSuccess: action.value };
    case UPLOAD:
      return {
        ...state,
        fileUploaded: {
          id: action.value.id,
          webViewLink: action.value.webViewLink,
          name: action.value.name,
        },
      };
    case GET_ASSESSMENT_STUDENT_REFERENCE_HISTORY:
      return {
        ...state,
        studentReferenceHistory: action.value,
      };
    default:
      return state;
  }
};

export default Tugas;
