/* eslint-disable no-undef */
// interceptor network

/* eslint-disable no-param-reassign */
import moment from 'moment/moment';
import Axios from 'axios';
import { getUserToken } from '@utils';
import { BASE_API_URL } from '../../constants/initHttp';
import { generateSignature, notificationOverride } from '../../utils/Utils';
import { store } from '../../redux/store';

const httpService = Axios.create({
  baseURL: BASE_API_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
    Authorization: null,
    customAuthorization: false,
  },
  slashId: null,
});

httpService.interceptors.request.use(
  (config) => {
    const token = getUserToken();

    if (config.slashId) {
      config.url = `${config.url}/${config.slashId}`;
    }

    if (!config.headers.customAuthorization && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config?.headers?.Authorization) {
      const { method } = config;
      let _body = '';
      let _token = '';
      let _url = config.baseURL + config.url;
      if (method === 'post' || method === 'put') {
        const _data = config.data ?? '';
        _body = window.btoa(JSON.stringify(_data));
      }
      const host = _url.split('/')[2];
      _url = _url.replace(`https://${host}`, '');
      _token = config.headers.Authorization;
      config.headers.Timestamp = moment().toISOString();
      let pathName = '';
      config.params &&
        Object.keys(config.params).map((_key, index) => {
          if (config.params[_key] !== null) {
            pathName += `${_key}=${config.params[_key] ?? ''}`;
            if (Object.keys(config.params).length - 1 !== index) {
              pathName += '&';
            }
          }
          return _key;
        });
      if (pathName !== '') {
        _url = `${_url}?${pathName.replace(' ', '+')}`;
      }
      config.headers.Signature = generateSignature(
        _url,
        _token.replace(' ', ''),
        config.method.toUpperCase(),
        config.headers.Timestamp,
        _body
      );

      delete config.headers.Signature;
      delete config.headers.Timestamp;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

httpService.interceptors.response.use(
  async (res) => res,
  (error) => {
    if (error?.response?.status === 401) {
      const _message = error?.response?.data?.message ?? error?.response?.data?.data ?? error?.response?.data?.error;
      if (_message === 'invalid authorization') {
        // notificationOverride({
        //   title: 'Permintaan Ditolak',
        //   description: 'Sesi login Anda telah berakhir. Silakan login kembali untuk melanjutkan.',
        //   type: 'warning',
        // });
      } else if (['unauthorized device', 'UNAUTHORIZED DEVICE'].includes(_message)) {
        notificationOverride({
          title: 'Perangkat Baru Telah Terdaftar',
          description:
            'Secara otomatis akun Anda dikeluarkan dari situs. Silahkan lakukan login kembali untuk mengakses Siswamedia.',
          type: 'warning-check',
        });
      }
      store.dispatch({ type: 'LOGOUT', value: true });
    }
    return Promise.reject(error);
  }
);

export default httpService;
