import { CHANGE_SCHOOL, DETAIL_SCHOOL, GET_SCHOOL_ME, LIST_SCHOOL } from '@type';

const initialState = {
  listSchoolMe: [],
  listSchool: [],
  listSchoolParams: {
    isEmpty: false,
  },
  changeSchool: null,
  schoolDetail: null,
};

const School = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHOOL_ME:
      return { ...state, listSchoolMe: action.value };
    case 'GET_SCHOOL_ME_PARAMS':
      return { ...state, listSchoolParams: action.value };
    case LIST_SCHOOL:
      return { ...state, listSchool: action.value };
    case DETAIL_SCHOOL:
      return {
        ...state,
        schoolDetail: action.value,
      };
    case CHANGE_SCHOOL:
      return {
        ...state,
        changeSchool: action.value,
      };
    default:
      return state;
  }
};

export default School;
