import { GET_ALL_COURSES, GET_COURSES_BY_ME } from '@type';
import axios from 'axios';
import { showLoading } from '@store/actions';
import { notification } from 'antd';
import { getUserToken } from '@utils';
import { BASE_COURSE } from '../../../../constants/initHttp';
import { reportSentry } from '../../../../utils/Utils';

export const getListCoursesByMe = (page, limit, status, rate, query) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(
      `${BASE_COURSE}/v1/api/course/me?page=${page}&limit=${limit}&status=${status === undefined ? '' : status}&rate=${
        rate === undefined ? '' : rate
      }&q=${query}`,
      {
        headers: { Authorization: `Bearer ${getUserToken()}` },
      }
    )
    .then((response) => {
      // if (response.data.code === 200) {
      dispatch({
        type: GET_COURSES_BY_ME,
        value: response.data.data,
      });
      // }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.data,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};
export const getListAllCourses = (page, limit, status, rate) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .get(
      `${BASE_COURSE}/v1/api/course?page=${page}&limit=${limit}${status !== undefined ? `&status=${status}` : ''}${
        rate !== undefined ? `&rate=${rate}` : ''
      }`
    )
    .then((response) => {
      // if (response.data.code === 200) {
      dispatch({ type: GET_ALL_COURSES, value: response.data.data });
      // }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.data,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const postCourses = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .post(`${BASE_COURSE}/v1/api/course`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem('course_id', res.data.id);
        notification.success({
          message: 'Sukses!',
          description: 'Kursus berhasil dibuat',
        });
      }
      return res.data;
    })
    .catch((err) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Oops!',
        description: err.response?.data.data,
      });
      throw err;
    });
  return api;
};

export const postClass = (data, courseId, classNumber) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .post(`${BASE_COURSE}/v1/api/course/${courseId}/class/${classNumber}`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((res) => {
      if (res.data.code === 200) {
        // localStorage.setItem('course_id',res.data.id)
        notification.success({
          message: 'Sukses!',
          description: 'Kursus berhasil dibuat',
        });
      }
      return res.data;
    })
    .catch((err) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Oops!',
        description: err.response?.data.data,
      });
      throw err;
    });
  return api;
};
