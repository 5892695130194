import { GET_UJIAN_GURU, GET_UJIAN_PARTICIPANT_GURU } from '@type';
import { listTableDetailParticipant, paginatior } from '../../../selector/MediaKelas/UjianGuru';

const initState = {
  list: [],
  detail: null,
  pagination: {
    current_page: 1,
    per_page: 10,
    last_page: 0,
    total: 0,
  },
  listParticipant: [],
  paginationParticipant: {
    current_page: 1,
    per_page: 10,
    last_page: 0,
    total: 0,
  },
};

const UjianGuru = (state = initState, action) => {
  switch (action.type) {
    case GET_UJIAN_GURU:
      return {
        ...state,
        list: action.value.list || [],
        pagination: paginatior(action.pagination.meta, state.pagination),
      };

    case GET_UJIAN_PARTICIPANT_GURU:
      return {
        ...state,
        listParticipant: listTableDetailParticipant(action.value),
        paginationParticipant: action.pagination,
      };
    default:
      return state;
  }
};

export default UjianGuru;
