import {
  CLASS_EVALUASI,
  CREATE_EVALUASI_KARAKTER,
  CREATE_EVALUASI_KARAKTER_DESC,
  CREATE_EVALUASI_KARAKTER_PENILAIAN,
  DELETE_EVALUASI_KARAKTER,
  DETAIL_EVALUASI_KARAKTER,
  LIST_EVALUASI_KARAKTER,
  STUDENT_EVALUASI,
  UPDATE_EVALUASI_KARAKTER,
} from '@type';

const initState = {
  list: false,
  create: false,
  update: false,
  delete: false,
  detail: false,
  penilaian: false, // create
  desc: false, // create
  class: false,
  students: false,
};

const EvaluasiKarakter = (state = initState, action) => {
  switch (action.type) {
    case LIST_EVALUASI_KARAKTER:
      return {
        ...state,
        list: action.value,
      };

    case CREATE_EVALUASI_KARAKTER:
      return {
        ...state,
        create: action.value,
      };

    case UPDATE_EVALUASI_KARAKTER:
      return {
        ...state,
        update: action.value,
      };

    case DELETE_EVALUASI_KARAKTER:
      return {
        ...state,
        delete: action.value,
      };

    case DETAIL_EVALUASI_KARAKTER:
      return {
        ...state,
        detail: action.value,
      };

    case CREATE_EVALUASI_KARAKTER_PENILAIAN:
      return {
        ...state,
        penilaian: action.value,
      };

    case CREATE_EVALUASI_KARAKTER_DESC:
      return {
        ...state,
        desc: action.value,
      };

    case CLASS_EVALUASI:
      return {
        ...state,
        class: action.value,
      };

    case STUDENT_EVALUASI:
      return {
        ...state,
        students: action.value,
      };

    default:
      return state;
  }
};

export default EvaluasiKarakter;
