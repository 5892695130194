import { requestDelete, requestGet, requestPost, requestPut } from '@configs/evaluasi';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import {
  CLASS_EVALUASI,
  CREATE_EVALUASI_KARAKTER,
  CREATE_EVALUASI_KARAKTER_DESC,
  CREATE_EVALUASI_KARAKTER_PENILAIAN,
  DELETE_EVALUASI_KARAKTER,
  DETAIL_EVALUASI_KARAKTER,
  LIST_EVALUASI_KARAKTER,
  STUDENT_EVALUASI,
  UPDATE_EVALUASI_KARAKTER,
} from '@type';

export const getListClassEvaluasi = (schoolId, sort, direction) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('class', { school_id: schoolId, sort, direction }, getUserToken());

    dispatch({ type: CLASS_EVALUASI, value: rest.payload.list });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getListStudentEvaluasi = (classId, sort, direction) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('student', { class_id: classId, sort, direction }, getUserToken());

    dispatch({ type: STUDENT_EVALUASI, value: rest.payload.list });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getListEvaluasiKarakter = (classId, teacherId, sort, direction) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(
      'characterevaluation',
      {
        class_id: classId,
        teacher_id: teacherId,
        sort,
        direction,
      },
      getUserToken()
    );

    dispatch({
      type: LIST_EVALUASI_KARAKTER,
      value: rest.payload.list,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createEvaluasiKarakter = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('characterevaluation', data, getUserToken());
    dispatch({
      type: CREATE_EVALUASI_KARAKTER,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const updateEvaluasiKarakter = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPut(`characterevaluation/${id}`, null, data, getUserToken());

    dispatch({
      type: UPDATE_EVALUASI_KARAKTER,
      value: rest.statusCode === 200,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const deleteEvaluasiKarakter = (id) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    await requestDelete(`characterevaluation/${id}`, null, getUserToken());
    dispatch({ type: DELETE_EVALUASI_KARAKTER, value: true });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const getDetailEvaluasiKarakter = (studentId, characterEvaluationId) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(
      'CharacterEvaluationStudent/detail',
      {
        student_id: studentId,
        character_evaluation_id: characterEvaluationId,
      },
      getUserToken()
    );

    dispatch({
      type: DETAIL_EVALUASI_KARAKTER,
      value: rest.statusCode === 200 ? rest.payload : [],
    });
  } catch (error) {
    if (error.response.status !== 400) {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
    }
  }
  dispatch(showLoading(false));
};

export const createEvaluasiKarakterPenilaian = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('evaluasis/nilai', data, getUserToken());
    dispatch({
      type: CREATE_EVALUASI_KARAKTER_PENILAIAN,
      value: rest.data,
    });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const createEvaluasiKarakterDesc = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('evaluasis/catat', data, getUserToken());

    dispatch({ type: CREATE_EVALUASI_KARAKTER_DESC, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const clearCreateEvaluasiKarakter = () => ({
  type: CREATE_EVALUASI_KARAKTER,
  value: false,
});

export const clearUpdateEvaluasiKarakter = () => ({
  type: UPDATE_EVALUASI_KARAKTER,
  value: false,
});

export const clearDeleteEvaluasiKarakter = () => ({
  type: DELETE_EVALUASI_KARAKTER,
  value: false,
});

export const clearCreateEvaluasiKarakterDesc = () => ({
  type: CREATE_EVALUASI_KARAKTER_DESC,
  value: false,
});

export const clearCreateEvaluasiKarakterPenilaian = () => ({
  type: CREATE_EVALUASI_KARAKTER_PENILAIAN,
  value: false,
});
