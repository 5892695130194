import { requestGet, requestPost } from '@configs';
import { getUserToken } from '@utils';
import { DETAIL_SCHOOL, GET_SCHOOL_ME, IS_SUCCESS, LIST_SCHOOL } from '@type';
import { showLoading } from '@store/actions';
import { AxiosInject, notificationOverride, reportSentry } from '../../../utils/Utils';
import { BASE_REKAP_NILAI } from '../../../constants/initHttp';
import { requestPut } from '../../../configs';
import { runError } from '../../../utils/errorHandler';

export const getSchool = (params) => async (dispatch) => {
  const api = await requestGet('me/schools', params, getUserToken())
    .then((response) => {
      dispatch({ type: GET_SCHOOL_ME, value: response.data });
      return response.data;
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
export const getSchoolById = (id) => async (dispatch) => {
  const api = await requestGet(`schools/${id ?? 0}`, null, getUserToken())
    .then((response) => {
      dispatch({ type: DETAIL_SCHOOL, value: response.data });
      return response.data;
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const getSchoolRekap = () => async (dispatch) => {
  const httpInject = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());
  return await httpInject
    .get('/v1/api/school/id')
    .then((r) => {
      if (r.data.code === 200) {
        dispatch({
          type: DETAIL_SCHOOL,
          value: r.data.data,
        });
      } else {
        reportSentry(
          new Error('method getSchoolRekap', {
            cause: r.data,
          })
        );
      }
    })
    .catch((e) => {
      reportSentry(e);
    });
};

export const createSchools = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost('schools', data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Create School Success',
        });
        dispatch(showLoading(false));
        return response.data;
        // dispatch({ type: CREATE_School, value: response.data });
      }
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Create School Failed',
      });

      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notificationOverride({
        title: 'Permintaan Ditolak',
        description: error.response.data.message,
        type: 'error',
      });
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Create School Failed',
      });
      reportSentry(error);
    });
  return api;
};

export const updateSchools = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPut(`schools/id/${id ?? 0}`, null, data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Create School Success',
        });
        dispatch(getSchoolById(id));
        dispatch(showLoading(false));
        return response.data;
        // dispatch({ type: CREATE_School, value: response.data });
      }
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Create School Failed',
      });

      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      runError(error.response.message, error);
    });
  return api;
};

export const joinSchools = (invitation_code) => async (dispatch) => {
  const api = await requestPost('join-schools', { invitation_code }, getUserToken())
    .then((response) => {
      if (response.status_code === 201) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Success Join School',
        });
      } else {
        dispatch({
          type: IS_SUCCESS,
          value: false,
          message: 'Join School Failed',
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Join School Failed',
      });
      reportSentry(error);
    });
  return api;
};

export const joinSchoolsForm = (id) => async (dispatch) => {
  const api = await requestPost('join-schools-form', { school_id: id }, getUserToken())
    .then((response) => {
      if (response.data.status_code === 201) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Create School Success',
        });
      } else {
        dispatch({
          type: IS_SUCCESS,
          value: false,
          message: 'Create School Failed',
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Create School Failed',
      });
      reportSentry(error);
    });
  return api;
};
export const searchSchool = (provinsi, kota, grade) => async (dispatch) => {
  const api = await requestGet(
    `schools?limit=100&provinsi_id=${provinsi}&kota_id=${kota}&grade=${grade}`,
    false,
    getUserToken()
  )
    .then((response) => {
      dispatch({ type: LIST_SCHOOL, value: response.data });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
