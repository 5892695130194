// Auth
export const AUTH_USER = 'AUTH_USER';
export const AUTH_PROFILE = 'AUTH_PROFILE';
export const AUTH_EDIT = 'AUTH_EDIT';
export const AUTH_FILE = 'AUTH_FILE';

// Role
export const GET_ROLE = 'GET_ROLE';
export const SET_ROLE = 'SET_ROLE';
export const CHANGE_SCHOOL = 'CHANGE_SCHOOL';
export const STATUS_ROLE = 'STATUS_ROLE';

// General
export const SET_LOADING = 'SET_LOADING';
export const IS_SUCCESS = 'IS_SUCCESS';
export const IS_UPLOAD_SUCCESS = 'IS_UPLOAD_SUCCESS';
export const SAVE_STATE_FOR_CHANGE_ROLE = 'SAVE_STATE_FOR_CHANGE_ROLE';
export const UPLOAD = 'UPLOAD';
export const SCHOOL_YEAR = 'SCHOOL_YEAR';
export const WHITELABEL_CONFIG = 'WHITELABEL_CONFIG';

//= =============MediaKelas================//
// Absensi
export const ABSENCE_GURU = 'ABSENCE_GURU';
export const ABSENCE_SISWA = 'ABSENCE_SISWA';
export const ABSENCE_CLASS_SUMMARY = 'ABSENCE_CLASS_SUMMARY';
export const ABSENCE_BY_COURSE_BY_DATE = 'ABSENCE_BY_COURSE_BY_DATE ';
export const ABSENCE_BY_COURSE_BY_MONTH = 'ABSENCE_BY_COURSE_BY_MONTH ';
export const LIST_PERMIT = 'LIST_PERMIT ';

// Tugas
export const TUGAS_SISWA = 'TUGAS_SISWA';
export const TUGAS_SISWA_HISTORY = 'TUGAS_SISWA_HISTORY';
export const TUGAS_GURU = 'TUGAS_GURU';
export const TUGAS_GURU_HISTORY = 'TUGAS_GURU_HISTORY';
export const TUGAS_DETAIL = 'TUGAS_DETAIL';
export const TUGAS_DETAIL_REFERENCE = 'TUGAS_DETAIL_REFERENCE';
export const TUGAS_REFERENCE = 'TUGAS_REFERENCE';
export const PUT_TUGAS_SISWA = 'PUT_TUGAS_SISWA';
export const CREATE_TUGAS = 'CREATE_TUGAS';
export const GET_ASSESSMENT_STUDENT_REFERENCE = 'GET_ASSESSMENT_STUDENT_REFERENCE';
export const DETAIL_HISTORY = 'DETAIL_HISTORY';
export const PUT_ASSESSMENT_STUDENT = 'PUT_ASSESSMENT_STUDENT';

// Jadwal
export const GET_SCHEDULES = 'GET_SCHEDULES';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';

// Konferensi
export const GET_CONFERENCE = 'GET_CONFERENCE';
export const CREATE_CONFERENCE = 'CREATE_CONFERENCE';
export const DELETE_CONFERENCE = 'DELETE_CONFERENCE';
export const GET_CONFERENCE_SISWA = 'GET_CONFERENCE_SISWA';
export const UPDATE_CONFERENCE = 'UPDATE_CONFERENCE';

// Kelasku
export const GET_CLASSES = 'GET_CLASSES';
export const CREATE_CLASS = 'CREATE_CLASS';
export const UPDATE_CLASS = 'UPDATE_CLASS';
export const DELETE_CLASS = 'DELETE_CLASS';
export const GET_SCHOOL_ME = 'GET_SCHOOL_ME';
export const DETAIL_SCHOOL = 'DETAIL_SCHOOL';
export const GET_CURRENT_CLASS = 'GET_CURRENT_CLASS';
export const SET_CURRENT_CLASS = 'SET_CURRENT_CLASS';
export const GET_CLASS_ME = 'GET_CLASS_ME';
export const GET_CLASS_BY_SCHOOL = 'GET_CLASS_BY_SCHOOL';
export const GET_COURSE_BY_CLASS = 'GET_COURSE_BY_CLASS';
export const GET_COURSE_BY_SCHOOL = 'GET_COURSE_BY_SCHOOL';
export const GET_TEACHER_BY_CLASS = 'GET_TEACHER_BY_CLASS';
export const SET_WALIKELAS = 'SET_WALIKELAS';
export const GET_STUDENT_BY_CLASS = 'GET_STUDENT_BY_CLASS';
export const GET_MAPEL_BY_CLASS = 'GET_MAPEL_BY_CLASS';
export const CREATE_MAPEL = 'CREATE_MAPEL';
export const UPDATE_MAPEL = 'UPDATE_MAPEL';
export const DELETE_MAPEL = 'DELETE_MAPEL';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const GET_SYNC_CLASS = 'GET_SYNC_CLASS';
export const GET_SYNC_STUDENT = 'GET_SYNC_STUDENT';
export const GET_SYNC_TEACHER = 'GET_SYNC_TEACHER';
export const CREATE_SYNC_CLASS = 'CREATE_SYNC_CLASS';

// Ujian
export const GET_QUIZ_PERSONAL = 'GET_QUIZ_PERSONAL';
export const GET_LIST_BANK_SOAL = 'GET_LIST_BANK_SOAL';
export const GET_COURSE_BANK_SOAL = 'GET_COURSE_BANK_SOAL';
export const GET_LIST_MASTER_SOAL = 'GET_LIST_MASTER_SOAL';
export const GET_SOAL_BANK_SOAL = 'GET_SOAL_BANK_SOAL';
export const UPDATE_QUIZ = 'UPDATE_QUIZ';
export const DELETE_QUIZ = 'DELETE_QUIZ';
export const GET_DETAIL_QUIZ = 'GET_DETAIL_QUIZ';
export const GET_QUIZ_JAWABAN = 'GET_QUIZ_JAWABAN';

// Ujian Student
export const CREATE_QUIZ_BANK_SOAL = 'CREATE_QUIZ_BANK_SOAL';
export const CREATE_ANSWER_QUIZ_BANK_SOAL = 'CREATE_ANSWER_QUIZ_BANK_SOAL';
export const GET_QUIZ_DETAIL = 'GET_QUIZ_DETAIL';
export const GET_QUIZ_SOAL = 'GET_QUIZ_SOAL';

// Ujian Siswa Refactor
export const FETCH_STUDENT_EXAM = 'FETCH_STUDENT_EXAM';
export const FETCH_EXAM_QUESTION = 'FETCH_EXAM_QUESTION';
export const READ_STUDENT_EXAM = 'READ_STUDENT_EXAM';
export const READ_QNA_EXAM = 'READ_QNA_EXAM';
export const SUBMIT_FACE_DETECTION = 'SUBMIT_FACE_DETECTION';
export const START_EXAM = 'START_EXAM';
export const SUBMIT_CHANGE_TAB = 'SUBMIT_CHANGE_TAB';
export const SUBMIT_STUDENT_EXAM = 'SUBMIT_STUDENT_EXAM';
export const COLLECT_ANSWER = 'COLLECT_ANSWER';

// Forum
export const FETCH_FORUM = 'FETCH_FORUM';
export const CREATE_FORUM = 'CREATE_FORUM';
export const READ_FORUM = 'READ_FORUM';
export const UPDATE_FORUM = 'UPDATE_FORUM';
export const CREATE_FORUM_COMMENT = 'CREATE_FORUM_COMMENT';

// GDRIVE
export const ADD_FILE_GDRIVE = 'ADD_FILE_GDRIVE';
export const READ_FILE_GDRIVE = 'READ_FILE_GDRIVE';

// Dokumen
export const GET_DOCUMENTS = 'GET_DOCUMENTS';

// Nilai
export const GET_STUDENT_NILAI = 'GET_STUDENT_NILAI';
export const GET_NILAI_BY_STUDENT = 'GET_NILAI_BY_STUDENT';
export const GET_DETAIL_NILAI = 'GET_DETAIL_NILAI';

// Jurnal Belajar
export const CREATE_JURNAL_BELAJAR = 'CREATE_JURNAL_BELAJAR';
export const LIST_JURNAL_BELAJAR = 'LIST_JURNAL_BELAJAR';
export const JURNAL_BELAJAR_DETAIL = 'JURNAL_BELAJAR_DETAIL';
export const LIST_JURNAL_BELAJAR_DRAGGABLE = 'LIST_JURNAL_BELAJAR_DRAGGABLE';

//= ======================================//

// WILAYAH
export const KECAMATAN = 'KECAMATAN';
export const KOTA = 'KOTA';
export const PROVINSI = 'PROVINSI';

// SEKOLAH
export const JOIN_SCHOOLS = 'JOIN_SCHOOLS';
export const JOIN_CLASSES = 'JOIN_CLASS';
export const LIST_SCHOOL = 'LIST_SCHOOL';

export const JURNAL_BELAJAR_TUGAS = 'JURNAL_BELAJAR_TUGAS';
export const JURNAL_BELAJAR_UJIAN = 'JURNAL_BELAJAR_UJIAN';

// Evaluasi
export const LIST_TEMA = 'LIST_TEMA';
export const CREATE_TEMA = 'CREATE_TEMA';
export const CREATE_JP = 'CREATE_JP';
export const DELETE_TEMA = 'DELETE_TEMA';
export const UPDATE_TEMA = 'UPDATE_TEMA';

export const KARAKTER_UTAMA_CREATE = 'KARAKTER_UTAMA_CREATE';
export const KARAKTER_UTAMA_DELETE = 'KARAKTER_UTAMA_DELETE';
export const KARAKTER_UTAMA_UPDATE = 'KARAKTER_UTAMA_UPDATE';

export const LIST_DETAIL_KD = 'LIST_DETAIL_KD';
export const CREATE_DETAIL_KD = 'CREATE_DETAIL_KD';
export const UPDATE_DETAIL_KD = 'UPDATE_DETAIL_KD';
export const DELETE_DETAIL_KD = 'DELETE_DETAIL_KD';

export const LIST_RINCIAN_KD = 'LIST_RINCIAN_KD';
export const CREATE_RINCIAN_KD = 'CREATE_RINCIAN_KD';
export const UPDATE_RINCIAN_KD = 'UPDATE_RINCIAN_KD';
export const DELETE_RINCIAN_KD = 'DELETE_RINCIAN_KD';

export const LIST_KARAKTER_DASAR = 'LIST_KARAKTER_DASAR';
export const FIND_KARAKTER_DASAR = 'FIND_KARAKTER_DASAR';
export const CREATE_KARAKTER_DASAR = 'CREATE_KARAKTER_DASAR';
export const UPDATE_KARAKTER_DASAR = 'UPDATE_KARAKTER_DASAR';
export const DELETE_KARAKTER_DASAR = 'DELETE_KARAKTER_DASAR';

export const DATA_DRAFT_SKL = 'DATA_DRAFT_SKL';
export const CREATE_DRAFT_SKL = 'CREATE_DRAFT_SKL';
export const UPDATE_DRAFT_SKL = 'UPDATE_DRAFT_SKL';

export const LIST_KATEGORI_PENILAIAN = 'LIST_KATEGORI_PENILAIAN';
export const CREATE_KATEGORI_PENILAIAN = 'CREATE_KATEGORI_PENILAIAN';
export const UPDATE_KATEGORI_PENILAIAN = 'UPDATE_KATEGORI_PENILAIAN';
export const DELETE_KATEGORI_PENILAIAN = 'DELETE_KATEGORI_PENILAIAN';

export const LIST_EVALUASI_KARAKTER = 'LIST_EVALUASI_KARAKTER';
export const CREATE_EVALUASI_KARAKTER = 'CREATE_EVALUASI_KARAKTER';
export const UPDATE_EVALUASI_KARAKTER = 'UPDATE_EVALUASI_KARAKTER';
export const DELETE_EVALUASI_KARAKTER = 'DELETE_EVALUASI_KARAKTER';
export const CREATE_EVALUASI_KARAKTER_PENILAIAN = 'CREATE_EVALUASI_KARAKTER_PENILAIAN';
export const CREATE_EVALUASI_KARAKTER_DESC = 'CREATE_EVALUASI_KARAKTER_DESC';
export const CLASS_EVALUASI = 'CLASS_EVALUASI';
export const STUDENT_EVALUASI = 'STUDENT_EVALUASI';

// Interkasi Belajar

export const LIST_INTERAKSI_BELAJAR_ROOM = 'LIST_INTERAKSI_BELAJAR_ROOM';
export const GET_INTERAKSI_BELAJAR_ROOM = 'GET_INTERAKSI_BELAJAR_ROOM';
export const GET_PARTISIPAN_INTERAKSI_BELAJAR_ROOM = 'GET_PARTISIPAN_INTERAKSI_BELAJAR_ROOM';
export const CREATE_INTERAKSI_BELAJAR_ROOM = 'CREATE_INTERAKSI_BELAJAR_ROOM';
export const UPDATE_INTERAKSI_BELAJAR_ROOM = 'UPDATE_INTERAKSI_BELAJAR_ROOM';
export const DELETE_INTERAKSI_BELAJAR_ROOM = 'DELETE_INTERAKSI_BELAJAR_ROOM';
export const SELECTED_PARTISIPAN_INTERKASI_BELAJAR = 'SELECTED_PARTISIPAN_INTERKASI_BELAJAR';
export const SOCKET_RESPONSE_INTERAKSI_BELAJAR_ROOM = 'SOCKET_RESPONSE_INTERAKSI_BELAJAR_ROOM';
export const CHAT_INTERAKSI_BELAJAR_ROOM = 'CHAT_INTERAKSI_BELAJAR_ROOM';
export const SET_CHATID_INTERAKSI_BELAJAR_ROOM = 'SET_CHATID_INTERAKSI_BELAJAR_ROOM';

export const DETAIL_EVALUASI_KARAKTER = 'DETAIL_EVALUASI_KARAKTER';

export const MASTER_COURSE_SCHEDULE_TYPE = 'MASTER_COURSE_SCHEDULE_TYPE';
export const MASTER_COURSE_CERTIFICATE_TYPE = 'MASTER_COURSE_CERTIFICATE_TYPE';
export const MASTER_COURSE_DIFFICULTY_LEVEL = 'MASTER_COURSE_DIFFICULTY_LEVEL';
export const MASTER_LANG = 'MASTER_LANG';
export const UPLOAD_COVER = 'UPLOAD_COVER';
export const MASTER_BANK = 'MASTER_BANK';

export const GET_COURSES_BY_ME = 'GET_COURSES_BY_ME';
export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const MASTER_COURSE_TYPE = 'MASTER_COURSE_TYPE';

export const MASTER_CONTENT_TYPE = 'MASTER_CONTENT_TYPE';
export const GET_ALL_BANK_SOAL = 'GET_ALL_BANK_SOAL';
export const GET_BANK_SOAL = 'GET_BANK_SOAL';
export const CREATE_BANK_SOAL = 'CREATE_BANK_SOAL';
export const EDIT_BANK_SOAL = 'EDIT_BANK_SOAL';
export const DELETE_BANK_SOAL = 'DELETE_BANK_SOAL';
export const CLEAR_BANK_SOAL = 'CLEAR_BANK_SOAL';

export const GET_TYPE_LIST = 'GET_TYPE_LIST';
export const GET_ONE_BANK_SOAL = 'GET_ONE_BANK_SOAL';

export const GET_LIST_EXAM = 'GET_LIST_EXAM';
export const GET_EXAM_DETAIL = 'GET_EXAM_DETAIL';
export const GET_QUESTION_BY_EXAM_ID = 'GET_QUESTION_BY_EXAM_ID';

// Ujian Guru
export const GET_UJIAN_GURU = 'GET_UJIAN_GURU';
export const GET_UJIAN_PARTICIPANT_GURU = 'GET_UJIAN_PARTICIPANT_GURU';
export const GET_QUESTION_BY_EXAM_ID_NEW = 'GET_QUESTION_BY_EXAM_ID_NEW';
export const GET_EXAM_DETAIL_AND_QUESTION = 'GET_EXAM_DETAIL_AND_QUESTION';

// Whitelabel
export const IS_SUSPENDED = 'IS_SUSPENDED';
// Google

export const GOOGLE_URL = 'GOOGLE_URL';

export const GET_ONE_CLASS = 'GET_ONE_CLASS';
export const GET_FILES = 'GET_FILES';
export const GET_ONE_SCHOOL = 'GET_ONE_SCHOOL';
export const GET_DOCUMENTS_SCHOOL = 'GET_DOCUMENTS_SCHOOL';
export const GET_FILES_SCHOOL = 'GET_FILES_SCHOOL';

export const GET_SUBMITTED_COURSE = 'GET_SUBMITTED_COURSE';

export const LIST_JURNAL_BELAJAR_NEXT = 'LIST_JURNAL_BELAJAR_NEXT';

export const GET_PENGAJUAN_MAPEL = 'GET_PENGAJUAN_MAPEL';
export const GET_STUDENT_SCORE = 'GET_STUDENT_SCORE';
export const GET_STUDENT_SCORE_SUBMIT = 'GET_STUDENT_SCORE_SUBMIT';

export const GET_DETAIL_SCORE_BY_SISWA = 'GET_DETAIL_SCORE_BY_SISWA';
export const GET_STUDENT_DETAIL_TASK = 'GET_STUDENT_DETAIL_TASK';

export const GET_ASSESSMENT_STUDENT_REFERENCE_HISTORY = 'GET_ASSESSMENT_STUDENT_REFERENCE_HISTORY';
export const GET_COURSE_PENGAJUAN = 'GET_COURSE_PENGAJUAN';
export const GET_ALL_GRADING_PENILAIAN = 'GET_ALL_GRADING_PENILAIAN';
export const GET_ONE_GRADING_PENILAIAN = 'GET_ONE_GRADING_PENILAIAN';
export const GET_STUDENT_SCORE_ALL = 'GET_STUDENT_SCORE_ALL';

export const GET_EXAM_HEADER = 'GET_EXAM_HEADER';
export const SET_WALIKELAS_DATA = 'SET_WALIKELAS_DATA';
