import { KOTA, PROVINSI } from '@type';
import { getUserToken } from '@utils';
import { BASE_LOGIN } from '@src/constants/initHttp';
import axios from 'axios';
import { reportSentry } from '../../../utils/Utils';
import { KECAMATAN } from '../../../constants/initType';
import { BASE_API_URL } from '../../../constants/initHttp';

export const getProvince = () => async (dispatch) => {
  // const api = await requestGet(`wilayah/provinsi`, false, getUserToken())
  //     .then((response) => {
  //         dispatch({ type: PROVINSI, value: response.data });
  //     })
  //     .catch((error) => {
  //         reportSentry(error)
  //     });
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/province/select`, {
      params: {
        country_id: 1,
        search: 'indo',
        status: true,
      },
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    })
    .then((response) => {
      dispatch({ type: PROVINSI, value: response.data.data });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const postSignature = (payload) => async () =>
  await axios({
    url: `${BASE_API_URL}v2/me/signature`,
    method: 'POST',
    data: payload,
    headers: {
      Authorization: getUserToken(),
    },
  }).then((response) => response.data);

export const getCity = (id) => async (dispatch) => {
  // const api = await requestGet(
  //     `wilayah/kabupaten?provinsi_id=${id}`,
  //     false,
  //     getUserToken(),
  // )
  //     .then((response) => {
  //         dispatch({ type: KOTA, value: response.data });
  //     })
  //     .catch((error) => {
  //         reportSentry(error)
  //     });
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/city/select`, {
      params: {
        province_id: id,
        search: '',
        status: true,
      },
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    })
    .then((response) => {
      dispatch({ type: KOTA, value: response.data.data });
      return response.data.data;
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
export const getDistrict = (id) => async (dispatch) => {
  // const api = await requestGet(
  //     `wilayah/kabupaten?provinsi_id=${id}`,
  //     false,
  //     getUserToken(),
  // )
  //     .then((response) => {
  //         dispatch({ type: KOTA, value: response.data });
  //     })
  //     .catch((error) => {
  //         reportSentry(error)
  //     });
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/district/select`, {
      params: {
        city_id: id,
        search: '',
        status: true,
      },
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    })
    .then((response) => {
      dispatch({ type: KECAMATAN, value: response.data.data });
      return response.data.data;
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const getDistrictById = (id) =>
  axios
    .get(`${BASE_LOGIN}/v1/api/district/id/${id}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    })
    .then((response) => response.data.data);
// getProvinceById
export const getCityById = (id) =>
  axios
    .get(`${BASE_LOGIN}/v1/api/city/id/${id}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    })
    .then((response) => response.data.data);
