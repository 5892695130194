import { notification } from 'antd';
import { AUTH_EDIT, AUTH_FILE, AUTH_PROFILE, AUTH_USER } from '@type';
import { showLoading } from '@store/actions';
import { requestPost } from '@configs';
import { getUserToken } from '@utils';
import axios from 'axios';
import { BASE_LOGIN } from '../../../constants/initHttp';
import { AxiosInject, reportSentry } from '../../../utils/Utils';

export const requestLogin = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('users/sign-in', data);

    localStorage.setItem('userData', JSON.stringify(rest.data));
    dispatch({ type: AUTH_USER, value: rest.data });
    dispatch(getUserProfile());
  } catch (error) {
    notification.error({
      message: 'Mohon Maaf!',
      description: 'Ada kesalahan saat melakukan login menggunakan akun google anda, silahkan coba lagi.',
    });
  }
};

export const getUserProfile = () => async (dispatch) =>
  axios
    .get(`${BASE_LOGIN}/v1/api/user/token`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    })
    .then((rest) => {
      dispatch({ type: AUTH_PROFILE, value: rest.data.data });
      dispatch(showLoading(false));
    })
    .catch(() => {
      // notificationOverride({
      //   title: 'Permintaan Ditolak',
      //   description: 'Terjadi Kesalahan, Silahkan Hubungi Customer Service Kami',
      //   type: 'warning',
      // });
      dispatch(showLoading(false));
    });

export const userCredential = async () =>
  await axios.get(`${BASE_LOGIN}/v1/api/userCredential/type/gmail`, {
    headers: { Authorization: `Bearer ${getUserToken()}` },
  });

export const requestLoginV2 = async (data) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    const api = axios
      .post(`${BASE_LOGIN}/v1/api/auth/login`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    return api;
  });

export const requestLoginV3 = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  return await axios
    .post(`${BASE_LOGIN}/v1/api/auth/login`, data)
    .then((response) => {
      localStorage.setItem('userData', JSON.stringify(response.data));
      dispatch({
        type: AUTH_USER,
        value: response.data,
      });
      dispatch(getUserProfile());
    })
    .catch((error) => {
      dispatch(showLoading(false));
      throw error;
    });
};
export const requestLoginV2Email = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .post(`${BASE_LOGIN}/v1/api/auth/login`, data)
    .then((response) => {
      localStorage.setItem('userData', JSON.stringify(response.data));
      dispatch({ type: AUTH_USER, value: response.data });
      dispatch(getUserProfile());
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description:
          error.response.data.message === 'permit overlapped'
            ? 'Sudah membuat izin untuk waktu tersebut'
            : error.response.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};
export const unbindAccount = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .post(
      `${BASE_LOGIN}/v1/api/auth/unbind`,
      { register_type: data },
      {
        headers: { Authorization: `Bearer ${getUserToken()}` },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch(showLoading(false));
        notification.success({
          message: 'Berhasil',
          description: `Sukses Unbind ${data}`,
        });
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: `Gagal Unbind ${data}`,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const bindAccount = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios
    .post(`${BASE_LOGIN}/v1/api/auth/bind`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      if (response.status === 200) {
        showLoading(false);
        notification.success({
          message: 'Berhasil',
          description: `Sukses Bind ${data.register_type}`,
        });
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: `Gagal Bind ${data.register_type}`,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const getBindedAccount = () => async (dispatch) => {
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/userCredential/select`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((response) => {
      dispatch({ type: 'BINDED_ACCOUNT', value: response.data });
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const checkEmail = (data) => async (dispatch) => {
  const api = await axios
    .post(`${BASE_LOGIN}/v1/api/auth/checkEmail`, data)
    .then((response) => {
      let continueLogin = false;
      if (
        response.data.data.exist === true &&
        response.data.data.password === false &&
        response.data.data.new_password_mail === true
      ) {
        notification.info({
          message: 'Your Password is not set',
          description: 'Check your email to reset your password',
        });
      } else if (
        response.data.data.exist === true &&
        response.data.data.password === false &&
        response.data.data.new_password_mail === false
      ) {
        notification.error({
          message: 'Oops!',
          description: 'Request email limit reached, Try again tomorrow',
        });
      } else if (response.data.data.exist === false) {
        dispatch(registerEmail(data));
      } else if (
        response.data.data.exist === true &&
        response.data.data.password === true &&
        response.data.data.new_password_mail === false
      ) {
        continueLogin = true;
      }
      return continueLogin;
      // dispatch({ type: 'CHECK_EMAIL', value: response.data.data });
      // dispatch({ type: 'CHECK_EMAIL_STATE', value: true });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const registerEmail = (data) => async (dispatch) => {
  const api = await axios
    .post(`${BASE_LOGIN}/v1/api/auth/registerEmail`, data)
    .then((response) => {
      if (response.status !== 422) {
        dispatch(checkEmail(data));
      }
    })
    .catch((err) => {
      notification.error({
        message: 'Oops!',
        description: err.response.data.message,
      });
      throw err;
    });
  return api;
};

export const getTokenByKey = (key) => async (dispatch) => {
  const api = await axios
    .get(`${BASE_LOGIN}/v1/api/resetPassword/token/key/${key}`)
    .then((response) => {
      localStorage.setItem('userData', JSON.stringify(response.data));
      dispatch({ type: AUTH_USER, value: response.data });
    })
    .catch((err) => {
      throw err;
    });
  return api;
};

export const newPassword = (data) => async (dispatch) => {
  const api = await axios
    .post(`${BASE_LOGIN}/v1/api/resetPassword/newPassword`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then(() => {
      dispatch(getUserProfile());
    })
    .catch((err) => {
      throw err;
    });
  return api;
};

export const uploadImage = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  return axios
    .post(`${BASE_LOGIN}/v1/api/file`, data, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => {
      dispatch({ type: AUTH_FILE, value: res.data });
      dispatch(showLoading(false));
      return res.data;
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      reportSentry(error);
    });
};

export const updateProfile = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  return axios
    .put(`${BASE_LOGIN}/v1/api/user/token`, data, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    })
    .then((res) => {
      dispatch({ type: AUTH_EDIT, value: res.data });
      dispatch(showLoading(false));
      return res.data;
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      reportSentry(error);
    });
};

export const getGoogleUrl = (params) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    const http = AxiosInject(BASE_LOGIN, null, null);
    http
      .post('/v1/api/auth/googleInfix', {
        ...params,
      })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
