import { requestDelete, requestGet, requestPost, requestPut } from '@configs';
import {
  CREATE_JURNAL_BELAJAR,
  IS_SUCCESS,
  JURNAL_BELAJAR_DETAIL,
  JURNAL_BELAJAR_TUGAS,
  JURNAL_BELAJAR_UJIAN,
  LIST_JURNAL_BELAJAR,
  LIST_JURNAL_BELAJAR_DRAGGABLE,
} from '@type';
import { getUserToken } from '@utils';
import { notification } from 'antd';
import { showLoading } from '@store/actions';
import moment from 'moment';
import { reportSentry } from '../../../../utils/Utils';
import { LIST_JURNAL_BELAJAR_NEXT } from '../../../../constants/initType';
import httpService from '../../../../configs/service';
import { BASE_EXAM } from '../../../../constants/initHttp';

export const createJurnalBelajar = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  data.list_attachment = data.list_attachment.filter((item) => !(item.link === '' || item.link === null));
  const api = await requestPost('study-journal', data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Create Modul Ajar Success',
        });
        dispatch({ type: CREATE_JURNAL_BELAJAR, value: response.data });
        dispatch(showLoading(false));
        notification.success({
          message: 'Berhasil!',
          description: 'Berhasil Membuat Modul Ajar',
        });
        return true;
      }
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Create Modul Ajar Failed',
      });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Create Tugas Failed',
      });
      dispatch(showLoading(false));
      reportSentry(error);
    })
    .finally(() => {
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: '',
      });
    });
  return api;
};
export const getNewListJurnalBelajarClasses = (class_id, page, limit, onlyme, startDate, endDate, search) => {
  const dp = async (dispatch) => {
    dispatch(showLoading(true));
    const api = await requestGet(
      `study-journal/class/${class_id}?onlyme=${onlyme}&limit=${limit}&page=${page}&start_date=${startDate}&end_date=${endDate}&search=${search}`,
      false,
      getUserToken()
    )
      .then((response) => {
        dispatch({ type: LIST_JURNAL_BELAJAR, value: response.data });
        dispatch(showLoading(false));
      })
      .catch((error) => {
        dispatch(showLoading(false));
        dispatch({
          type: LIST_JURNAL_BELAJAR,
          value: {
            list_data: [],
            total_count: 0,
          },
        });
        notification.error({
          message: 'Mohon Maaf!',
          description: error.response?.data.message,
        });
        reportSentry(error);
      });
    return api;
  };
  return dp;
};

export const getNewListJurnalBelajar = (courseid, page, limit, type, onlyme) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(
    `study-journal/course/${courseid}?onlyme=${onlyme}&limit=${limit}&page=${page}`,
    false,
    getUserToken()
  )
    .then((response) => {
      dispatch({ type: LIST_JURNAL_BELAJAR, value: response.data });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: LIST_JURNAL_BELAJAR,
        value: {
          list_data: [],
          total_count: 0,
        },
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.message,
      });
      reportSentry(error);
    });
  return api;
};

export const getListJurnalBelajar = (courseid, page, limit, startDate, endDate, onlyme) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(
    `study-journal/course/${courseid}?onlyme=${onlyme}&limit=${limit}&page=${page}&start_date=${startDate}&end_date=${endDate}`,
    false,
    getUserToken()
  )
    .then((response) => {
      dispatch({ type: LIST_JURNAL_BELAJAR, value: response.data });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: LIST_JURNAL_BELAJAR,
        value: {
          list_data: [],
          total_count: 0,
        },
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.message,
      });
      reportSentry(error);
    });
  return api;
};
export const getListJurnalBelajarClasses = (class_id, page, limit, type, onlyme) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(
    `study-journal/class/${class_id}?onlyme=${onlyme}&limit=${limit}&page=${page}&${
      type === 'selanjutnya' ? 'after=' : 'before_equal='
    }${moment(Date.now()).toISOString()}`,
    false,
    getUserToken()
  )
    .then((response) => {
      dispatch({ type: LIST_JURNAL_BELAJAR, value: response.data });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: LIST_JURNAL_BELAJAR,
        value: {
          list_data: [],
          total_count: 0,
        },
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.message,
      });
      reportSentry(error);
    });
  return api;
};
export const getListJurnalBelajarNext = (courseid, page, limit, type, onlyme) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(
    `study-journal/course/${courseid}?onlyme=${onlyme}&limit=${limit}&page=${page}&${
      type === 'selanjutnya' ? 'after=' : 'before_equal='
    }${moment(Date.now()).toISOString()}`,
    false,
    getUserToken()
  )
    .then((response) => {
      dispatch({
        type: LIST_JURNAL_BELAJAR_NEXT,
        value: response.data,
      });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: LIST_JURNAL_BELAJAR_NEXT,
        value: {
          list_data: [],
          total_count: 0,
        },
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.message,
      });
      reportSentry(error);
    });
  return api;
};

export const getJurnalBelajarDetail = (jurnalId) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(`study-journal/${jurnalId}`, false, getUserToken())
    .then((response) => {
      dispatch({ type: JURNAL_BELAJAR_DETAIL, value: response.data });
      dispatch(showLoading(false));
      return response.data;
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.message,
      });
      reportSentry(error);
    });
  return api;
};
export const editJurnalBelajar = (jurnalId, body) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPut(`study-journal/${jurnalId}`, false, body, getUserToken())
    .then((response) => {
      dispatch(showLoading(false));
      return response.status_code === 200;
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.message,
      });
      reportSentry(error);
    });
  return api;
};

export const getJurnalBelajarTugas = (classId, jurnalId, type) => async (dispatch) => {
  const api = await requestGet(
    `me/${
      type.includes('SISWA') || type.includes('ORTU') ? 'assessments' : 'assessments-guru'
    }?class_id=${classId}&limit=100&page=1&journal_id=${jurnalId}`,
    false,
    getUserToken()
  )
    .then((response) => {
      dispatch({ type: JURNAL_BELAJAR_TUGAS, value: response.data });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.message,
      });
      reportSentry(error);
    });
  return api;
};
export const getJurnalBelajarUjian = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('exam', { params, baseURL: BASE_EXAM })
    .then((response) => {
      dispatch({
        type: JURNAL_BELAJAR_UJIAN,
        value: response.data.payload,
        pagination: response.data.payload,
      });
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const deleteJurnalBelajar = (jurnalId) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestDelete(`study-journal/${jurnalId}`, false, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({ type: 'DELETEJURNAL', value: response.status_code });
        return true;
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.message,
      });
      reportSentry(error);
    });
  return api;
};

export const getListJurnalBelajarV2 = (courseid, startDate, endDate, onlyme) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(
    `study-journal-v2/course/${courseid}?onlyme=${onlyme}&start_date=${startDate}&end_date=${endDate}`,
    false,
    getUserToken()
  )
    .then((response) => {
      dispatch({ type: LIST_JURNAL_BELAJAR, value: response.data });
      dispatch(showLoading(false));
      return response;
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: LIST_JURNAL_BELAJAR,
        value: {
          list_data: [],
          total_count: 0,
        },
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response?.data.message,
      });
      reportSentry(error);
    });
  return api;
};

export const getListJurnalBelajarClassesV2 = (class_id, onlyme, startDate, endDate, search, status) => {
  const dp = async (dispatch) => {
    dispatch(showLoading(true));
    const api = await requestGet(
      `study-journal-v2/class/${class_id}?onlyme=${onlyme}&start_date=${startDate}&end_date=${endDate}&search=${search}&status=${status}`,
      false,
      getUserToken()
    )
      .then((response) => {
        dispatch({ type: LIST_JURNAL_BELAJAR, value: response.data });

        // mapping for table draggable
        const dataDraggable = [];
        response.data?.list_data.forEach((item, i) =>
          dataDraggable.push({
            key: item.status === 'ongoing' ? 0 : i + 1, // 0 = drag action is disabled
            id: item.id,
            flow: item.flow,
            name: item.name,
            course_name: item.course_name,
            start_time: item.start_time,
            status: item.status,
          })
        );
        dispatch({ type: LIST_JURNAL_BELAJAR_DRAGGABLE, value: dataDraggable });

        dispatch(showLoading(false));
        return response;
      })
      .catch((error) => {
        dispatch(showLoading(false));
        dispatch({
          type: LIST_JURNAL_BELAJAR,
          value: {
            list_data: [],
            total_count: 0,
          },
        });
        notification.error({
          message: 'Mohon Maaf!',
          description: error.response?.data.message,
        });
        reportSentry(error);
      });
    return api;
  };
  return dp;
};

export const updateSortedFlow = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPut('study-journal/update-flow', false, data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Atur Urutan Modul Ajar Success',
        });
        dispatch(showLoading(false));
        notification.success({
          message: 'Berhasil!',
          description: 'Berhasil Atur Urutan Modul Ajar',
        });
        return true;
      }
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Atur Urutan Modul Ajar Failed',
      });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Atur Urutan Modul Ajar Failed',
      });
      dispatch(showLoading(false));
      reportSentry(error);
    })
    .finally(() => {
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: '',
      });
    });
  return api;
};
