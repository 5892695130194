import { requestGet, requestPatch, requestPost, requestPut } from '@configs';
import { getUserToken } from '@utils';
import {
  ABSENCE_BY_COURSE_BY_DATE,
  ABSENCE_BY_COURSE_BY_MONTH,
  ABSENCE_CLASS_SUMMARY,
  ABSENCE_GURU,
  IS_SUCCESS,
  LIST_PERMIT,
} from '@type';
import { showLoading } from '@store/actions';
import { notification } from 'antd';
import moment from 'moment';
import { reportSentry } from '../../../../utils/Utils';
import { BASE_API_URL, BASE_VERSION } from '../../../../constants/initHttp';
import { requestDelete } from '../../../../configs';

export const absenceTeacher = (data, isStudentNotAbsence, status) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost('absensi', data, getUserToken())
    .then((response) => {
      if (isStudentNotAbsence) {
        if (response.status_code === 200) {
          const dataUpdate = {
            absen_id: response.data.id,
            status,
          };
          dispatch(updateAbsence(dataUpdate));
        }
        dispatch(showLoading(false));
        return true;
      }
      if (response.status_code === 200) {
        notification.success({
          message: 'Berhasil!',
          description: 'Berhasil Absen',
        });
        dispatch({ type: ABSENCE_GURU, value: response.data });
        dispatch(showLoading(false));
        return true;
      }
      notification.error({
        message: 'Gagal!',
        description: response.message,
      });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description:
          error.response.data.message === 'the course has finished'
            ? 'Jadwal Telah Selesai'
            : error.response.data.message || 'Gagal',
      });
      reportSentry(error);
    });

  return api;
};
export const updateAbsence = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPut('absensi', null, data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        notification.success({
          message: 'Berhasil!',
          description: 'Berhasil Merubah Absensi',
        });
        dispatch({ type: ABSENCE_GURU, value: response.status_code });
        dispatch(showLoading(false));
        return true;
      }
      dispatch(showLoading(false));
      notification.error({
        message: 'Gagal!',
        description: response.message,
      });
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.message,
      });
      reportSentry(error);
    })
    .finally(() => {
      dispatch({ type: ABSENCE_GURU, value: 0 });
    });

  return api;
};

export const absenceClassSummary = (id, role) => async (dispatch) => {
  const api = await requestGet(`absensi/summary/class?classID=${id}&access=${role}`, false, getUserToken())
    .then((response) => {
      dispatch({ type: ABSENCE_CLASS_SUMMARY, value: response.data });
    })
    .catch((error) => {
      dispatch({ type: ABSENCE_CLASS_SUMMARY, value: false });
      reportSentry(error);
    });
  return api;
};
export const absenceByCourseByDate = (id, role, date) => async (dispatch) => {
  const api = await requestGet(`absensi/course/date?courseID=${id}&role=${role}&date=${date}`, false, getUserToken())
    .then((response) => {
      dispatch({ type: ABSENCE_BY_COURSE_BY_DATE, value: response.data });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
export const absenceByCourseByMonth = (id, role, month) => async (dispatch) => {
  const api = await requestGet(`absensi/course/month?courseID=${id}&role=${role}&month=${month}`, false, getUserToken())
    .then((response) => {
      dispatch({
        type: ABSENCE_BY_COURSE_BY_MONTH,
        value: response.data,
      });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const getListPermit = (class_id, limit, page, role) => async (dispatch) => {
  const api = await requestGet(
    `student-permit/class/${class_id}?limit=${limit}&page=${page}&role=${role}`,
    false,
    getUserToken()
  )
    .then((response) => {
      dispatch({ type: LIST_PERMIT, value: response.data });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const createPermission = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost('student-permit', data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        notification.success({
          message: 'Success',
          description: 'Create Permission Success',
        });
        dispatch(showLoading(false));
        dispatch({ type: 'CREATE_PERMISSION', value: response.data });
      } else {
        dispatch(showLoading(false));
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description:
          error.response.data.message === 'permit overlapped'
            ? 'Sudah membuat izin untuk waktu tersebut'
            : error.response.data.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const editPermission = (data, permitID) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPut(`student-permit/${permitID}`, null, data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        notification.success({
          message: 'Success',
          description: 'Edit Permission Success',
        });
        dispatch(showLoading(false));
      } else {
        dispatch(showLoading(false));
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description:
          error.response.data.message === 'permit overlapped'
            ? 'Sudah membuat izin untuk waktu tersebut'
            : error.response.data.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const deletePermission = (permitID) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestDelete(`student-permit/${permitID}`, null, getUserToken())
    .then(() => {
      notification.success({
        message: 'Success',
        description: 'Edit Permission Success',
      });
    })
    .catch((error) => {
      notification.error({
        message: 'Mohon Maaf!',
        description: 'tidak dapat menghapus izin',
      });

      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
  return api;
};

export const responseTeacher = (id, data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost(`student-permit/${id}/respond`, data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        notification.success({
          message: 'Success',
          description: 'Status Updated',
        });
        dispatch(showLoading(false));
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Update Permission Success',
        });
      } else {
        dispatch(showLoading(false));
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Update Permission Failed',
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const fetchGenerateAbsensi = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return fetch(`${BASE_API_URL}${BASE_VERSION}absensi/summary/export?classID=${params.classID}&month=${params.month}`, {
    headers: {
      Authorization: getUserToken(),
    },
  })
    .then(async (res) => {
      if (res.status > 300 || res.status < 200) {
        const resMessage = await res.text();
        throw new Error(resMessage);
      }

      const blob = await res.blob();
      const result = {
        filename: `Absensi Bulan ${moment().format('MMMM')}`,
        blob,
      };
      return result;
    })
    .then((res) => res)
    .catch((error) => {
      notification.error({
        message: 'Mohon Maaf!',
        description: error.message,
      });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const absenceClassSummaryV2 = (params) => requestGet('absensi/summary/class-v2', params, getUserToken());

export const detailAbsenceSummary = (params) => requestGet('absensi/summary/class-v2/detail', params, getUserToken());

export const absenceClassDaily = (params) => requestGet('class-attendance', params, getUserToken());
export const absenceClassNotes = (class_attendance_student_id, params) =>
  requestPatch(`class-attendance/student/${class_attendance_student_id}/notes`, params, getUserToken());
export const absenceClassDailyV2 = (params) => requestGet('class-attendance-v2', params, getUserToken());

export const absenceRecapStudent = (params) => requestGet('class-attendance/student/recap', params, getUserToken());

export const studentRekapAbsence = (params) => requestGet('class-attendance/students', params, getUserToken());

export const getStudentPermitById = (id) => requestGet(`student-permit/${id}`, null, getUserToken());

export const changeAbsenceStatus = (id, payload) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPut(`class-attendance/student/${id}`, null, payload, getUserToken())
    .then((response) => {
      let result = false;
      if (response.status_code === 200) {
        notification.success({
          message: 'Status Kehadiran Berhasil Diubah',
          description: 'Status kehadiran siswa berhasil diubah dan diperbarui ke dalam sistem',
        });
        dispatch(showLoading(false));
        result = true;
      } else {
        dispatch(showLoading(false));
      }
      return result;
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
      return false;
    });
  return api;
};

export const getListPermitNotif = (class_id, params) =>
  requestGet(`student-permit/class/${class_id}`, params, getUserToken());

export const respondPermit = (id, data) => requestPost(`student-permit/${id}/respond`, data, getUserToken());

export const changeAbsenceReport = (payload) => async () => {
  const api = await requestPut('class-attendance/student-report', null, payload, getUserToken())
    .then((response) => response)
    .catch((error) => {
      reportSentry(error);
      return false;
    });
  return api;
};
