import { AxiosInject, getUserToken } from '../../../../../utils/Utils';
import { BASE_REKAP_NILAI } from '../../../../../constants/initHttp';

export const generateRaportQuali = (id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async () =>
    await httpInjector
      .get(`v1/api/reportQualitativeScorePdf/generate/${id}`, {
        responseType: 'blob',
      })
      .then((r) => r)
      .catch(r);
};
export const generateRaportQuanti = (id) => {
  const httpInjector = AxiosInject(BASE_REKAP_NILAI, 'Bearer', getUserToken());

  return async () =>
    await httpInjector
      .get(`v1/api/reportUserPdf/generate/${id}`, {
        responseType: 'blob',
      })
      .then((r) => r)
      .catch(r);
};
