import { showLoading } from '@store/actions';
import { notification } from 'antd';
import { BASE_REKAP_NILAI } from '../../../../constants/initHttp';
import { AxiosInject, getUserToken, reportSentry } from '../../../../utils/Utils';
import httpService from '../../../../configs/service';

const RECOVER = 'https://dev-api.siswamedia.com/v1-report-card/';

const URL_REKAP_NILAI = `${BASE_REKAP_NILAI || RECOVER}v1/api/`;

export const getListLaporan = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('scoresubmission/submitlist', {
      params,
      baseURL: URL_REKAP_NILAI,
    })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getListLaporanSiswa = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('scoresubmission/tasklist', {
      params,
      baseURL: URL_REKAP_NILAI,
    })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const getListLaporanNilaiSiswa = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('scoresubmission/score/students', {
      params,
      baseURL: URL_REKAP_NILAI,
    })
    .then((response) => response.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const generateExportNilai = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  return httpService
    .get('report/generate', { params, baseURL: URL_REKAP_NILAI })
    .then((response) => response)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};

export const fetchGenerateExportNilai = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  const httpAdapter = AxiosInject(URL_REKAP_NILAI, 'Bearer', getUserToken());
  return httpAdapter
    .get('report/generate', {
      responseType: 'blob',
      params,
    })
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    })
    .catch(() => {
      notification.error({
        message: 'Gagal!',
        description: 'Data tidak ditemukan',
      });
    })
    .finally(() => {
      dispatch(showLoading(false));
    });
};
