import {
  CREATE_ANSWER_QUIZ_BANK_SOAL,
  CREATE_QUIZ_BANK_SOAL,
  DELETE_QUIZ,
  GET_COURSE_BANK_SOAL,
  GET_EXAM_DETAIL,
  GET_EXAM_DETAIL_AND_QUESTION,
  GET_LIST_BANK_SOAL,
  GET_LIST_EXAM,
  GET_LIST_MASTER_SOAL,
  GET_QUESTION_BY_EXAM_ID,
  GET_QUESTION_BY_EXAM_ID_NEW,
  GET_QUIZ_DETAIL,
  GET_QUIZ_JAWABAN,
  GET_QUIZ_PERSONAL,
  GET_QUIZ_SOAL,
  GET_SOAL_BANK_SOAL,
  GET_TYPE_LIST,
  UPDATE_QUIZ,
} from '@type';

const initState = {
  dataCourse: false,
  listUjian: false,
  listBankSoal: false,
  masterSoal: false,
  listSoal: false,
  createQuizBankSoal: false,
  createAnswerQuizBankSoal: false,
  quizJawaban: {},
  // Ujian Student
  quizDetail: {},
  quizSoal: {},
  updateQuiz: false,
  deleteQuiz: false,
  typeList: [],
  listExam: [],
  examDetail: {},
  examDetailNew: null,
  questionByExam: [],
  questionByExamNew: [],
};

const Ujian = (state = initState, action) => {
  switch (action.type) {
    case GET_QUIZ_PERSONAL:
      return {
        ...state,
        listUjian: action.value,
      };

    case GET_LIST_BANK_SOAL:
      return {
        ...state,
        listBankSoal: action.value,
      };

    case GET_LIST_MASTER_SOAL:
      return {
        ...state,
        masterSoal: action.value,
      };

    case GET_SOAL_BANK_SOAL:
      return {
        ...state,
        listSoal: action.value,
      };

    case GET_COURSE_BANK_SOAL:
      return {
        ...state,
        dataCourse: action.value,
      };

    case CREATE_QUIZ_BANK_SOAL:
      return {
        ...state,
        createQuizBankSoal: action.value,
      };

    case CREATE_ANSWER_QUIZ_BANK_SOAL:
      return {
        ...state,
        createAnswerQuizBankSoal: action.value,
      };

    case UPDATE_QUIZ:
      return {
        ...state,
        updateQuiz: action.value,
      };

    case DELETE_QUIZ:
      return {
        ...state,
        deleteQuiz: action.value,
      };

    case GET_QUIZ_JAWABAN:
      return {
        ...state,
        quizJawaban: action.value,
      };
    // Ujian Student
    case GET_QUIZ_DETAIL:
      return {
        ...state,
        quizDetail: action.value,
      };
    case GET_QUIZ_SOAL:
      return {
        ...state,
        quizSoal: action.value,
      };
    case GET_TYPE_LIST:
      return {
        ...state,
        typeList: action.value,
      };
    case GET_LIST_EXAM:
      return {
        ...state,
        listExam: action.value,
      };
    case GET_EXAM_DETAIL:
      return {
        ...state,
        examDetail: action.value,
      };
    case GET_EXAM_DETAIL_AND_QUESTION:
      return {
        ...state,
        examDetailNew: action.value,
      };
    case GET_QUESTION_BY_EXAM_ID:
      return {
        ...state,
        questionByExam: action.value,
      };
    case GET_QUESTION_BY_EXAM_ID_NEW:
      return {
        ...state,
        questionByExamNew: action.value,
      };

    default:
      return state;
  }
};

export default Ujian;
