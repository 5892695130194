import { requestGet, requestPost } from '@configs/interaksi';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { notification } from 'antd';
import {
  CREATE_INTERAKSI_BELAJAR_ROOM,
  GET_INTERAKSI_BELAJAR_ROOM,
  LIST_INTERAKSI_BELAJAR_ROOM,
} from '../../../../constants/initType';
import { reportSentry } from '../../../../utils/Utils';

export const createInteraksiRoom = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost('room/create_room', data, getUserToken())
    .then((response) => {
      if (response.code === 200) {
        dispatch({
          type: CREATE_INTERAKSI_BELAJAR_ROOM,
          value: true,
          message: 'Create Room Success',
        });
        dispatch(showLoading(false));
      } else {
        dispatch(showLoading(false));
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: CREATE_INTERAKSI_BELAJAR_ROOM,
        value: false,
        message: 'Create Room Failed',
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const getInteraksiRooms = (is_student = false) => async (dispatch) => {
  const api = await requestGet(
    is_student === true ? 'room/search/student' : 'room/search/teacher',
    {
      limit: 9999,
      page: 0,
    },
    getUserToken()
  )
    .then((response) => {
      dispatch({
        type: LIST_INTERAKSI_BELAJAR_ROOM,
        value: response.data.list,
      });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};

export const getInteraksiBySlug = (slug) => async (dispatch) => {
  const api = await requestGet(`room/detail/${slug}`, false, getUserToken())
    .then((response) => {
      dispatch({
        type: GET_INTERAKSI_BELAJAR_ROOM,
        value: response.data,
      });
    })
    .catch((error) => {
      reportSentry(error);
    });
  return api;
};
