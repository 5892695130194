import {
  CREATE_JURNAL_BELAJAR,
  JURNAL_BELAJAR_DETAIL,
  JURNAL_BELAJAR_TUGAS,
  JURNAL_BELAJAR_UJIAN,
  LIST_JURNAL_BELAJAR,
  LIST_JURNAL_BELAJAR_NEXT,
  LIST_JURNAL_BELAJAR_DRAGGABLE,
} from '@type';

const initialState = {
  list_jurnal: { list_data: [] },
  list_jurnal_next: { list_data: [] },
  jurnal_detail: {},
  jurnal_tugas: [],
  jurnal_ujian: [],
  delete_status_code: null,
};

const JurnalBelajar = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_JURNAL_BELAJAR:
      return state;
    case LIST_JURNAL_BELAJAR:
      return { ...state, list_jurnal: action.value };
    case LIST_JURNAL_BELAJAR_NEXT:
      return { ...state, list_jurnal_next: action.value };
    case JURNAL_BELAJAR_DETAIL:
      return { ...state, jurnal_detail: action.value };
    case JURNAL_BELAJAR_TUGAS:
      return { ...state, jurnal_tugas: action.value };
    case JURNAL_BELAJAR_UJIAN:
      return { ...state, jurnal_ujian: action.value };
    case 'DELETEJURNAL':
      return { ...state, delete_status_code: action.value };
    case LIST_JURNAL_BELAJAR_DRAGGABLE:
      return { ...state, list_jurnal_draggable: action.value };
    default:
      return state;
  }
};

export default JurnalBelajar;
