import React from 'react';
import '@assets/scss/components/molecules/ItemClassMedia.scss';
import IconCreate from '../../atoms/IconCreate';

const ItemClassMedia = ({ name, icon, onClick }) => (
  <div className="wrapper-item-class-media" onClick={onClick}>
    <IconCreate
      svg={icon}
      color="var(--primary-color)"
      width={32}
      height={32}
      injection={(svg) => {
        svg.classList.add('svg-class-name');
        const el = [...svg.querySelectorAll('path')];
        const gEl = [...svg.querySelectorAll('line')];
        el.forEach((e) => {
          e.setAttribute('fill', 'var(--primary-color)');
        });
        gEl.forEach((e) => {
          e.setAttribute('stroke', 'var(--primary-color)');
        });
        svg.setAttribute('width', 32);
        svg.setAttribute('height', 32);
      }}
    />
    <p className="title-item-class-media">{name}</p>
  </div>
);

export default ItemClassMedia;
